import { useIsFetching } from '@tanstack/react-query';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { FC, useMemo } from 'react';
import { FiInfo } from 'react-icons/fi';

import rootStore from '../../../../../../stores/rootStore/rootStore';
import { getTimeWithTimeZone } from '../../../../../TrafficLightDetailed/TlDetectors/IntancyGraph/TitleBtns/TimeLocalCard/helpers/getTimeWithTimeZone';
import Popover from '../../../../../ui-kit/Popover/Popover';

import styles from './InfoBtn.module.scss';

interface InfoBtnProps {
  updateKeys: unknown[];
}

const InfoBtn: FC<InfoBtnProps> = ({ updateKeys }) => {
  const { interfaceTipsDelay } = rootStore.uiStore;

  const isFetchingSources = useIsFetching({ queryKey: updateKeys });

  const updateTime = useMemo(
    () => getTimeWithTimeZone().dateFormatted,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isFetchingSources]
  );

  return (
    <Popover
      content={`Время последнего обновления: ${updateTime}`}
      tipsDelay={interfaceTipsDelay}
    >
      <span className={classNames(styles.ico, styles.help)}>
        <FiInfo />
      </span>
    </Popover>
  );
};

export default observer(InfoBtn);
