import { useCallback, useEffect, useState } from 'react';

import api from '../../../api';
import { DataType, TableData, TableKeys } from '../../../ts/models/table.model';
import message from '../../ui-kit/Notification/Notification';
import ActionButtons from '../../ui-kit/Table/ActionButtons/ActionButtons';
import { DEFAULT_TABLE } from '../../ui-kit/Table/constants';
import Table from '../../ui-kit/Table/Table';

import DrawerBlock from './DrawerBlock/DrawerBlock';
import { getOrganizationsBtns } from './helpers/getOrganizationsBtns';
import { ActionType } from './models/Organizations.model';

const { Extended } = TableKeys;

const appPath = 'Admin/Organizations';
const ADD_ORG = 'Добавить организацию';

const Organizations = () => {
  const [tableData, setTableData] = useState<TableData>(DEFAULT_TABLE);
  const [isOpen, setIsOpen] = useState(false);
  const [isOrg, setIsOrg] = useState(false);
  const [divisionsId, setDivisionsId] = useState<N<number>>(null);
  const [isUpdate, setIsUpdate] = useState(false);
  const [orgType, setOrgType] = useState(ActionType.Add);
  const [values, setValues] = useState<N<DataType>>(null);
  const [isLoading, setIsLoading] = useState(true);

  const handleAddOrg = () => {
    setIsOpen(true);
    setIsOrg(true);
    setOrgType(ActionType.Add);
  };

  const onClose = () => {
    setIsOpen(false);
    setTimeout(() => {
      setIsOrg(false);
      setDivisionsId(null);
    }, 500);
  };

  const handleDelete = async (id: number) => {
    const res = await api.catalogs.organizations.delete(id, appPath);

    if (!res) return;

    setIsUpdate(true);

    message.success('DELETED_SUCCESSFULLY');
  };

  const handleEdit = (collaboration: any) => {
    setIsOrg(true);
    setOrgType(ActionType.Edit);
    setIsOpen(true);
    setValues(collaboration);
  };

  const getOrganizationsData = useCallback(async () => {
    setTableData(DEFAULT_TABLE);
    setIsUpdate(false);
    setIsLoading(true);
    const res = await api.catalogs.organizations.getList(appPath);

    setIsLoading(false);

    if (!res) return;

    setTableData(res.data);
  }, []);

  useEffect(() => {
    getOrganizationsData();
  }, [getOrganizationsData]);

  useEffect(() => {
    isUpdate && getOrganizationsData();
  }, [getOrganizationsData, isUpdate]);

  const collaborationRender = (collaboration: any) => {
    return (
      <ActionButtons
        actionButtons={getOrganizationsBtns({
          collaboration,
          setDivisionsId,
          setIsOpen,
          handleDelete,
          handleEdit,
        })}
      />
    );
  };

  return (
    <>
      <DrawerBlock
        isOpen={isOpen}
        onClose={onClose}
        isOrg={isOrg}
        divisionsId={divisionsId}
        setIsUpdate={setIsUpdate}
        type={orgType}
        collaboration={values}
      />
      <Table
        collaborationRender={collaborationRender}
        tableData={tableData}
        tableConfigType={Extended}
        iteractionButtons={[
          {
            text: ADD_ORG,
            handler: handleAddOrg,
          },
        ]}
        isLoading={isLoading}
        refreshData={getOrganizationsData}
        isOrderNumber
      />
    </>
  );
};

export default Organizations;
