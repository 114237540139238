import { AddFormFields } from '../../../../../models/DeviceAdmin.model';

const transformValues = (
  values: AddFormFields,
  initialProps: U<AddFormFields>
): AddFormFields => {
  return {
    ...(initialProps ?? {}),
    ...values,
    longitude: Number(values.longitude),
    latitude: Number(values.latitude),
  };
};

export default transformValues;
