import { Form, Select } from 'antd';
import { type Rule } from 'antd/es/form';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import rootStore from '../../../../../../../stores/rootStore/rootStore';
import { FormKeys } from '../../constants/constants';

import getTypesOptions from './helpers/getTypesOptions';

const { DeviceTypeId } = FormKeys;

interface DeviceTypeSelectProps {
  getDeviceRules: (value: FormKeys) => U<Rule[]>;
}

const DeviceTypeSelect: FC<DeviceTypeSelectProps> = ({ getDeviceRules }) => {
  const { isEditForm } = rootStore.adminPanelStore;

  const options = getTypesOptions();

  if (isEditForm) return null;

  return (
    <Form.Item
      label="Тип устройства"
      name={DeviceTypeId}
      rules={getDeviceRules(FormKeys.DeviceTypeId)}
    >
      <Select
        placeholder="Выберите тип устройства"
        options={options}
        allowClear
      />
    </Form.Item>
  );
};

export default observer(DeviceTypeSelect);
