import { useMemo } from 'react';

import rootStore from '../../../../stores/rootStore/rootStore';
import { X_STEP } from '../constants/constants';
import createStepArray from '../helpers/createStepArray';
import getTlsData, { getXDomain } from '../helpers/getTlsData';
import getYTicks from '../helpers/getYTicks';

const useTlsData = () => {
  const { offset, coordinationData } = rootStore.coordinatedTrafficStore;

  const tlsData = useMemo(() => {
    if (!coordinationData) return {};
    const { tls } = coordinationData;

    const xDomain = getXDomain(tls, offset);
    const maxX = xDomain[1];
    const { distances, tlsData } = getTlsData(tls, offset, maxX);
    const offsetTicks = offset > 0 ? [] : [offset];
    const xTicks = [...offsetTicks, ...createStepArray(maxX, X_STEP), maxX];

    return { xDomain, tlsData, yTicks: getYTicks(distances), xTicks };
  }, [offset, coordinationData]);

  return tlsData;
};

export default useTlsData;
