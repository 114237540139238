import axios from 'axios';

import { GEOCODE_DEFAULT_RESULTS } from '../constants/tasks/constants';
import { getGeocodeServiceUrl } from '../helpers';
import { IGeocodeResult } from '../models/tasks/tasks.model';

export const geocode = async (
  text: string,
  count?: number
): Promise<IGeocodeResult[]> => {
  const results = count || GEOCODE_DEFAULT_RESULTS;

  const url = `${getGeocodeServiceUrl()}?location=${text}&results=${results}`;

  try {
    const action = await axios.get(url);

    const { data } = action;

    if (data && Array.isArray(data)) {
      return data.map((item: IGeocodeResult) => {
        return item;
      });
    }

    return [];
  } catch (e) {
    return [];
  }
};

export const getGeocodedTextView = (value: string | undefined) => {
  if (!value) {
    return '';
  }

  if (Number.isFinite(Number(value))) {
    return Number(value).toFixed(8);
  }

  const parts = value.split(',');

  if (parts && parts.length === 2) {
    const [x, y] = parts;

    if (Number.isFinite(Number(x)) && Number.isFinite(Number(y))) {
      return [x, y].map((item) => Number(item).toFixed(8)).join(', ');
    }
  }

  return value;
};
