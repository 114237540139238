import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import rootStore from '../../../../../stores/rootStore/rootStore';
import SourceForm from '../SourceForm/SourceForm';

import SourcePreview from './SourcePreview/SourcePreview';

import styles from './SourceAddForm.module.scss';

const SourceAddForm: FC = () => {
  const { isCameraAdminDevice } = rootStore.adminPanelStore;

  const isShow = isCameraAdminDevice;

  return (
    <div
      className={classNames(styles.container, {
        [styles.additional]: isShow,
      })}
    >
      <SourceForm isFormFull={isShow} />
      <SourcePreview isShow={isShow} />
    </div>
  );
};

export default observer(SourceAddForm);
