import { uniqueId } from 'lodash';
import { observer } from 'mobx-react-lite';
import { FC, Fragment } from 'react';
import {
  CartesianGrid,
  ComposedChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Customized,
  Scatter,
  LabelList,
} from 'recharts';

import { COLORS } from '../../../constants/colorsConstants';
import rootStore from '../../../stores/rootStore/rootStore';
import Detailed from '../../ui-kit/Detailed/Detailed';

import { DETAILED_HEADER, Y_MIN } from './constants/constants';
import EmptyDetailed from './EmptyDetailed/EmptyDetailed';
import formatDirNum from './helpers/formatDirNum';
import getTactFill from './helpers/getTactFill';
import useTlsData from './hooks/useTlsData';
import TactBlock from './TactBlock';
import TrafficArea from './TrafficArea';
import { Direction } from './TrafficDetailed.model';

const TrafficDetailed: FC = () => {
  const { setTrafficValue, offset, coordinationData } =
    rootStore.coordinatedTrafficStore;

  const handleClose = () => setTrafficValue('isTrafficDetailed', false);

  const { tlsData, xDomain, yTicks, xTicks } = useTlsData();

  if (!coordinationData) return <EmptyDetailed onClose={handleClose} />;

  const { tls, cls_bw, cls_fw } = coordinationData;

  return (
    <Detailed header={DETAILED_HEADER} onClose={handleClose}>
      <ResponsiveContainer>
        <ComposedChart data={tlsData} margin={{ top: 30, right: 80 }}>
          {cls_fw.map((points) => (
            <Customized
              key={uniqueId('cls_fw_')}
              component={<TrafficArea points={points} />}
            />
          ))}

          {cls_bw.map((points) => (
            <Customized
              key={uniqueId('cls_bw_')}
              component={
                <TrafficArea points={points} fill={COLORS.TURQUOISE} />
              }
            />
          ))}

          {tls.map(({ directions }) => {
            const values = Object.values(directions) as Direction[][];

            return (
              <Fragment key={uniqueId('tl_')}>
                {values.map((dir) => (
                  <Fragment key={uniqueId('tl_dirs_')}>
                    {dir.map(({ start, stop, distance, type }) => (
                      <Customized
                        key={uniqueId('dir_')}
                        component={
                          <TactBlock
                            x1={start}
                            x2={stop}
                            y={distance}
                            fill={getTactFill(type)}
                            shift={offset}
                          />
                        }
                      />
                    ))}
                  </Fragment>
                ))}
              </Fragment>
            );
          })}

          <Scatter opacity={0}>
            <LabelList
              dataKey={'tlId'}
              fontSize={14}
              fontWeight={'bold'}
              position={'right'}
              width={100}
              formatter={(val: number) => `СО ${val}`}
            />
            <LabelList
              dataKey={'dirNum'}
              fontSize={16}
              position={'right'}
              width={100}
              offset={0}
              formatter={formatDirNum}
            />
          </Scatter>

          <CartesianGrid strokeDasharray="3 3" opacity={0.7} />
          <YAxis
            reversed
            domain={[Y_MIN, 'dataMax+30']}
            ticks={yTicks}
            minTickGap={0}
            unit="м"
            dataKey={'y'}
          />
          <XAxis
            dataKey={'x'}
            type="number"
            domain={xDomain}
            orientation="top"
            ticks={xTicks}
            minTickGap={0}
            unit="с"
          />
        </ComposedChart>
      </ResponsiveContainer>
    </Detailed>
  );
};

export default observer(TrafficDetailed);
