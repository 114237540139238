import { useQuery } from '@tanstack/react-query';

import api from '../../../../../../../api';
import {
  DEFAULT_STALE_TIME,
  DEFAULT_UPDATE_DEVICE_ADMIN,
  QUERY_KEY,
} from '../../../../../../../constants/queryConstants';
import rootStore from '../../../../../../../stores/rootStore/rootStore';
import transformToFormData from '../helpers/transformToFormData';

const { DEVICE_FORM_INFO } = QUERY_KEY;

const { fetchDeviceInfoById } = api.devices.info.deviceInfoApi;

const APP_PATH =
  'Admin.DeviceAdmin.DeviceAdminCard.DeviceFormContent.DeviceEditCard.hooks.useDeviceInfo';

const useDeviceInfo = () => {
  const { selectedDevice, isDeviceAdmin } = rootStore.adminPanelStore;

  const { data, isLoading, isError } = useQuery({
    queryKey: [DEVICE_FORM_INFO, selectedDevice, isDeviceAdmin],
    queryFn: async () => {
      if (!selectedDevice) return null;

      const res = await fetchDeviceInfoById(selectedDevice.id, APP_PATH);

      return { formData: transformToFormData(res), data: res };
    },
    refetchInterval: DEFAULT_UPDATE_DEVICE_ADMIN,
    staleTime: DEFAULT_STALE_TIME,
    enabled: !!selectedDevice && isDeviceAdmin,
  });

  const device = data?.formData;
  const deviceInfo = data?.data;

  return { device, isLoading, isError: isError || !device, deviceInfo };
};

export default useDeviceInfo;
