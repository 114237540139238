import { COLORS } from '../../../constants/colorsConstants';

export const STYLE_CONSTANTS = {
  WIDTH: {
    DEFAULT: 1,
  },
  COLOR: {
    DEFAULT: COLORS.MAGENTA,
    FILL: COLORS.GREY_TRANSPARENT,
  },
  RADIUS: {
    DEFAULT: 12,
    SCALE: 64,
  },
  ANGLE: {
    DEFAULT: 0,
  },
  POINTS: {
    DEFAULT: 12,
  },
  PLACEMENTS: {
    LINE: 'line',
    POINT: 'point',
  },
  TEXT_ALIGN: {
    CENTER: 'center',
    LEFT: 'left',
    TOP: 'top',
    BOTTOM: 'bottom',
  },
} as const;

export const DEFAULT_IMAGE_SCALE = 2;
export const DIRECTION_POINT_SCALE = 0.07;
export const DEFAULT_IMAGE_ANCHOR = [0.5, 0.5];

export const DIRECTION_CONSTANTS = {
  WIDTH_MAIN_LINE: 8,
  WIDTH_SECONDARY_LINE: 5,
  WIDTH_OVERPASS_LINE: 4,
  WIDTH_OVERPASS_SECONDARY_LINE: 2,
  LINE_DASH_OVERPASS_LINE: [0.2, 5],
};

export const TEMPORARY_GRAPHICS_STYLE = {
  STROKE_COLOR: COLORS.MAGENTA,
  STROKE_WIDTH: 12,
  ICON_SIZE: 15,
  FILL_COLOR: COLORS.BLUE0,
  ICON_SCALE: 0.1,
  TEXT_STROKE_COLOR: COLORS.WHITE,
  TEXT_STROKE_WIDTH: 0.1,
};

export const DEFAULT_TEXT_STYLE_PROPS = {
  FONT: 'bold 12px arial',
  FONT_SIZE: '12px',
  FONT_FAMILY: 'arial',
  FILL: {
    COLOR: COLORS.BLACK,
  },
  STROKE: {
    WIDTH: 1,
    COLOR: COLORS.WHITE,
  },
};

export const RESOLUTIONS_SCALES = [
  { resolution: 15, value: 0.5 },
  { resolution: 10, value: 0.7 },
  { resolution: 7, value: 0.95 },
  { resolution: 3, value: 1.2 },
];

export const MIN_RESOLUTION_VEHICLE_TEXT_VISIBILITY = 1;

export const STANDARD_IMAGE_ANCHOR = [0.5, 0.5];

export const VEHICLE_STYLE_PROPS = {
  BUS_SIZE: 0.26,
  DIRECTION_SIZE: 1.5,
  PLATE_SIZE: 1.7,
  LABEL_POSITION: 24,
  MAX_LABEL_LENGTH: 12,
  FILL_COLOR: '#FFF',
  STROKE_COLOR: '#000',
  BACKGROUND_COLOR: '#FFF',
  STROKE_WIDTH: 3,
  FONT: 'arial 11px',
};

export const ARROW_MIN_SEGMENT_DISTANCE = 100;
export const ARROW_MIN_RESOLUTION = 5;
export const ARROW_SCALE = 0.6;

export const TRAFFIC_ARROWS = {
  NORMAL:
    'data:image/jpeg;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAAW9JREFUWIXt1s1KAlEcBfAz1YhfF6TCjW2CkaSQoo1blw6CqBthXNjCB7E36BHcS2BPUL1BLdxbL9CAIBjSaaFD11FTx+sY5IELziic3zDj/45GEtvM3lbbd4AdYAYgu01AFsAjABtAE0DRFwFJZ2VJNvuf/QF/YpNskixKv1O6pk800LHurfdWp/UxB3NDMqYKoLknoXardeRjK22JUqok8sl8OKSHAtJXDwDa42V7vQMLAXJMw4yUz8uicFYIxsPxsArMSgA5mZNMsH5dj83APEuY7sYAS2JeMfpHzcUoAchJHiX1ykVF1C5rEePQEIswygFLYgDgFEDXl1E8/BrqrlNvzocD1WXOVVfT1VjqOBV0lbYxug0vSgHOQ2gapkiIhHy1M0vleAasU+oZ4AyidUtXAjijOGfkRDQQ3VdRuhCw6dKJuLfj3qA35GS6JO9IXm16Oy76VfrbdtwA8DRevmRqFPudP/dWvAP8P8A3Qz3/H9W8ebAAAAAASUVORK5CYII=',
  SLOW: 'data:image/jpeg;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAAWRJREFUWIXt1k1Kw0AcBfA3aktsHSwuROlKsODSKtKFCgE3FkGiCIIu3HgQj+ARcoR6AusNdOE+3qBSUArV5yJJnaTRpM0kFeyD0K/Q90va/CeCJCaZmYm2TwFTQATAnCTABHAPoAPABmDlIiDpbyZJm723Hr/TIWmTtJT9tG4iPIg+z8Sz2LuQonEisX1UQnG+6H30CqAFoO09dnScgEhAYIdoDADceZBUmFhAIPVmWTROpdg5NrC4XNKBGQ2gptYwxMF1JQLzoGCc7ADJME9wr6gfMXoA6heu1grYPZfCvCpjZV3GYbQDEmIAYA2Ak88o/ugXQu+8+E/mdHcNjnr/soLqhhEqbcH9GR71Avw/4VZTYqmqHm1kqZrxASlKxwf4gyhl6UiAwSiuH0oYC7M6SmMBWZcGMrQcv3f7DMYheUtyM4vlWH1h5VX62/3ADdz1vq3l9CbI0CjOO3/urngK+H+AL5m7ohc/goVIAAAAAElFTkSuQmCC',
  VERY_SLOW:
    'data:image/jpeg;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAAVxJREFUWIXtlk1qwkAcxV/aWuLHgCs3rgoKLrtzm6VBkOhGiAtdeJAeoUfI3o09QdsbtAv3theoIAiFwOsiDp2Y+BXHpFAf/CEzCbzfMJP3H4MkstRVpu4XgAtADICVJYAF4BnAAoAHwEmFgKQsi6TH1eqbv1qQ9Eg6yndaKzoJzOi6n5xMvrbAjEiWdQEYkSQ0jFlo7LoC3a5Au11APn+rvHkCMF3XIukO7AdQZdtF9HoCnY6JSqWgA+Y4AFXNponxuBwD86rAzM8HcBjMO4I/aiuMHgBV9XoO/b7AcFhErSb2wegHOAwGAO4AzNOJYt/Pbcx8yIcb7WZy1YNBGY2GuWE6RbANb3oB5CG0bYFqVV1trKmq5AAnmCYHkEF0oulxADKKWy2BUulah2lIO5vRcukzrDnJR5L35+iGQTtOwXQbgJOW6a52/ADgZV2pKBrFKevP3YovAP8P4AerNlTry0KT1QAAAABJRU5ErkJggg==',
};

export const TRAFFIC_FACTOR_PROPERTY_NAME = 'factor';
export const TRAFFIC_FACTOR_LIMITS = {
  NORMAL: 3,
  SLOW: 6,
};

export const TRAFFIC_BACKGROUND_PROPS = {
  width: 9,
  color: 'rgba(255,255,255,0.8)',
  miterLimit: 0,
  lineJoin: 'miter',
  lineCap: 'butt',
} as const;
export const OFFSET_DISTANCE = 3;
