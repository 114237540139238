import { observer } from 'mobx-react-lite';

import rootStore from '../../../stores/rootStore/rootStore';
import Admin from '../../Admin/Admin';
import TrafficDetailed from '../../CoordinatedTraffic/TrafficDetailed/TrafficDetailed';
import DetailedStatistics from '../../DetailedStatistics/DetailedStatistics';
import ExternalResource from '../../ExternalResource/ExternalResource';
import TestingDetailed from '../../Panel/TestPanel/ui/TestingDetailed/TestingDetailed';
import ReportDetailed from '../../ReportDetailed/ReportDetailed';
import VideoWall from '../../VideoWall/VideoWall';
import DetailedMain from '../DetailedMain';

const DetailedWrapper = () => {
  const { isDetailedForm, externalResource } = rootStore.uiStore;
  const { isAdminLoad } = rootStore.adminPanelStore;
  const { isVideoPanel } = rootStore.videoWallPanelStore;
  const { isTestingPanel } = rootStore.testingPanelStore;
  const { isModulesSubsystemsDetailed } = rootStore.monitoringPanelStore;
  const { isReportsPanel } = rootStore.reportsPanelStore;
  const { isTrafficDetailed } = rootStore.coordinatedTrafficStore;

  return (
    <>
      {isDetailedForm && <DetailedMain />}
      {isAdminLoad && <Admin />}
      {isVideoPanel && <VideoWall />}
      {isTestingPanel && <TestingDetailed />}
      {isModulesSubsystemsDetailed && <DetailedStatistics />}
      {isReportsPanel && <ReportDetailed />}
      {externalResource && (
        <ExternalResource key={externalResource.name + externalResource.url} />
      )}
      {isTrafficDetailed && <TrafficDetailed />}
    </>
  );
};

export default observer(DetailedWrapper);
