import { useQueryClient } from '@tanstack/react-query';
import { App } from 'antd';

import api from '../../../../../../../../api';
import { QUERY_KEY } from '../../../../../../../../constants/queryConstants';
import rootStore from '../../../../../../../../stores/rootStore/rootStore';
import notification from '../../../../../../../ui-kit/Notification/Notification';
import getDeviceInfo from '../../helpers/getDeviceInfo';

const TITLE = 'Вы точно хотите удалить устройство?';
const APP_PATH =
  'Admin.DeviceAdmin.DeviceAdminCard.DeviceFormContent.DeviceEditCard.DeleteButton.useDeleteDevice';

const { DEVICES_INFO } = QUERY_KEY;
const { deleteDevice } = api.devices.manage.manageDeviceApi;

const useDeleteDevice = () => {
  const { selectedDevice, setAdminsData } = rootStore.adminPanelStore;
  const { regionData } = rootStore.uiStore;

  const { modal } = App.useApp();
  const queryClient = useQueryClient();

  const confirmDeleteDevice = async () => {
    if (!selectedDevice) return;

    const res = await deleteDevice(selectedDevice.id, APP_PATH);

    if (!res) {
      console.error('Ошибка удаления устройства');

      return;
    }

    notification.success('DELETE_DEVICE');
    setAdminsData('selectedDevice', null);
    queryClient.refetchQueries({
      queryKey: [DEVICES_INFO, regionData?.id, null],
    });
  };

  const handleDeleteDevice = () => {
    if (!selectedDevice) return;

    const { id } = selectedDevice;

    const caption = getDeviceInfo(queryClient, id, selectedDevice)?.sname;

    if (!caption)
      return console.error('Неизвестное название устройства', caption);

    modal.confirm({
      title: TITLE,
      content: `Устройство ${id} | ${caption} будет удалено!`,
      onOk: confirmDeleteDevice,
      okText: 'Удалить',
      okButtonProps: { danger: true },
      centered: true,
    });
  };

  return { handleDeleteDevice };
};

export default useDeleteDevice;
