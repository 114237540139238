import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import { IoIosWarning } from 'react-icons/io';

import { COMMANDS_CONTENT } from '../../../../../../../constants/commands';
import { findById } from '../../../../../../../helpers/findBy';
import parseTLMode from '../../../../../../../helpers/parseTLMode';
import { getTLModeName } from '../../../../../../../helpers/statusHelpers';
import rootStore from '../../../../../../../stores/rootStore/rootStore';
import { System } from '../../../../../../../ts/enums/enums';
import { TlMode } from '../../../../../../../ts/models/tl.model';
import { getAvatarIcon } from '../../../../../../DetailedStatistics/Monitoring/SystemMonitoring/helpers/helpers';
import TooltipCommandContent from '../../../../../../TrafficLightDetailed/TlManagement/ManagementCommands/CommandCard/TooltipCommandContent/TooltipCommandContent';
import Popover, {
  PopoverProps,
} from '../../../../../../ui-kit/Popover/Popover';

import styles from './StatusBlock.module.scss';

interface StatusBlockProps {
  mode: TlMode;
  warningText: U<string>;
}

const StatusBlock: FC<StatusBlockProps> = ({ mode, warningText }) => {
  const { interfaceTipsDelay } = rootStore.uiStore;

  const color = parseTLMode(mode).color;
  const modeLabel = getTLModeName(mode);
  const commandInfo = findById(COMMANDS_CONTENT, mode)?.tip;

  const popoverProps: PopoverProps = {
    tipsDelay: interfaceTipsDelay,
    placement: 'bottom',
  };

  return (
    <div className={styles.container}>
      {warningText ? (
        <Popover {...popoverProps} content={warningText}>
          <IoIosWarning className={styles.warningIco} />
        </Popover>
      ) : null}
      <Popover
        {...popoverProps}
        content={
          commandInfo ? (
            <TooltipCommandContent
              title={modeLabel}
              infoTooltip={commandInfo}
              mode={mode}
              isStatus={false}
            />
          ) : null
        }
      >
        <span style={{ color }}>{getAvatarIcon(System.Lights)}</span>
      </Popover>
    </div>
  );
};

export default observer(StatusBlock);
