import {
  EnabledStatus,
  ExternalStatus,
} from '../../../../../api/adminObjects/systems/adapter/model/adapter.model';
import { COLORS } from '../../../../../constants/colorsConstants';
import { LibraryValues } from '../../../../../stores/uiStore/uiStore.model';
import { AdapterKeys, TerminationKeys } from '../models/monitoring.models';

export const STATUS_ADAPTER = {
  OK: 'OK',
  ERROR: 'ERROR',
  WARNING: 'WARNING',
  TERMINATED: 'TERMINATED',
  SIGTERM: 'SIGTERM',
} as const;

export const ADAPTER_LABELS: Record<ValueOf<typeof AdapterKeys>, string> = {
  systemId: 'Id системы',
  systemName: 'Название системы',
  regions: 'Регионы',
  hostName: 'Название хоста, откуда запускается ВМ',
  sessionUid: 'Уникальный идентификатор сессии',
  workTime: 'Время работы',
  status: 'Статус работы',
  passesDtFromStart: 'Получено проездов (с начала работы)',
  passesDtFrom10Min: 'Получено проездов за 10 минут',
  passesTLFromStart: 'Получено событий (с начала работы)',
  passesTLFrom10Min: 'Получено событий за 10 минут',
  lastTime: 'Время формирования сообщения с таймзоной, сек',
  error: 'Дополнительная информация о работе адаптера',
  adapterWork: 'Состояние адаптера (включен / отключен)',
  startedJobsFromStart: 'Запущено заданий (с начала работы)',
  startedJobsFromLast10Min: 'Запущено заданий за последние 10 минут',
  latestPassageTimeTZ:
    'Время последнего зафиксированного адаптером проезда, с таймзоной',
  latestEventTimeTZ:
    'Время последнего зафиксированного адаптером события, с таймзоной',
  latestStartedJobTimeTZ:
    'Время последнего зафиксированного старта задания, с таймзоной',
};

export const TERMINATION_LABELS: Record<TerminationKeys, string> = {
  newSessionUuid: 'Uuid новой сессии',
  oldSessionUuid: 'Uuid старой сессии',
  sessionStatus: 'Статус завершенной сессии',
  timeNewSessionTZ: 'Время переноса статистики в архив',
};

const { Off, On } = EnabledStatus;

type EnabledStatusDict = Record<EnabledStatus, { name: string; color: string }>;

const { SUCCESS, GREY100, ERROR, ALLOWED } = COLORS;

export const ENABLED_STATUS_DICT: EnabledStatusDict = {
  [Off]: {
    name: 'Выключен',
    color: GREY100,
  },
  [On]: {
    name: 'Включен',
    color: SUCCESS,
  },
};

enum DeviceStatus {
  Work = 'count_ok',
  Error = 'count_error',
}

export const CAMERA_STATUS_PIE = [
  {
    type: DeviceStatus.Work,
    name: 'Работает',
    color: SUCCESS,
  },
  {
    type: DeviceStatus.Error,
    name: 'Не работает',
    color: ERROR,
  },
] as const;

export const EXTERNAL_STATUS_DICT: LibraryValues[] = [
  {
    id: ExternalStatus.Stopped,
    name: 'Остановлен',
  },
  {
    id: ExternalStatus.Active,
    name: 'Активен',
  },
  {
    id: ExternalStatus.Progress,
    name: 'Запускается',
  },
  {
    id: ExternalStatus.Error,
    name: 'Ошибка создания / Проблема потока',
  },
];

export const EXTERNAL_STATUS_COLORS: Record<ExternalStatus, string> = {
  [ExternalStatus.Stopped]: GREY100,
  [ExternalStatus.Active]: SUCCESS,
  [ExternalStatus.Progress]: ALLOWED,
  [ExternalStatus.Error]: ERROR,
};

export const MONITORING_KEY = 'monitoring_';
