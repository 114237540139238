import { FC } from 'react';

import rootStore from '../../../../../stores/rootStore/rootStore';
import { GRAPHS_TITLE } from '../../../../TrafficLightDetailed/TlDetectors/constants/constants';
import Popover, { PopoverProps } from '../../../../ui-kit/Popover/Popover';
import { CHART_UNITS, ChartBDKeys } from '../Chart/constants/constants';
import getChartParamName from '../Chart/helpers/getChartParamName';
import { IDataGraph } from '../PopupDetector.model';

import styles from './CountBlock.module.scss';

interface CountBlockProps {
  radioValue: ChartBDKeys;
  activeCell: U<IDataGraph>;
}

const CountBlock: FC<CountBlockProps> = ({ radioValue, activeCell }) => {
  const { interfaceTipsDelay } = rootStore.uiStore;

  if (!activeCell) return null;

  const graphParam = getChartParamName(radioValue);

  const popoverProps: PopoverProps = {
    tipsDelay: interfaceTipsDelay,
    content: graphParam
      ? `${GRAPHS_TITLE[graphParam].value}: ${activeCell.comparableValue} ${CHART_UNITS[radioValue]}`
      : 'Нет данных',
    placement: 'left',
  };

  if (!graphParam) return null;

  return (
    <Popover {...popoverProps}>
      <span
        className={styles.infoBlock}
        style={{ backgroundColor: activeCell.status }}
      >
        {activeCell.comparableValue}
      </span>
    </Popover>
  );
};

export default CountBlock;
