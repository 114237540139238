import { Button } from 'antd';
import { FC } from 'react';

import { IIteractionButton } from '../../../../ts/models/table.model';

import styles from './IteractionButtons.module.scss';

interface IIteractionButtons {
  iteractionButtons?: IIteractionButton[];
}

const IteractionButtons: FC<IIteractionButtons> = ({ iteractionButtons }) => {
  if (!iteractionButtons) return null;

  return (
    <div className={styles.iteractionButtons}>
      {iteractionButtons.map((iteractionButton, i) => (
        <Button
          key={i}
          type="primary"
          onClick={iteractionButton.handler}
          size="middle"
        >
          {iteractionButton.text}
        </Button>
      ))}
    </div>
  );
};

export default IteractionButtons;
