import { useMemo } from 'react';

import { findById } from '../../../helpers/findBy';
import rootStore from '../../../stores/rootStore/rootStore';
import { getCorrectScene } from '../helpers/sceneHelpers/sceneHelpers';

const useScene = (id: N<number>) => {
  const { detectors } = rootStore.mapDataStore;

  const scene = useMemo(() => {
    if (!id) return;

    const detector = findById(detectors, id);

    const extOptions = detector?.dai_jdt80?.source_info?.ext_options;

    const jsettings =
      extOptions?.camera_jsetting2?.dt_ext_jsetting ??
      extOptions?.camera_jsetting;

    const sceneRes = jsettings?.scene;

    return getCorrectScene(sceneRes);
  }, [detectors, id]);

  return scene;
};

export default useScene;
