import classNames from 'classnames';
import { FC, ReactNode } from 'react';

import { IWidgetPieInfo } from '../../../Panel/StatisticsPanel/MonitoringCard/models/monitoring.model';
import CircleChart from '../../../ui-kit/CircleChart/CircleChart';
import DefaultChecked from '../../../ui-kit/DefaultChecked/DefaultChecked';

import styles from './MonitoringWidget.module.scss';

interface MonitoringWidgetProps {
  title: string;
  dataPieChart: U<IWidgetPieInfo>;
  isNeedBg?: boolean;
  activeSectors?: string[];
  isEmptyData: boolean;
  isLoading?: boolean;
  footerTitle?: string;
  footer?: ReactNode;
  handleClickSector?: (sectorName: string, len: U<number>) => void;
}

const MonitoringWidget: FC<MonitoringWidgetProps> = ({
  title,
  dataPieChart,
  activeSectors,
  isNeedBg = true,
  isEmptyData,
  footerTitle,
  isLoading = false,
  footer,
  handleClickSector,
}) => {
  const containerStyle = classNames(styles.container, {
    [styles.bg]: isNeedBg,
  });

  return (
    <div className={containerStyle}>
      <h3 className={styles.title}>{title}:</h3>
      <DefaultChecked
        isEmptyData={isEmptyData}
        isLoading={isLoading}
        noDataProps={{ classNameEmpty: styles.empty }}
      >
        {dataPieChart ? (
          <>
            <div className={styles.chartContainer}>
              <CircleChart
                data={dataPieChart.data}
                colors={dataPieChart.colors}
                isLabel={false}
                handleClickSector={handleClickSector}
                activeSectors={activeSectors}
              />
            </div>
            <div className={styles.footer}>
              {footer ?? <p>{`${footerTitle}: ${dataPieChart.total}`}</p>}
            </div>
          </>
        ) : null}
      </DefaultChecked>
    </div>
  );
};

export default MonitoringWidget;
