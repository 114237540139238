import { useQueryClient } from '@tanstack/react-query';
import { observer } from 'mobx-react-lite';

import { ExternalLink } from '../../../../../api/devices/model/devices.model';
import { QUERY_KEY } from '../../../../../constants/queryConstants';
import { findById } from '../../../../../helpers/findBy';
import rootStore from '../../../../../stores/rootStore/rootStore';
import SourceInfo from '../SourceEditForm/SourceInfo/SourceInfo';

const { EXTERNAL_LINKS_INFO } = QUERY_KEY;

const SourceWatchCard = () => {
  const { selectedSource, selectedDevice } = rootStore.adminPanelStore;

  const queryClient = useQueryClient();

  const sources = queryClient.getQueryData<N<ExternalLink[]>>([
    EXTERNAL_LINKS_INFO,
    selectedDevice,
  ]);

  if (!selectedSource) return null;

  const activeSource = findById(sources ?? [], selectedSource?.id);

  return <SourceInfo fullSourceInfo={activeSource} />;
};

export default observer(SourceWatchCard);
