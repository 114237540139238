import { type DefaultOptionType } from 'antd/es/select';

import { SourceDisplayType } from '../../../../../../stores/adminPanelStore/constants/constants';
import rootStore from '../../../../../../stores/rootStore/rootStore';
import { SEPARATE } from '../../../../../Panel/ScriptsControl/SelectModule/DispatchGroupControl/SelectItem/helpers/helpers';
import useSourceInfo from '../../SourceContainer/hooks/useSourceInfo';
import getSourceOptions from '../helpers/getSourceOptions';

const { SourceEditForm } = SourceDisplayType;

const useSourceSelect = () => {
  const { setAdminsData } = rootStore.adminPanelStore;

  const { isError, isLoading, sources } = useSourceInfo();

  const options = getSourceOptions(sources);

  const isDisabled = (isError || !options) && !isLoading;

  const onChange = (value: N<string>, props: DefaultOptionType) => {
    if (!value) return setAdminsData('selectedSource', null);

    const [id] = props.id.split(SEPARATE);

    const idNum = Number(id);

    if (isNaN(idNum)) {
      console.error(`Неккоректные данные по устройству id = ${id}`);

      return;
    }

    setAdminsData('selectedSource', {
      id: idNum,
      formType: SourceEditForm,
    });
  };

  return { isLoading, isDisabled, options, onChange };
};

export default useSourceSelect;
