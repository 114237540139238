import { observer } from 'mobx-react-lite';

import DefaultChecked from '../../../../../ui-kit/DefaultChecked/DefaultChecked';
import VideoCameraWithPreloader from '../../../../../VideoCamera/VideoCameraWithPreloader/VideoCameraWithPreloader';

import useVideoContent from './hooks/useVideoContent';

import styles from './VideoContent.module.scss';

const VideoContent = () => {
  const { cameraData, isFetchingCamera } = useVideoContent();

  return (
    <div className={styles.container}>
      <DefaultChecked
        isEmptyData={!cameraData}
        isLoading={!!isFetchingCamera}
        noDataProps={{ textError: 'Камера не найдена' }}
      >
        {cameraData ? (
          <VideoCameraWithPreloader
            cameraData={cameraData}
            isBorderRadius={false}
          />
        ) : null}
      </DefaultChecked>
    </div>
  );
};

export default observer(VideoContent);
