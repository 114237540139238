import { observer } from 'mobx-react-lite';

import rootStore from '../../../../stores/rootStore/rootStore';
import { DEVICE_LABELS } from '../constants/constants';
import Header from '../Header/Header';

import DeviceFormContent from './DeviceFormContent/DeviceFormContent';
import useDeviceAdminCard from './hooks/useDeviceAdminCard';

import styles from './DeviceAdminCard.module.scss';

const DeviceAdminCard = () => {
  const { deviceFormType } = rootStore.adminPanelStore;

  const { isShowArrow, handleClick } = useDeviceAdminCard();

  return (
    <div className={styles.block}>
      <Header
        title={deviceFormType ? DEVICE_LABELS[deviceFormType] : null}
        handleClick={handleClick}
        isShowArrow={isShowArrow}
      />
      <DeviceFormContent />
    </div>
  );
};

export default observer(DeviceAdminCard);
