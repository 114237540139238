import { message, Modal } from 'antd';
import { FC, useCallback, useEffect, useState } from 'react';

import api from '../../../../api';
import { TableData, TableKeys } from '../../../../ts/models/table.model';
import ActionButtons from '../../../ui-kit/Table/ActionButtons/ActionButtons';
import { DEFAULT_TABLE } from '../../../ui-kit/Table/constants';
import Table from '../../../ui-kit/Table/Table';
import useSubmitForm from '../../hooks/useSubmitForm';
import { Collaboration } from '../../models/adminTypes.model';
import DivisionsForm from '../DivisionsForm/DivisionsForm';
import { getDivisionsBtns } from '../helpers/getDivisionsBtns';
import submitDivisions from '../helpers/submitDivisions';
import {
  DivisionsProps,
  SubmitDivisionsArgs,
  SubmitDivValues,
} from '../models/Divisions.model';
import { ActionType } from '../models/Organizations.model';

const { DivisionsTable } = TableKeys;

export const DIV_PATH = '.Admin.Organizations.Divisions';
const ADD_DIV = 'Добавить подразделение';

const Divisions: FC<DivisionsProps> = ({ id }) => {
  const [divisionsTableData, setDivisionsTableData] =
    useState<TableData>(DEFAULT_TABLE);
  const [isUpdate, setIsUpdate] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoadData, setIsLoadData] = useState(true);
  const [isEdit, setIsEdit] = useState(false);
  const [collaboration, setCollaboration] = useState<Collaboration>({});

  const { form, isSubmit, isLoading, submit } = useSubmitForm<
    SubmitDivValues,
    SubmitDivisionsArgs
  >({
    handleSubmit: submitDivisions,
    props: {
      collaboration,
      setIsUpdate,
      setIsModalOpen,
      id,
      isEdit,
    },
    initData: collaboration,
  });

  const handleDelete = useCallback(async (id: number) => {
    const res = await api.catalogs.organizations.divisions.delete(id, DIV_PATH);

    if (!res) return;

    message.success('DELETED_SUCCESSFULLY');
    setIsUpdate(true);
  }, []);

  const handleFormEdit = useCallback(
    (type: ActionType, collab?: Collaboration) => {
      if (collab) {
        form.setFieldsValue(collab);
        setCollaboration(collab);
      }

      setIsEdit(type === ActionType.Edit);
      setIsModalOpen(true);
    },
    [form]
  );

  const getDivisionsData = useCallback(async () => {
    setIsLoadData(true);
    setDivisionsTableData(DEFAULT_TABLE);
    setIsUpdate(false);
    const res = await api.catalogs.organizations.divisions.getList(
      id,
      DIV_PATH
    );

    res && setDivisionsTableData(res.data);
    setIsLoadData(false);
  }, [id]);

  useEffect(() => {
    getDivisionsData();
  }, [getDivisionsData]);

  useEffect(() => {
    isUpdate && getDivisionsData();
  }, [getDivisionsData, isUpdate]);

  useEffect(() => {
    if (!isModalOpen) return;

    if (!isEdit) return form.resetFields();

    form.setFieldsValue(collaboration);
  }, [form, isModalOpen, collaboration, isEdit]);

  const collaborationRender = (collaboration: any) => {
    const isDisabledDelete = divisionsTableData.dataRows.length === 1;

    return (
      <ActionButtons
        actionButtons={getDivisionsBtns({
          handleEdit: (collab: OWKS<any>) =>
            handleFormEdit(ActionType.Edit, collab),
          handleDelete,
          collaboration,
          isDisabledDelete,
        })}
      />
    );
  };

  const title = `Форма ${
    isEdit ? 'редактирования' : 'добавления'
  } подразделения`;

  const okText = isEdit ? 'Обновить' : 'Добавить';

  return (
    <>
      <Table
        tableData={divisionsTableData}
        collaborationRender={collaborationRender}
        tableConfigType={DivisionsTable}
        iteractionButtons={[
          {
            text: ADD_DIV,
            handler: () => handleFormEdit(ActionType.Add),
          },
        ]}
        isLoading={isLoadData}
        refreshData={getDivisionsData}
        isOrderNumber
      />
      <Modal
        open={isModalOpen}
        onOk={submit}
        confirmLoading={isLoading}
        onCancel={() => setIsModalOpen(false)}
        title={title}
        okText={okText}
        cancelText="Отменить"
        okButtonProps={{
          disabled: !isSubmit,
        }}
      >
        <DivisionsForm form={form} />
      </Modal>
    </>
  );
};

export default Divisions;
