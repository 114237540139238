/* eslint-disable jsx-a11y/no-static-element-interactions */
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { CSSProperties, FC, ReactNode, useEffect, useRef } from 'react';

import rootStore from '../../../stores/rootStore/rootStore';
import { TChildren } from '../../../ts/models/general.model';

import DetailedHeader from './DetailedHeader/DetailedHeader';

import styles from './Detailed.module.scss';

export type DetailedType = Omit<DetailedProps, 'children'>;

export interface DetailedProps {
  children: TChildren;
  isRightPanelStyle?: boolean;
  header: string | JSX.Element;
  onClose: () => void;
  headerIco?: JSX.Element;
  containerStyle?: CSSProperties;
  wrapperStyle?: CSSProperties;
  subHeaderIco?: ReactNode;
  subtitle?: string;
}

const Detailed: FC<DetailedProps> = ({
  children,
  isRightPanelStyle = false,
  containerStyle,
  wrapperStyle,
  ...headerProps
}) => {
  const {
    infoData,
    isPanel,
    isOffsetRightPanel,
    isRightPanel,
    isCollapseRightPanel,
    setIsNot,
  } = rootStore.uiStore;

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    containerRef.current?.scrollTo(0, 0);
  }, [infoData]);

  const container = classNames({
    [styles.container]: true,
    [styles.detailed]: !isRightPanelStyle,
    [styles.isPanel]: !isRightPanelStyle && isPanel,
    [styles.isRightPanelDefault]: isRightPanelStyle,
    [styles.isRightPanel]: isOffsetRightPanel,
  });

  const contentStyle = classNames({
    [styles.content]: true,
    [styles.contentRightPanel]: isRightPanelStyle,
  });

  const collapseButtonStyle = classNames({
    [styles.collapseButton]: true,
    [styles.collapseButtonHidden]: !isRightPanel,
  });

  const handleCollapseButton = () => {
    setIsNot('isCollapseRightPanel');
  };

  return (
    <div className={container} style={containerStyle}>
      <div className={styles.contentWrapper} style={wrapperStyle}>
        <DetailedHeader {...headerProps} isRightPanel={isRightPanelStyle} />
        <main className={contentStyle} ref={containerRef}>
          {children}
        </main>
      </div>
      {isRightPanelStyle && (
        <div className={collapseButtonStyle} onClick={handleCollapseButton}>
          {isCollapseRightPanel ? 'Развернуть панель' : 'Свернуть панель'}
        </div>
      )}
    </div>
  );
};

export default observer(Detailed);
