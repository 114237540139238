import type { CanvasFilterOptions } from 'ol-ext/filter/CanvasFilter';

import { TMap } from '../../../stores/mapStore/mapStore.model';
import { addFilter, restoreDefaultFilter } from '../basemaps';

export const applyLayerFilter = (
  map: TMap,
  id: string,
  settings?: CanvasFilterOptions
) => {
  if (!map || !id || !settings) {
    return;
  }

  const layer = map.getAllLayers().find((layer) => layer.get('id') === id);

  if (!layer) {
    return;
  }

  restoreDefaultFilter(layer);

  addFilter(layer, settings);
};

export const getInitialLayerFilterSettings = (): CanvasFilterOptions => {
  return {
    blur: 0,
    brightness: 100,
    contrast: 100,
    grayscale: 0,
    hueRotate: 0,
    invert: 0,
    saturate: 100,
    sepia: 0,
    shadow: undefined,
    shadowBlur: 0,
    shadowColor: 0,
    url: '',
  };
};
