import { useQuery } from '@tanstack/react-query';

import {
  DEFAULT_STALE_TIME,
  QUERY_KEY,
} from '../../../../../constants/queryConstants';
import { Path } from '../../../../api.model';
import { FetchCoordGroupsList } from '../coordGroupApi.model';

const useGroupsListQuery = (
  regionId: Nullish<number>,
  path: Path,
  fetchCoordGroupsList: FetchCoordGroupsList
) =>
  useQuery({
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: [QUERY_KEY.COORD_GROUPS_LIST, regionId],
    queryFn: async () => await fetchCoordGroupsList(regionId, path),
    staleTime: DEFAULT_STALE_TIME,
  });

export default useGroupsListQuery;
