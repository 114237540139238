import { ReactNode } from 'react';

import { CartographyTabName } from '../components/Panel/MainPanel/constants';
import { BTN_POSITION } from '../components/ProfileSettings/MainSettings/QueryToolsBtnPosition/constants/btnPositionConstants';
import {
  Markers,
  MarkersKeys,
  UIStoreType,
} from '../stores/uiStore/uiStore.model';
import { System } from '../ts/enums/enums';

import {
  DEFAULT_MAP_ICON_SIZE,
  EVENTS,
  PARAMS,
  SYSTEM,
  LOGO_NAMES,
  DEV_TOOLS,
  HINTS,
  DISPLAY_INFO,
  CLUSTERS_INFO,
  GIS_BUTTON_INFO,
  DEVICE_TYPES_ID,
} from './constants';
import { DEVICE_TYPE_IDS } from './deviceConstants';

type SettingKeys = keyof UIStoreType['profileValues'];

export type ProfileSettingsMain = Readonly<{
  TITLE: string;
  DATA: {
    LABEL: string;
    NAME: SettingKeys;
    IS_RELOAD_REQUIRED: boolean;
    POPOVER_CONTENT: ReactNode;
    DEPENDENT_KEYS?: SettingKeys[];
  }[];
}>;

type SettingsData = ProfileSettingsMain['DATA'];

export const INITIAL_MARKERS: Markers = {
  // Subsystems
  isLights: true,
  isDetectors: true,
  isCameras: true,
  isSpecialTransport: false,
  isPublicTransport: false,
  isMeteo: true,
  isTraffic: false,
  // Events
  isRta: false,
  isRoadWorks: false,
  isActivities: false,
  isTrafficRestrictions: false,
};

export const INITIAL_SETTINGS = {
  isWidgetLoad: false,
  isClusters: true,
  interfaceTipsDelay: 1,
  mapTipsDelay: 1,
  mapButtonsTipsDelay: 2,
  profileTipsDelay: 2,
  gisTooltipsDelay: 2,
  isSummaryInfo: false,
  mapIconsSize: DEFAULT_MAP_ICON_SIZE,
  logoName: LOGO_NAMES.osut,
  //temporary delete because it works not so good
  // isCenterDependsOnPanels: false,
  isOpenInfoPanel: true,
  isInfoBtn: true,
  isAlarmAlert: true,
  isDevMapInfo: false,
  isStaticDataLog: false,
  isZodLog: false,
  isVideoDtLinksLog: false,
  isIncorrectCoordinatesLog: false,
  isActiveDeviceLog: false,
  isQueryDevTools: false,
  queryToolsBtnPosition: BTN_POSITION.TOP_LEFT,
  isMinificMap: true,
  isScaleLine: true,
  isNeedOpenedCluster: true,
  isSearchBtnGis: true,
  isRouteMeasurementBtnGis: true,
  isLayersBtnGis: true,
  isMeasurementBtnGis: true,
  isBasemapBtnGis: true,
  isMeteoDataBtnGis: true,
  isTrafficBtnGis: true,
  isTrafficGraphBtnGis: true,
};

export const POINTS_INITIAL_SETTINGS = {
  isShowPhaseCircle: true,
  isUnderCircleFill: false,
  isPhaseCircleLabel: false,
  phaseCircleDiameterRate: 1,
};

export const POPOVER_CONTENT_DISPLAY = {
  CENTER_DEPENDS_ON_PANEL: (
    <div>
      Вкл./откл. смещения центра карты <br />
      при открытии/ закрытии панелей.
    </div>
  ),
  INFO_PANEL: (
    <div>
      Вкл./откл. открытии инфопанели
      <br />
      при закрытии вкладки <b>подробнее</b>.
    </div>
  ),
  INFO_BTN: <div>Вкл./откл. блока информации</div>,
  ALARM_ALERT: <div>Вкл./откл. мигающих уведомлений</div>,
  MINIFIC_MAP: <div>Вкл./откл. минифицированную карту</div>,
  SCALE_LABEL: <div>Вкл./откл. отображение масштаба карты</div>,
  SLIDER: 'Изменить размер иконок',
};

export const POPOVER_CONTENT_CLUSTERS = {
  CLUSTERIZATION: (
    <div>
      Вкл./откл. объединения иконок <br />
      на карте при масштабировании.
    </div>
  ),
  OPENED: (
    <div>
      Вкл./откл. распадение кластера <br />
      на объекты при клике.
    </div>
  ),
};

export const POPOVER_CONTENT_DEV_TOOLS = {
  MAP_INFO_BTN: 'Вкл./откл. данных карты в кнопке информации',
  STATIC_DATA_LOG: 'Вкл./откл. логирования статических данных',
  ZOD_LOG: 'Вкл./откл. логирования ошибок валидации входящих данных',
  VIDEO_DT_LINKS_LOG:
    'Вкл./откл. логирования ссылок на удаленные устройства в видеодетекторе',
  INCORRECT_COORDINATES:
    'Вкл./откл. логирования некорректных координат в консоле',
  ACTIVE_DEVICE: 'Вкл./откл. логирования данных выбранного устройства карты',
  QUERY_TOOLS: 'Вкл./откл. инструмента отладки библиотеки React Query',
  QUERY_TOOLS_BTN_POSITION:
    'Расположение кнопки отладки React Query. Активно при включенном кэшировании запросов.',
};

export const POPOVER_CONTENT_HELPERS = {
  INTERFACE: 'Время появления подсказки в интерфейсе',
  MAP: 'Время появления подсказки на карте',
  BTNS_MAP: 'Время появления подсказки на кнопках карты',
  PROFILE: 'Время появления подсказки в профиле',
  GIT_TOOLTIPS: 'Время появления подсказки над объектом слоя',
};

export const POPOVER_CONTENT_GIS_BUTTON = {
  SEARCH: 'Вкл./откл. кнопки поиска в интерфейсе',
  ROUTE_MEASUREMENT: 'Вкл./откл. кнопки расчёта маршрута в интерфейсе',
  LAYERS: 'Вкл./откл. кнопки списка слоев в интерфейсе',
  MEASUREMENT: 'Вкл./откл. кнопки картографических измерений в интерфейсе',
  BASEMAP: 'Вкл./откл. кнопки картографических подложек в интерфейсе',
  METEODATA: 'Вкл./откл. кнопки погоды в интерфейсе',
  TRAFFIC: 'Вкл./откл. кнопки дорожного затора в интерфейсе',
  TRAFFIC_GRAPH: 'Вкл./откл. кнопки графика потоков ТС в интерфейсе',
};

const DEVICES_DICT = Object.entries(DEVICE_TYPES_ID);

export const SUBSYSTEMS_DATA = (
  [
    {
      LABEL: SYSTEM.LIGHTS,
      NAME: MarkersKeys.IsLights,
      IS_RELOAD_REQUIRED: true,
      POPOVER_CONTENT: (
        <div>
          Вкл./откл. иконок «Светофорные объекты» <br /> на карте{' '}
          <b>при загрузке приложения.</b>
        </div>
      ),
    },

    {
      LABEL: SYSTEM.DETECTORS,
      NAME: MarkersKeys.IsDetectors,
      IS_RELOAD_REQUIRED: true,
      POPOVER_CONTENT: (
        <div>
          Вкл./откл. иконок «Детекторы транспорта» <br /> на карте{' '}
          <b>при загрузке приложения.</b>
        </div>
      ),
    },

    {
      LABEL: SYSTEM.CAMERAS,
      NAME: MarkersKeys.IsCameras,
      IS_RELOAD_REQUIRED: true,
      POPOVER_CONTENT: (
        <div>
          Вкл./откл. иконок «Камеры» <br /> на карте{' '}
          <b>при загрузке приложения.</b>
        </div>
      ),
    },
    {
      LABEL: SYSTEM.SPECIAL_TRANSPORT,
      NAME: MarkersKeys.IsSpecialTransport,
      IS_RELOAD_REQUIRED: true,
      POPOVER_CONTENT: (
        <div>
          Вкл./откл. иконок «Спец.техника» <br /> на карте{' '}
          <b>при загрузке приложения.</b>
        </div>
      ),
    },
    {
      LABEL: SYSTEM.PUBLIC_TRANSPORT,
      NAME: MarkersKeys.IsPublicTransport,
      IS_RELOAD_REQUIRED: true,
      POPOVER_CONTENT: (
        <div>
          Вкл./откл. иконок «Общественный транспорт» <br /> на карте{' '}
          <b>при загрузке приложения.</b>
        </div>
      ),
    },
    {
      LABEL: EVENTS.RTA,
      NAME: MarkersKeys.IsRta,
      IS_RELOAD_REQUIRED: true,
      POPOVER_CONTENT: (
        <div>
          Вкл./откл. иконок «ДТП» <br /> на карте{' '}
          <b>при загрузке приложения.</b>
        </div>
      ),
    },
    {
      LABEL: EVENTS.ROAD_WORKS,
      NAME: MarkersKeys.IsRoadWorks,
      IS_RELOAD_REQUIRED: true,
      POPOVER_CONTENT: (
        <div>
          Вкл./откл. иконок «Дорожные работы» <br /> на карте{' '}
          <b>при загрузке приложения.</b>
        </div>
      ),
    },
    {
      LABEL: SYSTEM.METEO,
      NAME: MarkersKeys.IsMeteo,
      IS_RELOAD_REQUIRED: true,
      POPOVER_CONTENT: (
        <div>
          Вкл./откл. иконок «Метеостанции» <br /> на карте{' '}
          <b>при загрузке приложения.</b>
        </div>
      ),
    },
  ] as const
).filter(
  ({ LABEL }) =>
    DEVICE_TYPE_IDS.some((el) => {
      return (
        Number(DEVICES_DICT.find(([id, value]) => LABEL === value)?.at(0)) ===
        el
      );
    }) || LABEL === System.PublicTransport
);

const PARAMS_DATA: SettingsData = [
  {
    LABEL: PARAMS.SUMMARY_INFO,
    NAME: 'isSummaryInfo',
    IS_RELOAD_REQUIRED: false,
    POPOVER_CONTENT: (
      <div>
        Вкл./откл. отображения сводной <br />
        информации в окне «Параметры»
      </div>
    ),
  },
];

const EVENTS_DATA: SettingsData = [
  {
    LABEL: EVENTS.RTA,
    NAME: MarkersKeys.IsRta,
    IS_RELOAD_REQUIRED: true,
    POPOVER_CONTENT: (
      <div>
        Вкл./откл. иконок «ДТП» <br /> на карте
        <b> при загрузке приложения.</b>
      </div>
    ),
  },
  {
    LABEL: EVENTS.ROAD_WORKS,
    NAME: MarkersKeys.IsRoadWorks,
    IS_RELOAD_REQUIRED: true,
    POPOVER_CONTENT: (
      <div>
        Вкл./откл. иконок «Дорожные работы» <br /> на карте{' '}
        <b>при загрузке приложения.</b>
      </div>
    ),
  },
  {
    LABEL: EVENTS.ACTIVITIES,
    NAME: MarkersKeys.IsActivities,
    IS_RELOAD_REQUIRED: true,
    POPOVER_CONTENT: (
      <div>
        Вкл./откл. иконок «Мероприятия» <br /> на карте{' '}
        <b>при загрузке приложения.</b>
      </div>
    ),
  },
  {
    LABEL: EVENTS.TRAFFIC_RESTRICTIONS,
    NAME: MarkersKeys.IsTrafficRestrictions,
    IS_RELOAD_REQUIRED: true,
    POPOVER_CONTENT: (
      <div>
        Вкл./откл. иконок «Ограничения в движении» <br /> на карте{' '}
        <b>при загрузке приложения.</b>
      </div>
    ),
  },
];

export const TRAFFIC_LIGHTS_PHASES_DATA: SettingsData = [
  {
    LABEL: 'отображать круг',
    NAME: 'isShowPhaseCircle',
    IS_RELOAD_REQUIRED: false,
    POPOVER_CONTENT: (
      <div>
        Вкл./откл. отображения светофорного круга на карте <br />
        по умолчанию. Настройка применяется сразу.
      </div>
    ),
  },
  {
    LABEL: 'продолжительность фаз',
    NAME: 'isPhaseCircleLabel',
    IS_RELOAD_REQUIRED: false,
    POPOVER_CONTENT: (
      <div>
        Вкл./откл. отображения времени <br />
        работы фаз светофора на карте.
      </div>
    ),
  },
  {
    LABEL: 'элементы под заливкой',
    NAME: 'isUnderCircleFill',
    IS_RELOAD_REQUIRED: false,
    POPOVER_CONTENT: (
      <div>
        Отображение стрелок, переходов, картинки перекрестка <br />
        под/над заливкой временной шкалы фазового круга.
      </div>
    ),
  },
  {
    LABEL: 'диаметр круга',
    NAME: 'phaseCircleDiameterRate',
    IS_RELOAD_REQUIRED: false,
    POPOVER_CONTENT: <div>Коэффициент диаметра фазового круга.</div>,
  },
];

export const HINTS_DATA = [
  {
    LABEL: HINTS.INTERFACE,
    NAME: 'interfaceTipsDelay',
    IS_RELOAD_REQUIRED: false,
    POPOVER_CONTENT: POPOVER_CONTENT_HELPERS.INTERFACE,
  },
  {
    LABEL: HINTS.MAP,
    NAME: 'mapTipsDelay',
    IS_RELOAD_REQUIRED: false,
    POPOVER_CONTENT: POPOVER_CONTENT_HELPERS.MAP,
  },
  {
    LABEL: HINTS.BTNS_MAP,
    NAME: 'mapButtonsTipsDelay',
    IS_RELOAD_REQUIRED: false,
    POPOVER_CONTENT: POPOVER_CONTENT_HELPERS.BTNS_MAP,
  },
  {
    LABEL: HINTS.PROFILE,
    NAME: 'profileTipsDelay',
    IS_RELOAD_REQUIRED: false,
    POPOVER_CONTENT: POPOVER_CONTENT_HELPERS.PROFILE,
  },
  {
    LABEL: HINTS.GIT_TOOLTIPS,
    NAME: 'gisTooltipsDelay',
    IS_RELOAD_REQUIRED: false,
    POPOVER_CONTENT: POPOVER_CONTENT_HELPERS.GIT_TOOLTIPS,
  },
] as const;

export const CLUSTERS_DATA: SettingsData = [
  {
    LABEL: CLUSTERS_INFO.CLUSTERIZATION,
    NAME: 'isClusters',
    IS_RELOAD_REQUIRED: false,
    POPOVER_CONTENT: POPOVER_CONTENT_CLUSTERS.CLUSTERIZATION,
  },
  {
    LABEL: CLUSTERS_INFO.OPENED,
    NAME: 'isNeedOpenedCluster',
    IS_RELOAD_REQUIRED: false,
    POPOVER_CONTENT: POPOVER_CONTENT_CLUSTERS.OPENED,
    DEPENDENT_KEYS: ['isClusters'],
  },
];

const DISPLAY_DATA: SettingsData = [
  //temporary delete because it works not so good
  // {
  //   LABEL: DISPLAY_INFO.CENTER,
  //   NAME: 'isCenterDependsOnPanels',
  //   IS_RELOAD_REQUIRED: false,
  //   POPOVER_CONTENT: POPOVER_CONTENT_DISPLAY.CENTER_DEPENDS_ON_PANEL,
  // },
  {
    LABEL: DISPLAY_INFO.INFO_PANEL,
    NAME: 'isOpenInfoPanel',
    IS_RELOAD_REQUIRED: false,
    POPOVER_CONTENT: POPOVER_CONTENT_DISPLAY.INFO_PANEL,
  },
  {
    LABEL: DISPLAY_INFO.INFO_BTN,
    NAME: 'isInfoBtn',
    IS_RELOAD_REQUIRED: false,
    POPOVER_CONTENT: POPOVER_CONTENT_DISPLAY.INFO_BTN,
  },
  {
    LABEL: DISPLAY_INFO.ALARM_ALERT,
    NAME: 'isAlarmAlert',
    IS_RELOAD_REQUIRED: false,
    POPOVER_CONTENT: POPOVER_CONTENT_DISPLAY.ALARM_ALERT,
  },
  {
    LABEL: DISPLAY_INFO.MINIFIC_MAP,
    NAME: 'isMinificMap',
    IS_RELOAD_REQUIRED: false,
    POPOVER_CONTENT: POPOVER_CONTENT_DISPLAY.MINIFIC_MAP,
  },
  {
    LABEL: DISPLAY_INFO.SCALE_LABEL,
    NAME: 'isScaleLine',
    IS_RELOAD_REQUIRED: false,
    POPOVER_CONTENT: POPOVER_CONTENT_DISPLAY.SCALE_LABEL,
  },
  {
    LABEL: DISPLAY_INFO.SLIDER,
    NAME: 'mapIconsSize',
    IS_RELOAD_REQUIRED: false,
    POPOVER_CONTENT: POPOVER_CONTENT_DISPLAY.SLIDER,
  },
];

const DEV_TOOLS_DATA: SettingsData = [
  {
    LABEL: DEV_TOOLS.MAP_INFO_BTN,
    NAME: 'isDevMapInfo',
    IS_RELOAD_REQUIRED: false,
    POPOVER_CONTENT: POPOVER_CONTENT_DEV_TOOLS.MAP_INFO_BTN,
  },
  {
    LABEL: DEV_TOOLS.STATIC_DATA_LOG,
    NAME: 'isStaticDataLog',
    IS_RELOAD_REQUIRED: false,
    POPOVER_CONTENT: POPOVER_CONTENT_DEV_TOOLS.STATIC_DATA_LOG,
  },
  {
    LABEL: DEV_TOOLS.ZOD_LOG,
    NAME: 'isZodLog',
    IS_RELOAD_REQUIRED: false,
    POPOVER_CONTENT: POPOVER_CONTENT_DEV_TOOLS.ZOD_LOG,
  },
  {
    LABEL: DEV_TOOLS.VIDEO_DT_LINKS_LOG,
    NAME: 'isVideoDtLinksLog',
    IS_RELOAD_REQUIRED: false,
    POPOVER_CONTENT: POPOVER_CONTENT_DEV_TOOLS.VIDEO_DT_LINKS_LOG,
  },
  {
    LABEL: DEV_TOOLS.INCORRECT_COORDINATES,
    NAME: 'isIncorrectCoordinatesLog',
    IS_RELOAD_REQUIRED: false,
    POPOVER_CONTENT: POPOVER_CONTENT_DEV_TOOLS.INCORRECT_COORDINATES,
  },
  {
    LABEL: DEV_TOOLS.ACTIVE_DEVICE,
    NAME: 'isActiveDeviceLog',
    IS_RELOAD_REQUIRED: false,
    POPOVER_CONTENT: POPOVER_CONTENT_DEV_TOOLS.ACTIVE_DEVICE,
  },
  {
    LABEL: DEV_TOOLS.QUERY_TOOLS,
    NAME: 'isQueryDevTools',
    IS_RELOAD_REQUIRED: false,
    POPOVER_CONTENT: POPOVER_CONTENT_DEV_TOOLS.QUERY_TOOLS,
  },
  {
    LABEL: DEV_TOOLS.QUERY_TOOLS_BTN_POSITION,
    NAME: 'queryToolsBtnPosition',
    IS_RELOAD_REQUIRED: false,
    POPOVER_CONTENT: POPOVER_CONTENT_DEV_TOOLS.QUERY_TOOLS_BTN_POSITION,
  },
];

const GIS_BUTTON: SettingsData = [
  {
    LABEL: GIS_BUTTON_INFO.SEARCH,
    NAME: 'isSearchBtnGis',
    IS_RELOAD_REQUIRED: false,
    POPOVER_CONTENT: POPOVER_CONTENT_GIS_BUTTON.SEARCH,
  },
  {
    LABEL: GIS_BUTTON_INFO.ROUTE_MEASUREMENT,
    NAME: 'isRouteMeasurementBtnGis',
    IS_RELOAD_REQUIRED: false,
    POPOVER_CONTENT: POPOVER_CONTENT_GIS_BUTTON.ROUTE_MEASUREMENT,
  },
  {
    LABEL: GIS_BUTTON_INFO.LAYERS,
    NAME: 'isLayersBtnGis',
    IS_RELOAD_REQUIRED: false,
    POPOVER_CONTENT: POPOVER_CONTENT_GIS_BUTTON.LAYERS,
  },
  {
    LABEL: GIS_BUTTON_INFO.MEASUREMENT,
    NAME: 'isMeasurementBtnGis',
    IS_RELOAD_REQUIRED: false,
    POPOVER_CONTENT: POPOVER_CONTENT_GIS_BUTTON.MEASUREMENT,
  },
  {
    LABEL: GIS_BUTTON_INFO.BASEMAP,
    NAME: 'isBasemapBtnGis',
    IS_RELOAD_REQUIRED: false,
    POPOVER_CONTENT: POPOVER_CONTENT_GIS_BUTTON.BASEMAP,
  },
  {
    LABEL: GIS_BUTTON_INFO.METEODATA,
    NAME: 'isMeteoDataBtnGis',
    IS_RELOAD_REQUIRED: false,
    POPOVER_CONTENT: POPOVER_CONTENT_GIS_BUTTON.METEODATA,
  },
  {
    LABEL: GIS_BUTTON_INFO.TRAFFIC,
    NAME: 'isTrafficBtnGis',
    IS_RELOAD_REQUIRED: false,
    POPOVER_CONTENT: POPOVER_CONTENT_GIS_BUTTON.TRAFFIC,
  },
  {
    LABEL: GIS_BUTTON_INFO.TRAFFIC_GRAPH,
    NAME: 'isTrafficGraphBtnGis',
    IS_RELOAD_REQUIRED: false,
    POPOVER_CONTENT: POPOVER_CONTENT_GIS_BUTTON.TRAFFIC_GRAPH,
  },
];

export const DEV_TOOLS_SETTINGS: ProfileSettingsMain = {
  TITLE: 'инструменты разработки',
  DATA: DEV_TOOLS_DATA,
};

export const PROFILE_SETTINGS_MAIN: ProfileSettingsMain[] = [
  {
    TITLE: CartographyTabName.Subsystems,
    DATA: SUBSYSTEMS_DATA,
  },
  { TITLE: 'параметры', DATA: PARAMS_DATA },
  { TITLE: 'события', DATA: EVENTS_DATA },
  {
    TITLE: 'фазовый круг светофора',
    DATA: TRAFFIC_LIGHTS_PHASES_DATA,
  },
  {
    TITLE: 'кластеризация',
    DATA: CLUSTERS_DATA,
  },
  { TITLE: 'отображение', DATA: DISPLAY_DATA },
  { TITLE: 'ГИС', DATA: GIS_BUTTON },
];

export const SETTINGS_DISABLED =
  'Для активации настройки необходимо, чтобы параметры';
