import { FC } from 'react';

import JsonView from '../../../../../../ui-kit/JsonView/JsonView';

import styles from './JsonAdditional.module.scss';

interface JsonAdditionalProps {
  object: object;
}

const JsonAdditional: FC<JsonAdditionalProps> = ({ object }) => {
  return (
    <div className={styles.json}>
      <JsonView object={object} />
    </div>
  );
};

export default JsonAdditional;
