import { useQueryClient } from '@tanstack/react-query';
import { FC } from 'react';

import UpdateBtn from '../../../../ui-kit/StandardHeader/UpdateBtn/UpdateBtn';

import InfoBtn from './InfoBtn/InfoBtn';

import styles from './MainButtons.module.scss';

interface MainButtonsProps {
  isShow: boolean;
  updateKeys: unknown[];
}

const MainButtons: FC<MainButtonsProps> = ({ isShow, updateKeys }) => {
  const queryClient = useQueryClient();

  const onUpdateBtn = () => {
    queryClient.refetchQueries({
      queryKey: updateKeys,
      exact: true,
    });
  };

  if (!isShow) return null;

  return (
    <div className={styles.container}>
      <UpdateBtn onUpdateBtn={onUpdateBtn} />
      <InfoBtn updateKeys={updateKeys} />
    </div>
  );
};

export default MainButtons;
