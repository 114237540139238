import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import { TMap } from '../../../../../stores/mapStore/mapStore.model';

import CrossroadSchemaUds from './CrossroadSchemaUds/CrossroadSchemaUds';
import useCrossroadOverlay from './hooks/useCrossroadOverlay';

import styles from './CrossroadSchemaWrapper.module.scss';

interface CrossroadSchemaWrapperProps {
  map: TMap;
  dtId: number;
}

const CrossroadSchemaWrapper: FC<CrossroadSchemaWrapperProps> = ({
  map,
  dtId,
}) => {
  const { mainProps, crossroadDetailedRef } = useCrossroadOverlay({
    map,
  });

  return (
    <div className={styles.container} ref={crossroadDetailedRef}>
      {<CrossroadSchemaUds {...mainProps} dtId={dtId} />}
    </div>
  );
};

export default observer(CrossroadSchemaWrapper);
