import { EditOutlined } from '@ant-design/icons';
import { FC } from 'react';

import ModalBody from '../../../PhaseHold/ModalBody/ModalBody';
import ModalConfirm, {
  ModalConfirmProps,
} from '../../../PhaseHold/ModalConfirm/ModalConfirm';
import styles from '../../../styles/basicStyles.module.scss';
import { UseEditModalProps } from '../../model/currentPlan.model';

import useEditModal from './hooks/useEditModal';

interface EditModalProps {
  editProps: Omit<ModalConfirmProps, 'icon'>;
  editTableProps: UseEditModalProps;
}

const EditModal: FC<EditModalProps> = ({ editProps, editTableProps }) => {
  const { editBodyProps, isErrorSgnProgram } = useEditModal(editTableProps);

  const { handleCancel, okButtonProps } = editProps;

  const disabledReset = !isErrorSgnProgram && okButtonProps?.disabled;

  return (
    <ModalConfirm
      {...editProps}
      closableModal={false}
      confirmClose={!disabledReset}
      handleReset={handleCancel}
      disabledReset={disabledReset}
      icon={<EditOutlined className={styles.editIcon} />}
    >
      <ModalBody {...editBodyProps} />
    </ModalConfirm>
  );
};

export default EditModal;
