import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import rootStore from '../../../../../../../stores/rootStore/rootStore';
import Popover, {
  PopoverProps,
} from '../../../../../../ui-kit/Popover/Popover';

import styles from './SystemItem.module.scss';

interface SystemItemProps {
  id: number;
  name: string;
  description: N<string>;
}

const SystemItem: FC<SystemItemProps> = ({ id, description, name }) => {
  const { interfaceTipsDelay } = rootStore.uiStore;

  const popoverProps: PopoverProps = {
    content: description,
    tipsDelay: description ? interfaceTipsDelay : null,
    placement: 'left',
  };

  return (
    <Popover {...popoverProps}>
      <div className={styles.container}>
        <span className={styles.id}>{id}</span>
        <p>{name}</p>
      </div>
    </Popover>
  );
};

export default observer(SystemItem);
