import { TableData } from '../../../ts/models/table.model';
import { getAppPathHeaders } from '../../helpers/getAppPathHeaders';
import http from '../../http';
import tryCatchWrapper from '../../tryCatchWrapper';
import { ADMIN } from '../constants/constants';

interface ListItem {
  id: number;
  name: string;
}

export interface SystemAboutItem {
  label: string;
  value: string;
}

const ABOUT = ADMIN + '/about/';

export const about = {
  getAboutSystem: (appPath: string) =>
    tryCatchWrapper(async () => {
      const res = await http.get<SystemAboutItem[]>(ABOUT + 'system', {
        headers: getAppPathHeaders(appPath),
      });

      return res.data;
    }),
  getAboutVersions: (appPath: string) =>
    tryCatchWrapper(async () => {
      const res = await http.get<TableData>(ABOUT + 'versions', {
        headers: getAppPathHeaders(appPath),
      });

      return res.data;
    }),
  getAboutRegions: (appPath: string) =>
    tryCatchWrapper(async () => {
      const res = await http.get(ABOUT + 'regions', {
        headers: getAppPathHeaders(appPath),
      });

      const data: ListItem[] = res.data;

      return data;
    }),
  getAboutSubSystems: (regionId: number, appPath: string) =>
    tryCatchWrapper(async () => {
      const res = await http.get(ABOUT + 'subsystems', {
        params: {
          regionId,
        },
        headers: getAppPathHeaders(appPath),
      });

      const data: ListItem[] = res.data;

      return data;
    }),
  getAboutSystemType: (subsystemId: number, appPath: string) =>
    tryCatchWrapper(async () => {
      const res = await http.get(ABOUT + 'systemtypes', {
        params: {
          subsystemId,
        },
        headers: getAppPathHeaders(appPath),
      });

      const data: ListItem[] = res.data;

      return data;
    }),
};
