import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import { findById } from '../../../../../../../../helpers/findBy';
import { TL } from '../../../../../../../../ts/models/tl.model';
import { getLinkedLanes } from '../../../../../../../Overlays/PointUdsOverlay/helpers/getLinkedLanes';
import LinkedDevices from '../../LinkedDevices/LinkedDevices';

interface LinkedDevicesTLProps {
  selectedTL: TL;
  isShowLanes: boolean;
  rate: number;
  id: N<number>;
}

const LinkedDevicesTL: FC<LinkedDevicesTLProps> = ({
  isShowLanes,
  rate,
  selectedTL,
  id,
}) => {
  const { linkedDeviceIds } = selectedTL;
  const linkedDts = linkedDeviceIds?.detectors ?? [];
  const linkedTLId = linkedDeviceIds?.trafficLights ?? null;
  const lanes = getLinkedLanes(linkedDts) ?? [];
  const selectedLanes = findById(lanes, id);

  if (!isShowLanes || !selectedLanes || !id) return null;

  return (
    <LinkedDevices
      rate={rate}
      linkedDevices={{ detectors: linkedDts, trafficLights: linkedTLId }}
      id={id}
      isSchemaODD
    />
  );
};

export default observer(LinkedDevicesTL);
