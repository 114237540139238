import { observer } from 'mobx-react-lite';
import { FC, useMemo } from 'react';

import { findById } from '../../../../../helpers/findBy';
import rootStore from '../../../../../stores/rootStore/rootStore';
import { TrafficLane } from '../../../../../ts/models/constructor.model';
import useScene from '../../../../DetectorGrid/hooks/useScene';
import { getCameraInfo } from '../../../../VideoCamera/helpers/getCameraInfo/getCameraInfo';
import { StreamType } from '../../../../VideoCamera/models/VideoCamera.model';
import VideoCameraWithPreloader from '../../../../VideoCamera/VideoCameraWithPreloader/VideoCameraWithPreloader';
import {
  DETECTOR_IMAGE_ERROR,
  DETECTOR_TITLE_STYLE,
} from '../../constants/constants';

import getStatusColor from './helpers/getStatusColor';

import styles from './DetectorImg.module.scss';

const { Jpeg } = StreamType;

const { NO_DATA, NOT_LINKED } = DETECTOR_IMAGE_ERROR;

interface DetectorImgProps {
  lane: TrafficLane;
}

const DetectorImg: FC<DetectorImgProps> = ({ lane }) => {
  const { viewStatus } = rootStore.constructorStore;
  const { cameras } = rootStore.mapDataStore;

  const { linkColor, isTransferred, id, linkedCameraId } = lane;

  const linkedCamera = useMemo(
    () => findById(cameras, linkedCameraId),
    [cameras, linkedCameraId]
  );

  const cameraData = getCameraInfo(linkedCamera, false);

  const scene = useScene(id);

  const statusColor = getStatusColor(viewStatus, linkColor, isTransferred);

  const titleStyle = {
    ...DETECTOR_TITLE_STYLE,
    borderLeft: `5px solid ${statusColor}`,
  };

  const noDataText = linkedCameraId ? NO_DATA : NOT_LINKED;

  return (
    <div>
      <h2 className={styles.title} style={titleStyle}>
        {`Изображение с детектора № ${id}:`}
      </h2>
      {cameraData ? (
        <VideoCameraWithPreloader
          cameraData={cameraData}
          scene={scene}
          streamType={Jpeg}
          isSceneDefault
        />
      ) : (
        <p>{noDataText}</p>
      )}
    </div>
  );
};

export default observer(DetectorImg);
