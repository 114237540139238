import { type QueryClient } from '@tanstack/react-query';

import { Device } from '../../../../../../../api/devices/model/devices.model';
import { QUERY_KEY } from '../../../../../../../constants/queryConstants';
import { findById } from '../../../../../../../helpers/findBy';
import rootStore from '../../../../../../../stores/rootStore/rootStore';
import { SelectedDeviceType } from '../../../../../models/adminTypes.model';

const { DEVICES_INFO } = QUERY_KEY;

const getDeviceInfo = (
  queryClient: QueryClient,
  id: number,
  selectedDevice: SelectedDeviceType
) => {
  const { regionData } = rootStore.uiStore;

  const queryData = queryClient.getQueryData<{ data: Nullish<Device[]> }>([
    DEVICES_INFO,
    regionData?.id,
    selectedDevice,
  ]);

  return findById(queryData?.data ?? [], id);
};

export default getDeviceInfo;
