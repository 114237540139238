/* eslint-disable jsx-a11y/no-autofocus */
import { Form } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import classNames from 'classnames';
import { FC } from 'react';

import { DSC_INPUT_PROPS } from '../../../../../constants/formsConstants/formsRules';
import { MAX_DSC_LENGTH } from '../../DeviceAdminCard/DeviceFormContent/constants/constants';
import { SourceFormFields } from '../../models/DeviceAdmin.model';

import ConfirmButtons from './ConfirmButtons/ConfirmButtons';
import { FormSourceKeys } from './constants/constants';
import ExternalDevicesSelect from './ExternalDevicesSelect/ExternalDevicesSelect';
import useSourceForm from './hooks/useSourceForm';
import LifeTimeFromPicker from './LifeTimeFromPicker/LifeTimeFromPicker';
import LifeTimeToPicker from './LifeTimeToPicker/LifeTimeToPicker';
import StatusSelect from './StatusSelect/StatusSelect';
import SystemsSelect from './SystemsSelect/SystemsSelect';
import SystemTypesSelect from './SystemTypesSelect/SystemTypesSelect';

import styles from './SourceForm.module.scss';

const { Dsc } = FormSourceKeys;

interface SourceFormProps {
  initialProps?: SourceFormFields;
  isFormFull?: boolean;
}

const SourceForm: FC<SourceFormProps> = ({
  initialProps,
  isFormFull = true,
}) => {
  const {
    form,
    isEditForm,
    getSourceRules,
    handleChangeDate,
    handleChangeSelect,
  } = useSourceForm(initialProps);

  const selectProps = {
    form,
    isShow: !isEditForm,
    getSourceRules,
    handleChangeSelect,
  };

  return (
    <div
      className={classNames(styles.wrapper, {
        [styles.full]: isFormFull,
      })}
    >
      <Form form={form} layout="vertical" initialValues={initialProps}>
        <SystemTypesSelect {...selectProps} />
        <SystemsSelect {...selectProps} />
        <ExternalDevicesSelect {...selectProps} />
        <Form.Item label="Описание" name={Dsc} rules={getSourceRules(Dsc)}>
          <TextArea
            placeholder="Введите описание источника"
            {...DSC_INPUT_PROPS}
            rows={4}
            maxLength={MAX_DSC_LENGTH}
          />
        </Form.Item>
        <StatusSelect getSourceRules={getSourceRules} />
        <LifeTimeFromPicker
          getSourceRules={getSourceRules}
          handleChangeDate={handleChangeDate}
        />
        <LifeTimeToPicker
          form={form}
          getSourceRules={getSourceRules}
          handleChangeDate={handleChangeDate}
        />
        <ConfirmButtons form={form} initialProps={initialProps} />
      </Form>
    </div>
  );
};

export default SourceForm;
