import classNames from 'classnames';
import { FC } from 'react';

import BackArrow from '../../../ui-kit/BackArrow/BackArrow';

import MainButtons from './MainButtons/MainButtons';

import styles from './Header.module.scss';

interface HeaderProps {
  title: N<string>;
  isShowArrow?: boolean;
  isSmall?: boolean;
  isShowBtns?: boolean;
  updateKeys?: unknown[];
  handleClick: () => void;
}

const Header: FC<HeaderProps> = ({
  title,
  isShowArrow = true,
  isSmall = false,
  isShowBtns = false,
  updateKeys = [],
  handleClick,
}) => {
  return (
    <div className={styles.title}>
      {title ? (
        <h3 className={classNames({ [styles.head]: isSmall })}>{title}:</h3>
      ) : null}
      <BackArrow isShow={isShowArrow} handleClick={handleClick} />
      <MainButtons isShow={isShowBtns} updateKeys={updateKeys} />
    </div>
  );
};

export default Header;
