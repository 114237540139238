import { useEffect, useState } from 'react';

import {
  getLayerFeaturesCount,
  getWordDegree,
} from '../../../../apiGIS/layers/helpers';
import { ILayer } from '../../../../stores/gisDataStore/gisDataStore.model';
import {
  LAYER_COUNT_TOOLTIP_NO_DATA,
  LAYER_COUNT_TOOLTIP_PREFIX,
  LAYER_COUNT_TOOLTIP_SUFFIXES,
} from '../constants';
import { getFeaturesCountView } from '../helpers';

import styles from '../LayersButton.module.scss';

export const LayerFeaturesCountInfo = (props: {
  definition: ILayer | undefined;
}) => {
  const [count, setCount] = useState<number | null>(null);

  const { definition } = props;

  useEffect(() => {
    if (!definition) {
      return;
    }

    getLayerFeaturesCount(definition).then((count: number | null) => {
      setCount(count);
    });
  }, [definition]);

  const getTitle = () => {
    if (!count) {
      return LAYER_COUNT_TOOLTIP_NO_DATA;
    }

    return `${LAYER_COUNT_TOOLTIP_PREFIX} ${count} ${getWordDegree(
      count,
      LAYER_COUNT_TOOLTIP_SUFFIXES
    )}`;
  };

  return (
    <div
      className={
        !count ? styles.countViewNoDataContainer : styles.countViewContainer
      }
      title={getTitle()}
    >
      {getFeaturesCountView(count)}
    </div>
  );
};
