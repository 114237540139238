import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import rootStore from '../../../../../../../stores/rootStore/rootStore';
import DefaultChecked from '../../../../../../ui-kit/DefaultChecked/DefaultChecked';
import VideoCameraWithPreloader from '../../../../../../VideoCamera/VideoCameraWithPreloader/VideoCameraWithPreloader';

import getCameraDataInfo from './helpers/getCameraDataInfo';

import styles from './VideoContainer.module.scss';

interface VideoContainerProps {
  systemId: U<number>;
  cameraExtId: Nullish<string>;
  name: U<string>;
  dsc?: Nullish<string>;
}

const VideoContainer: FC<VideoContainerProps> = ({
  cameraExtId,
  systemId,
  name,
  dsc,
}) => {
  const { isCameraAdminDevice } = rootStore.adminPanelStore;

  const cameraData = getCameraDataInfo({ cameraExtId, dsc, name, systemId });

  const error = !systemId ? 'Система не найдена' : null;

  if (!isCameraAdminDevice) return null;

  return (
    <div className={styles.container}>
      <DefaultChecked
        isEmptyData={!cameraData}
        noDataProps={{ textError: error ?? 'Камера не найдена' }}
      >
        {cameraData ? (
          <VideoCameraWithPreloader cameraData={cameraData} />
        ) : null}
      </DefaultChecked>
    </div>
  );
};

export default observer(VideoContainer);
