export const LOCAL_STYLES = {
  panelBodyStyle: {
    margin: 0,
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 5,
    paddingBottom: 5,
  },
  outerContainer: {
    minWidth: 420,
    maxWidth: 440,
    maxHeight: 800,
    overflowX: 'hidden',
  },
  innerContainer: {
    minWidth: 420,
    maxWidth: 420,
    maxHeight: 750,
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  GROUP_FOLDER_ICON: {
    size: 20,
    marginTop: 4,
  },
} as const;

export const LAYERS_CONTENT = {
  content: 'Слои',
  title: 'Слои',
};

export const LAYER_SCALE_CONTENT = {
  LAYER_IN_SCALE_RANGE:
    'Объекты слоя видны на текущем масштабе, если слой включён',
  LAYER_NOT_IN_SCALE_RANGE:
    'Объекты слоя не видны на текущем масштабе, даже если слой включён',
  ACTION_TIP: `Объекты сейчас не видны - приблизьтесь к карте`,
};

export const DEFAULT_LAYERS_GROUP_TITLE = 'Слои';

export const SETTINGS_OPACITY_HINT =
  'прозрачность применяется ко всем объектам слоя и действует в том случае, когда слой является видимым';
export const SETTINGS_VISIBILITY =
  'изменение видимости приводит к глобальному изменению видимости объектов слоя с учётом диапазона видимости слоя';
export const SETTINGS_MAX_SCALE_HINT =
  'Значение масштаба при приближении к карте (увеличение размеров объектов)';
export const SETTINGS_MIN_SCALE_HINT =
  'Значение масштаба при удалении от карты (уменьшение размеров объектов)';
export const SETTINGS_LABELS_HINT =
  'подписи будут показываться при включённой опции в том случае, если они настроены в консоли администратора для данного слоя';
export const SETTINGS_USE_STYLE =
  'При выборе данного значения можно будет изменить базовый стиль объектов слоя';

export const ERROR_MAP_IS_NOT_EXIST =
  'При инициализации компонента произошла ошибка. Пожалуйста, вернитесь назад.';
export const ERROR_LAYER_IS_NOT_EXIST =
  'Слой не был найден в составе карты. Пожалуйста, вернитесь назад.';
export const ERROR_LAYER_IS_NOT_SUPPORTED =
  'Для данного слоя операция не поддерживается. Пожалуйста, вернитесь назад.';

export const MAX_OPACITY = 1;
export const MIN_OPACITY = 0.1;
export const STEP_OPACITY = 0.05;

export const LABELS_SETTINGS_DEFAULT_FONT_SIZE = 10;
export const LABELS_SETTINGS_FONT_SIZES = [
  { label: '8px', value: '8px' },
  { label: '9px', value: '9px' },
  { label: '10px', value: '10px' },
  { label: '11px', value: '11px' },
  { label: '12px', value: '12px' },
  { label: '14px', value: '14px' },
  { label: '16px', value: '16px' },
  { label: '20px', value: '20px' },
  { label: '24px', value: '24px' },
  { label: '32px', value: '32px' },
];

export const LABELS_SETTINGS_DEFAULT_FONT_FAMILY = 'Arial';
export const LABELS_SETTINGS_FONT_FAMILIES = [
  { label: 'Arial', value: 'Arial' },
  { label: 'Calibri', value: 'Calibri' },
  { label: 'Cambria', value: 'Cambria' },
  { label: 'Consolas', value: 'Consolas' },
  { label: 'Noto Sans', value: 'NotoSans' },
  { label: 'Tahoma', value: 'Tahoma' },
  { label: 'Times New Roman', value: 'Times New Roman' },
  { label: 'Verdana', value: 'Verdana' },
];

export const MODES = {
  TREE: 'mode.tree',
  SETTINGS: 'mode.settings',
  TABLE: 'mode.table',
};

export const MAX_FEATURES_PER_REQUEST = 1000000;
export const EXTENT_DR = 100000000;

export const TABLE_SETTINGS = {
  PAGINATION: {
    pageSize: 1000,
    pageSizeOptions: ['1000'],
  },
  SCROLL: { y: 240 },
};

export const DEFAULT_WHERE = '1=1';
export const DEFAULT_OUT_FIELDS = ['*'];

export enum GROUP_VISIBILITY_STATES {
  GROUP_LAYERS_VISIBLE = 1,
  GROUP_LAYERS_NOT_VISIBLE = 0,
  GROUP_LAYER_PARTIALLY_VISIBLE = -1,
}

const {
  GROUP_LAYERS_VISIBLE,
  GROUP_LAYERS_NOT_VISIBLE,
  GROUP_LAYER_PARTIALLY_VISIBLE,
} = GROUP_VISIBILITY_STATES;

export const VISIBLE_TOOLTIPS: Record<GROUP_VISIBILITY_STATES, string> = {
  [GROUP_LAYERS_VISIBLE]: 'Все слои группы видны',
  [GROUP_LAYERS_NOT_VISIBLE]: 'Все слои группы не видны',
  [GROUP_LAYER_PARTIALLY_VISIBLE]: 'Часть слоёв не видна',
};

export const LAYER_COUNT_TOOLTIP_NO_DATA =
  'Не удалось получить информацию о количестве объектов слоя. Проверьте настройки в консоли администратора ГИС';
export const LAYER_COUNT_TOOLTIP_PREFIX = 'В слое содержится';
export const LAYER_COUNT_TOOLTIP_SUFFIXES = ['объект', 'объекта', 'объектов'];
