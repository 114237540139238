import { isBoolean, isNumber } from 'lodash';

import {
  ColumnTypes,
  ITableColumn,
  SorterFunc,
} from '../../../../../../ts/models/table.model';

type SorterProps = Omit<ITableColumn, 'multiple'> & {
  multiple: number;
};

type Compare = SorterFunc['compare'];

const compareString = (a: string, b: string) => a.localeCompare(b);

export const getSortedFunc = (item: SorterProps): U<SorterFunc> => {
  const basicSorter: Pick<SorterFunc, 'multiple'> = {
    multiple: item.multiple,
  };

  if (item.type.includes('jArray')) {
    const compare: Compare = (a, b) => {
      type ExitedArr = U<N<(string | number)[]>>;

      const aValue = a[item.referens] as ExitedArr;
      const bValue = b[item.referens] as ExitedArr;

      if (!aValue) return 1;
      if (!bValue) return -1;

      const parsedA = aValue
        .sort()
        .reduce((acc: string, el) => acc + el.toString(), '');
      const parsedB = bValue
        .sort()
        .reduce((acc: string, el) => acc + el.toString(), '');

      return compareString(parsedA, parsedB);
    };

    return { ...basicSorter, compare };
  }

  if (item.type === ColumnTypes.date) {
    const compare: Compare = (a, b) => {
      const aValue = a[item.referens];
      const bValue = b[item.referens];

      if (!aValue) return 1;
      if (!bValue) return -1;

      const isNum = isNumber(aValue) && isNumber(bValue);

      if (isNum) return aValue - bValue;

      if (isNumber(aValue)) return 1;

      if (isNumber(bValue)) return -1;

      return compareString(aValue as string, bValue as string);
    };

    return { ...basicSorter, compare };
  }

  return {
    ...basicSorter,
    compare: (a, b) => {
      const sort1 = a[item.referens];
      const sort2 = b[item.referens];

      const isNumberSort = isNumber(sort1) && isNumber(sort2);

      if (isNumberSort) return sort1 - sort2;

      const isBooleanSort = isBoolean(sort1) && isBoolean(sort2);

      if (isBooleanSort) {
        return Number(sort1) - Number(sort2);
      }

      if (!sort1) return 1;
      if (!sort2) return -1;

      return compareString(sort1 as string, sort2 as string);
    },
  };
};
