import { observer } from 'mobx-react-lite';

import { Z_INDEX_LAYER } from '../../../../../../apiGIS/constants/map';
import { CONFIG } from '../../../../../../constants/configConstants';
import rootStore from '../../../../../../stores/rootStore/rootStore';
import DefaultChecked from '../../../../../ui-kit/DefaultChecked/DefaultChecked';
import { TileLayer } from '../../../../../ui-kit/MapLayers';
import { osm } from '../../../../../ui-kit/MapSource';
import UiMap from '../../../../../ui-kit/UiMap/UiMap';

import useMapInfo from './hooks/useMapInfo';

import styles from './MapContent.module.scss';

const MapContent = () => {
  const { regionData } = rootStore.uiStore;

  const { map, isLoading, handleMapClick, setMap, handleLoadView } =
    useMapInfo();

  return (
    <div className={styles.map}>
      <DefaultChecked
        isLoading={isLoading}
        isEmptyData={!regionData}
        isBackdropFilter
        noDataProps={{
          textError: 'Нет данных о регионе',
          isNeedBackground: true,
        }}
      >
        <UiMap
          map={map}
          setMap={setMap}
          extent={regionData?.extent}
          centerCoord={regionData?.mapCenterCoords}
          zoomProps={{
            initZoom: regionData?.initialZoom,
            minZoom: regionData?.initialZoom,
          }}
          handleLoadView={() => handleLoadView(false)}
          handleMapClick={handleMapClick}
          handleStartLoadView={() => handleLoadView(true)}
          isRadius={false}
        >
          <TileLayer
            source={osm(CONFIG.OSM_SOURCE)}
            map={map}
            zIndex={Z_INDEX_LAYER}
          />
        </UiMap>
      </DefaultChecked>
    </div>
  );
};

export default observer(MapContent);
