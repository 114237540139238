import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';

import api from '../../../../../../api';
import {
  DEFAULT_UPDATE_DEVICE_ADMIN,
  QUERY_KEY,
} from '../../../../../../constants/queryConstants';
import rootStore from '../../../../../../stores/rootStore/rootStore';
import transformSourcesData from '../helpers/transformSourcesData';

const { EXTERNAL_LINKS_INFO } = QUERY_KEY;
const { fetchExternalLinksByDeviceId } =
  api.devices.externalLinks.externalLinksApi;

const APP_PATH = 'Admin.DeviceAdmin.SourceEdit.hooks.useSourcesEdit';

const useSourceInfo = () => {
  const { selectedDevice } = rootStore.adminPanelStore;

  const {
    data: sources,
    isFetching,
    isError,
    refetch,
  } = useQuery({
    queryKey: [EXTERNAL_LINKS_INFO, selectedDevice],
    queryFn: async () => {
      if (!selectedDevice) return null;

      const res = await fetchExternalLinksByDeviceId(
        selectedDevice.id,
        APP_PATH
      );

      return transformSourcesData(res);
    },
    refetchInterval: DEFAULT_UPDATE_DEVICE_ADMIN,
    enabled: !!selectedDevice,
  });

  useEffect(() => {
    refetch();
  }, [selectedDevice, refetch]);

  return { sources, isLoading: isFetching, isError };
};

export default useSourceInfo;
