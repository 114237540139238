import { PlusOutlined } from '@ant-design/icons';
import { Descriptions } from 'antd';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import { CardsContent, isEmptyCard } from '../../../models/DeviceAdmin.model';
import getSourceInfo from '../../SourceEditForm/SourceInfo/helpers/getSourceInfo';

import SourceButtons from './SourceButtons/SourceButtons';
import SourceInfo from './SourceInfo/SourceInfo';
import SourceStatus from './SourceStatus/SourceStatus';

import styles from './SourceContent.module.scss';

type SourceContentProps = CardsContent[number];

const SourceContent: FC<SourceContentProps> = (props) => {
  if (isEmptyCard(props)) return <PlusOutlined />;

  const info = getSourceInfo(props).sourceInfo;

  if (!info) return null;

  const {
    nameSource,
    systemName,
    dscSource,
    extDevice,
    lifetimeFrom,
    lifetimeTo,
  } = info;

  const items = [
    systemName,
    extDevice,
    { ...dscSource, children: dscSource.children ?? 'Отсутствует' },
  ];

  const { status_id, sname, id } = props;

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <h6>{nameSource.children}</h6>
      </div>
      <div className={styles.main}>
        <Descriptions
          layout="horizontal"
          items={items}
          column={1}
          className={styles.dsc}
        />
      </div>
      <SourceInfo lifetimeFrom={lifetimeFrom} lifetimeTo={lifetimeTo} />
      <SourceButtons extSourceId={id} name={sname} />
      <SourceStatus status_id={status_id} />
    </div>
  );
};

export default observer(SourceContent);
