import { Path } from '../../../../api.model';
import { getAppPathHeaders } from '../../../../helpers/getAppPathHeaders';
import http from '../../../../http';
import tryCatchWrapper from '../../../../tryCatchWrapper';
import { WaveType } from '../../tlCoordination.model';
import { GROUP } from '../coordGroupApi';

import { Profile } from './profilesApi.model';
import useProfilesListQuery from './query/useProfilesList';

const PROFILES = '/profiles';

class ProfilesApi {
  fetchProfilesList = (uuid: Nullish<string>, path: Path) =>
    tryCatchWrapper(async () => {
      if (!uuid) return;

      const res = await http.get<Profile[]>(`${GROUP}/${uuid + PROFILES}`, {
        headers: getAppPathHeaders(path),
      });

      return res.data;
    });

  useProfilesList = (uuid: Nullish<string>, path: Path) =>
    useProfilesListQuery(uuid, path, this.fetchProfilesList);

  createProfilesItem = (
    uuid: string,
    profile: Omit<Profile, 'id' | 'time'>,
    path: Path
  ) =>
    tryCatchWrapper(async () => {
      const res = await http.post<Profile>(
        `${GROUP}/${uuid + PROFILES}`,
        { id: null, time: null, ...profile },
        {
          headers: getAppPathHeaders(path),
        }
      );

      return res.data;
    });

  startCoordGroup = (
    uuid: string,
    profileId: number,
    wave: WaveType,
    path: Path
  ) =>
    tryCatchWrapper(async () => {
      const res = await http.patch(
        `${GROUP}/${uuid + PROFILES}/${profileId}/start`,
        {
          headers: getAppPathHeaders(path),
          params: {
            wave,
          },
        }
      );

      return res;
    });
}

const profilesApi = new ProfilesApi();

export default profilesApi;
