import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import NOTICE from '../../../../../constants/notificationConstants';
import DefaultChecked from '../../../../ui-kit/DefaultChecked/DefaultChecked';
import SourceForm from '../SourceForm/SourceForm';

import useSourceInfo from './hooks/useSourceInfo';
import SourceInfo from './SourceInfo/SourceInfo';

import styles from './SourceEditForm.module.scss';

const { EXTERNAL_LINK_ERROR } = NOTICE;

const SourceEditForm: FC = () => {
  const { isError, isLoading, source, fullSourceInfo } = useSourceInfo();

  return (
    <div className={styles.container}>
      <DefaultChecked
        isEmptyData={isError || !source}
        isLoading={isLoading}
        noDataProps={{ textError: isError ? EXTERNAL_LINK_ERROR : undefined }}
      >
        <div className={styles.block}>
          <SourceForm initialProps={source ?? undefined} />
          <SourceInfo fullSourceInfo={fullSourceInfo} isFullInfo={false} />
        </div>
      </DefaultChecked>
    </div>
  );
};

export default observer(SourceEditForm);
