import { observer } from 'mobx-react-lite';
import { FC, useMemo } from 'react';

import rootStore from '../../../../../../../stores/rootStore/rootStore';
import useScene from '../../../../../../DetectorGrid/hooks/useScene';
import { getCameraInfo } from '../../../../../../VideoCamera/helpers/getCameraInfo/getCameraInfo';
import VideoCameraWithPreloader from '../../../../../../VideoCamera/VideoCameraWithPreloader/VideoCameraWithPreloader';

import { getFilteredScene } from './helpers/helpers';

import styles from './VideoPreview.module.scss';

const ZONES_LABEL = 'Вкл/Выкл отображения зон';

interface VideoPreviewProps {
  id: N<number>;
  isNoImageDt?: boolean;
  isShowOnlyZones?: boolean;
}

const VideoPreview: FC<VideoPreviewProps> = ({
  id,
  isNoImageDt = false,
  isShowOnlyZones = false,
}) => {
  const { cameras, detectors } = rootStore.mapDataStore;

  const scene = useScene(id);

  const { scene: correctScene, sceneErrorType } = useMemo(
    () => getFilteredScene({ scene, detectors, id, isShowOnlyZones }),
    [detectors, id, scene, isShowOnlyZones]
  );

  const linkedCamera = useMemo(
    () => cameras.find(({ dai_jdt83 }) => dai_jdt83?.linkedDetectorId === id),
    [cameras, id]
  );

  const cameraData = useMemo(() => getCameraInfo(linkedCamera), [linkedCamera]);

  const isOnlyZones = correctScene && !correctScene.trafficLines.length;
  const lanesBtnTitle =
    isShowOnlyZones || isOnlyZones ? ZONES_LABEL : undefined;

  return (
    <div className={styles.container}>
      {cameraData ? (
        <VideoCameraWithPreloader
          cameraData={cameraData}
          scene={!isNoImageDt ? correctScene : undefined}
          sceneErrorType={!isNoImageDt ? sceneErrorType : null}
          lanesBtnTitle={lanesBtnTitle}
        />
      ) : (
        <p>Не удалось получить стрим связанной камеры</p>
      )}
    </div>
  );
};

export default observer(VideoPreview);
