import { Device } from '../../../../../../../api/devices/model/devices.model';
import { DeviceData } from '../../../../models/DeviceAdmin.model';

const transformToFormData = (data: U<Device>): N<DeviceData> => {
  if (!data) return null;

  const {
    device_type_id,
    dsc,
    latitude,
    longitude,
    sname,
    point_uds_profile_uid,
  } = data;

  return {
    device_type_id,
    dsc,
    latitude,
    longitude,
    sname,
    point_uds_profile_uid,
  };
};

export default transformToFormData;
