import { isNumber } from 'lodash';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import {
  isDeviceStatus,
  isSystem,
  isTL,
} from '../../ts/models/mapObject.model';
import getDeviceBySystem from '../DetailedStatistics/Monitoring/DeviceCard/Mapper/CrossroadSchema/helpers/getDeviceBySystem';
import { DevicesProps } from '../InfoPanel/SystemsInfo/LinkedsInfo/LinkedDeviceItem/LinkedDeviceItem';

import DevIco from './DevIco/DevIco';

const IcoDevice: FC<DevicesProps> = ({ system, bgColor, iconStyle, id }) => {
  const activeDevice =
    isSystem(system) && isNumber(id) ? getDeviceBySystem(id, system) : null;

  const mode = isTL(activeDevice) ? activeDevice.tlStatus?.mode : undefined;

  const statusDevice = isDeviceStatus(activeDevice?.deviceInfo.deviceStatusId)
    ? activeDevice?.deviceInfo.deviceStatusId
    : undefined;

  return (
    <DevIco
      system={system}
      bgColor={bgColor}
      iconStyle={iconStyle}
      mode={mode}
      statusDevice={statusDevice}
    />
  );
};

export default observer(IcoDevice);
