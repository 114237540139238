import { Button, Space } from 'antd';
import { FC, useEffect, useState } from 'react';

import { IFilteredInfo, TableData } from '../../../../ts/models/table.model';

import FilterList from './FilterList/FilterList';

import styles from './FilterInfo.module.scss';

interface IFilterInfo {
  filteredInfo: IFilteredInfo;
  setFilteredInfo: (param: IFilteredInfo) => void;
  tableData: TableData;
  setMaxPageSize: SetState<N<number>>;
}

const CLEAR_BTN_TEXT = 'Сбросить Фильтры';

const FilterInfo: FC<IFilterInfo> = ({
  filteredInfo,
  setFilteredInfo,
  tableData,
  setMaxPageSize,
}) => {
  const [filters, setFilters] = useState<string[]>([]);

  useEffect(() => {
    if (!filteredInfo) return;

    setFilters(Object.keys(filteredInfo));
  }, [filteredInfo]);

  if (!filteredInfo) return null;

  const handleClear = () => {
    setFilteredInfo(null);
    setMaxPageSize(null);
  };
  const handleClose = (filter: string) => {
    const values = filteredInfo ? Object.values(filteredInfo) : [];
    let count = 0;

    values.forEach((item) => {
      if (item) count++;
    });

    setMaxPageSize(null);

    if (count === 1) return setFilteredInfo(null);

    setFilteredInfo({
      ...filteredInfo,
      [filter]: null,
    });
  };

  return (
    <Space className={styles.btsWrapper}>
      <Button onClick={handleClear} type="primary">
        {CLEAR_BTN_TEXT}
      </Button>
      <FilterList
        filters={filters}
        filteredInfo={filteredInfo}
        handleClose={handleClose}
        tableData={tableData}
      />
    </Space>
  );
};

export default FilterInfo;
