import notification from '../../../../../components/ui-kit/Notification/Notification';
import rootStore from '../../../../../stores/rootStore/rootStore';
import { WEB_DEVELOPER_CODE } from '../../../../../ts/enums/userData';
import {
  AdapterTypes,
  GeneralAdapterStatistic,
  InvalidAdapter,
  isAdapterGo,
  MainAdapter,
  StatisticByType,
} from '../model/adapter.model';

const getInvalidStructure = (
  error: GeneralAdapterStatistic['error'],
  adapterType: AdapterTypes,
  isValidationSuccess: boolean
): InvalidAdapter => {
  const data = error?.data;
  const status = isAdapterGo(data, adapterType)
    ? data?.add_info?.adapter?.status
    : data?.status;

  const statistic = isAdapterGo(data, adapterType)
    ? data?.add_info?.adapter?.statistics
    : data?.statistic;

  return {
    status: status ?? null,
    errorInvalid: error
      ? {
          issues: error.issues,
          data: error.data,
        }
      : undefined,
    sc_system_id: data?.sc_system_id,
    sc_system_type_id: data?.sc_system_type_id,
    sc_system_regions_id: data?.sc_system_regions_id,
    sub_systems_id: data?.sub_systems_id,
    statistic,
    isValidationSuccess,
  };
};

const getGeneralStructure = (
  statisticItem: GeneralAdapterStatistic['data'],
  adapterType: AdapterTypes,
  error: GeneralAdapterStatistic['error'],
  isValidationSuccess: boolean
): MainAdapter => {
  if (!isValidationSuccess || !statisticItem)
    return getInvalidStructure(error, adapterType, isValidationSuccess);

  if (!isAdapterGo(statisticItem, adapterType))
    return { ...statisticItem, isValidationSuccess, adapter_monitoring: null };

  const {
    add_info: { adapter, terminations, adapter_monitoring },
  } = statisticItem;

  return {
    ...statisticItem,
    adapter_monitoring,
    terminations,
    uptime_sec: adapter?.uptime_sec ?? null,
    error: adapter?.error?.jerror ?? null,
    statistic: adapter,
    status: adapter?.status ?? null,
    isValidationSuccess,
  };
};

const transformedDataStatistic = (statistic: U<GeneralAdapterStatistic[]>) => {
  const { hasAccess } = rootStore.userDataStore;

  if (!statistic) return;

  const data = statistic.reduce(
    (acc: StatisticByType, { data, data_type, error, isValidationSuccess }) => {
      const formattedData = getGeneralStructure(
        data,
        data_type,
        error,
        isValidationSuccess
      );

      if (!acc[data_type]) {
        acc[data_type] = [formattedData];
      } else {
        acc[data_type]?.push(formattedData);
      }

      return acc;
    },
    {} as StatisticByType
  );

  const isNotify = Object.values(data)
    .flat()
    .some(({ isValidationSuccess }) => !isValidationSuccess);
  const isDeveloper = hasAccess(WEB_DEVELOPER_CODE);

  isNotify && isDeveloper && notification.error('VALIDATION_ERROR_ADAPTERS');

  return data;
};

export default transformedDataStatistic;
