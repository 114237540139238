import { Popover as AntdPopover, type PopoverProps as Props } from 'antd';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { FC, RefObject } from 'react';

import rootStore from '../../../stores/rootStore/rootStore';
import { TipType } from '../../../stores/uiStore/uiStore.model';

import formatContent from './helpers/formatContent';
import { getPopupContainer } from './helpers/getPopupContainer/getPopupContainer';

import styles from './Popover.module.scss';

export interface PopoverProps extends Props {
  tipsDelay?: TipType;
  isClearParent?: boolean;
  containerRef?: RefObject<HTMLElement>;
}

const Popover: FC<PopoverProps> = (props) => {
  const {
    children,
    content,
    tipsDelay,
    trigger = 'hover',
    overlayClassName = '',
    isClearParent = false,
    fresh = false,
    destroyTooltipOnHide = false,
    containerRef,
  } = props;

  const { interfaceTipsDelay } = rootStore.uiStore;

  if (tipsDelay === null) {
    return <>{children}</>;
  }

  const overlayClassNameStyles = classNames({
    [overlayClassName]: true,
    [styles.popoverClear]: Boolean(isClearParent),
  });

  const popupContainer = getPopupContainer(containerRef)?.func;
  const mouseEnterDelay = tipsDelay ?? interfaceTipsDelay ?? undefined;

  const popoverProps: PopoverProps = {
    ...props,
    trigger,
    fresh,
    destroyTooltipOnHide,
    mouseEnterDelay,
    content: formatContent(content),
    overlayClassName: overlayClassNameStyles,
    getPopupContainer: popupContainer,
  };

  return <AntdPopover {...popoverProps}>{children}</AntdPopover>;
};

export default observer(Popover);
