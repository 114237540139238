import { notification } from 'antd';
import axios, { AxiosRequestConfig, AxiosRequestHeaders } from 'axios';

import onRejectedErrorGIS from './helpers/onRejectedErrorGIS';

const HEADERS: AxiosRequestHeaders = {
  'Content-Type': 'application/json',
  'Access-Control-Allow-Origin': '*',
};

const requestSettings: AxiosRequestConfig = {
  headers: HEADERS,
};

export const gisHttp = axios.create(requestSettings);

gisHttp.interceptors.response.use((config) => {
  config.status !== 502 &&
    config.status !== 0 &&
    notification.destroy('error-notification');

  return config;
}, onRejectedErrorGIS);
