import { observer } from 'mobx-react-lite';
import { CSSProperties, FC, useEffect, useState } from 'react';

import { findById } from '../../../../helpers/findBy';
import { getSystemTitle } from '../../../../helpers/getSystemTitle';
import { TObjMap } from '../../../../stores/mapDataStore/mapDataStore.model';
import rootStore from '../../../../stores/rootStore/rootStore';
import { System } from '../../../../ts/enums/enums';
import { TMeteo } from '../../../../ts/models/MapObjects/meteo.model';

import MeteoInfo from './MeteoInfo/MeteoInfo';
import ModeInfo from './ModeInfo/ModeInfo';

import styles from './MapIconContent.module.scss';

interface IMapIconContentProps {
  id: number;
  system: System;
  style?: CSSProperties;
}

const isMeteo = (item: TObjMap, system: System): item is TMeteo =>
  system === System.Meteo;

const MapIconContent: FC<IMapIconContentProps> = ({ id, system, style }) => {
  const { systemsInfo } = rootStore.mapDataStore;

  const [activeItem, setActiveItem] = useState<TObjMap>();
  const [activeSystemArr, setActiveSystemArr] = useState<TObjMap[]>([]);

  useEffect(() => {
    setActiveSystemArr(systemsInfo[system]?.data ?? []);
  }, [systemsInfo, system]);

  useEffect(() => {
    const active = findById(activeSystemArr, id);

    if (!active) return;

    const isEmpty = !Object.keys(active).length;

    if (isEmpty) return;

    setActiveItem(active);
  }, [activeSystemArr, id]);

  if (activeItem?.id !== id) return null;

  if (!activeItem)
    return (
      <p className={styles.errorTitle}>Устройство с данным id не найдено</p>
    );

  const caption = activeItem.deviceInfo?.caption;

  return (
    <div className={styles.container} style={style}>
      <span className={styles.tooltipTitle}>
        {getSystemTitle(system, activeItem)}
      </span>
      {caption ? (
        <span className={styles.tooltipTitle}>{`(${caption})`}</span>
      ) : null}
      <ModeInfo id={id} system={system} />
      {isMeteo(activeItem, system) ? <MeteoInfo meteo={activeItem} /> : null}
    </div>
  );
};

export default observer(MapIconContent);
