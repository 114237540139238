import { Slider } from 'antd';
import type { CanvasFilterOptions } from 'ol-ext/filter/CanvasFilter';

import {
  LAYER_FILTER_COMPONENTS_ITEMS,
  LAYER_SETTINGS_PREDEFINED_ITEMS,
} from '../../../apiGIS/constants/filters';
import { getInitialLayerFilterSettings } from '../../../apiGIS/utils/filters';

import styles from './LayerFilter.module.scss';

const LayerFilter = (props: {
  onSettingsChange: (evt: any) => void;
  settings?: CanvasFilterOptions;
}) => {
  const { onSettingsChange } = props;
  const settings = props.settings || getInitialLayerFilterSettings();

  const updateSettings = (value: number, property: string) => {
    const data: CanvasFilterOptions = {
      ...settings,
      [property]: value,
    };

    onSettingsChange(data);
  };

  const getCurrentValue = (item: { property: string }): number => {
    const property = item.property || 'contrast';

    // @ts-ignore
    return Number(settings[property]) || 0;
  };

  return (
    <div className={styles.layerFilterSettingsContainer}>
      <div className={styles.layerFilterSettingsSubTitle}>Готовые фильтры</div>
      <div className={styles.layerFilterPredefinedContainer}>
        {LAYER_SETTINGS_PREDEFINED_ITEMS.map((item) => {
          const { value, text } = item;

          return (
            <div
              key={item.text}
              className={styles.layerFilterSettingsButton}
              role={'presentation'}
              onClick={() => {
                onSettingsChange(value);
              }}
            >
              {text}
            </div>
          );
        })}
      </div>

      <div className={styles.layerFilterSettingsSubTitle}>Подстройка</div>
      {LAYER_FILTER_COMPONENTS_ITEMS.map((item) => {
        const value = getCurrentValue(item);

        return (
          <div key={item.property}>
            <div className={styles.layerFilterSettingsTitle}>{item.text}</div>
            <Slider
              value={value}
              onChange={(value) => {
                updateSettings(value, item.property);
              }}
              min={item.min}
              max={item.max}
              step={item.step}
              className={styles.layerFilterSettingsSlider}
            />
          </div>
        );
      })}
    </div>
  );
};

export default LayerFilter;
