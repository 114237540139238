/* eslint-disable jsx-a11y/no-autofocus */
import { Form, Input } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import {
  DSC_INPUT_PROPS,
  INPUT_PROPS,
} from '../../../../../../constants/formsConstants/formsRules';
import WarningNotify from '../../../../../TrafficLightDetailed/TlManagement/StatusCard/WarningNotify/WarningNotify';
import { AddFormFields } from '../../../models/DeviceAdmin.model';
import {
  FORM_RULES,
  FormKeys,
  MAX_DSC_LENGTH,
  MAX_NAME_LENGTH,
} from '../constants/constants';
import { getCoordPlaceholder } from '../helpers/coordHelpers';
import {
  getCoordFormRules,
  getValidationRules,
} from '../helpers/validationHelpers';

import Buttons from './Buttons/Buttons';
import DeviceTypeSelect from './DeviceTypeSelect/DeviceTypeSelect';
import useDeviceAddCard from './hooks/useDeviceAddCard';

import styles from './DeviceAddCard.module.scss';

interface DeviceAddCardProps {
  initialProps?: AddFormFields;
  pointUdsUid?: N<string>;
}

const DeviceAddCard: FC<DeviceAddCardProps> = ({
  initialProps,
  pointUdsUid,
}) => {
  const { form, tooltipCoordLatitude, tooltipCoordLongitude, warningNotify } =
    useDeviceAddCard({ initialProps, pointUdsUid });

  const getDeviceRules = (value: FormKeys) =>
    getValidationRules(value, FORM_RULES);

  return (
    <div className={styles.wrapper}>
      <Form form={form} layout="vertical" initialValues={initialProps}>
        <Form.Item
          label="Имя"
          name={FormKeys.SName}
          rules={getDeviceRules(FormKeys.SName)}
        >
          <Input
            autoFocus
            placeholder="Введите имя устройства"
            {...INPUT_PROPS}
            maxLength={MAX_NAME_LENGTH}
          />
        </Form.Item>
        <Form.Item
          label="Описание"
          name={FormKeys.Dsc}
          rules={getDeviceRules(FormKeys.Dsc)}
        >
          <TextArea
            placeholder="Введите описание устройства"
            {...DSC_INPUT_PROPS}
            rows={4}
            maxLength={MAX_DSC_LENGTH}
          />
        </Form.Item>
        <DeviceTypeSelect getDeviceRules={getDeviceRules} />
        <Form.Item
          label="Широта"
          name={FormKeys.Latitude}
          rules={getCoordFormRules(FormKeys.Latitude)}
          tooltip={tooltipCoordLatitude}
        >
          <Input
            type="number"
            className={styles.coordInput}
            title={tooltipCoordLatitude}
            placeholder={getCoordPlaceholder(FormKeys.Latitude)}
            allowClear
          />
        </Form.Item>
        <Form.Item
          label="Долгота"
          name={FormKeys.Longitude}
          rules={getCoordFormRules(FormKeys.Longitude)}
          tooltip={tooltipCoordLongitude}
        >
          <Input
            type="number"
            className={styles.coordInput}
            title={tooltipCoordLongitude}
            placeholder={getCoordPlaceholder(FormKeys.Longitude)}
            allowClear
          />
        </Form.Item>
        <Buttons form={form} initialProps={initialProps} />
      </Form>
      <WarningNotify
        isActiveNotify={!!warningNotify}
        warningTitle={warningNotify ?? undefined}
      />
    </div>
  );
};

export default observer(DeviceAddCard);
