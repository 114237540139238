import { useQuery } from '@tanstack/react-query';

import {
  DEFAULT_STALE_TIME,
  QUERY_KEY,
} from '../../../../../../constants/queryConstants';
import { Path } from '../../../../../api.model';
import { FetchProfilesList } from '../profilesApi.model';

const useProfilesListQuery = (
  uuid: Nullish<string>,
  path: Path,
  fetchProfilesList: FetchProfilesList
) =>
  useQuery({
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: [QUERY_KEY.COORD_PROFILES_LIST, uuid],
    queryFn: async () => await fetchProfilesList(uuid, path),
    staleTime: DEFAULT_STALE_TIME,
  });

export default useProfilesListQuery;
