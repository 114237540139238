import { Spin } from 'antd';
import classNames from 'classnames';
import { FC, useMemo } from 'react';

import { StreamType } from '../models/VideoCamera.model';
import VideoCamera, { VideoCameraProps } from '../VideoCamera';

import useVideoSources from './hooks/useVideoSources';

import styles from './VideoCameraWithPreloader.module.scss';
import VideoCameraStyles from '../VideoCamera.module.scss';

type VideoCameraWithPreloaderProps = Omit<VideoCameraProps, 'videoSources'> & {
  streamType?: StreamType;
};

const VideoCameraWithPreloader: FC<VideoCameraWithPreloaderProps> = ({
  streamType,
  ...props
}) => {
  const { error, isLoading, videoSources } = useVideoSources(
    props.cameraData?.deviceId,
    props.cameraData?.systemId
  );

  const formattedVideoSources = useMemo(
    () =>
      streamType
        ? videoSources?.filter(
            ({ streamType: videoStreamType }) => videoStreamType === streamType
          ) ?? []
        : videoSources,
    [streamType, videoSources]
  );

  const wrapperClass = classNames([VideoCameraStyles.wrapper, styles.wrapper]);

  if (isLoading)
    return (
      <div className={wrapperClass} data-testid="spin-loader-wrapper">
        <Spin spinning={isLoading} size="large" />
      </div>
    );

  if (error || !videoSources)
    return (
      <div className={wrapperClass} data-testid="error-wrapper">
        <p className={styles.errorText}>{error}</p>
      </div>
    );

  const videoCameraProps = {
    ...props,
    videoSources: formattedVideoSources,
  };

  return <VideoCamera {...videoCameraProps} />;
};

export default VideoCameraWithPreloader;
