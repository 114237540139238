import { Button } from 'antd';
import { FormInstance } from 'antd/lib';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import { SourceDisplayType } from '../../../../../../stores/adminPanelStore/constants/constants';
import rootStore from '../../../../../../stores/rootStore/rootStore';
import { SourceFormFields } from '../../../models/DeviceAdmin.model';
import { BUTTON_TEXT } from '../constants/constants';

import useConfirm from './hooks/useConfirm';

import styles from './ConfirmButtons.module.scss';

const { SourceWatchForm } = SourceDisplayType;

interface ConfirmButtonsProps {
  form: FormInstance;
  initialProps: U<SourceFormFields>;
}

const ConfirmButtons: FC<ConfirmButtonsProps> = ({ form, initialProps }) => {
  const { sourceDisplayType } = rootStore.adminPanelStore;

  const { handleSubmit, isDisabledConfirm, isLoading, isChangeForm, onReset } =
    useConfirm(form, initialProps);

  if (!sourceDisplayType || sourceDisplayType === SourceWatchForm) return null;

  const { submit: submitText, reset: resetText } =
    BUTTON_TEXT[sourceDisplayType];

  return (
    <div className={styles.btns}>
      <Button onClick={onReset} disabled={!isChangeForm}>
        {resetText}
      </Button>
      <Button
        type="primary"
        onClick={handleSubmit}
        disabled={isDisabledConfirm}
        loading={isLoading}
      >
        {submitText}
      </Button>
    </div>
  );
};

export default observer(ConfirmButtons);
