import { ArrowLeftOutlined } from '@ant-design/icons';
import { List } from 'antd';
import React from 'react';
import { HiMagnifyingGlassPlus } from 'react-icons/hi2';
import { MdOutlineOpenInNew } from 'react-icons/md';

import { IFeatureProperty } from '../../InfoPanel/IdentificationInfo/utils/utils';
import { ActionItem } from '../GisFeatureActionItem/ActionItem';

import styles from './FeatureForm.module.scss';

const FeatureForm = (props: {
  onClick: () => void;
  dataSource: U<IFeatureProperty[]>;
  onZoom: () => Promise<void>;
  onGIS: () => Promise<void>;
}) => (
  <>
    <div className={styles.featureTitle}>
      <ArrowLeftOutlined size={24} onClick={props.onClick} />
      <div className={styles.featureTitleText}>Свойства объекта</div>
    </div>

    <List
      dataSource={props.dataSource}
      renderItem={(item, index) => (
        <div
          className={styles.featurePropertyContainer}
          key={'feature-property-' + index}
        >
          <div className={styles.featurePropertyName}>
            {item.alias || item.text}
          </div>
          <div className={styles.featurePropertyValue}>{item.value}</div>
        </div>
      )}
    />

    <div className={styles.actionsContainer}>
      <ActionItem
        icon={HiMagnifyingGlassPlus}
        text="приблизить"
        onClick={props.onZoom}
      />
      <ActionItem
        icon={MdOutlineOpenInNew}
        text="открыть в ГИС"
        onClick={props.onGIS}
      />
    </div>
  </>
);

export default FeatureForm;
