import { ZodError } from 'zod';

import notification from '../components/ui-kit/Notification/Notification';
import NOTICE, {
  NotifyTextKeys,
  isNoticeKey,
} from '../constants/notificationConstants';
import { sendError } from '../helpers/sendError';

const { SERVER_ERROR } = NOTICE;

export interface TCWrapperParams {
  ignoreCodes?: number[];
  errorMessage?: NotifyTextKeys | string;
  notifyKey?: string;
  notifyErrorDsc?: string;
  isExternalError?: boolean;
  isBlockedNotify?: boolean;
  isHideErrorNotify?: boolean;
  isIgnoreError?: boolean;
}

const tryCatchWrapper = async function <T>(
  f: () => Promise<T>,
  params?: TCWrapperParams
): Promise<U<T>> {
  const appPath = `tryCatchWrapper.${f.name}`;

  try {
    return await f();
  } catch (e: any) {
    if (!params?.isIgnoreError) return;

    if (e?.name === 'AxiosError') {
      const res = e?.response;

      const message = res?.data?.message || e.message;

      if (params?.ignoreCodes?.includes(res?.status)) {
        return res;
      }

      const errorMessage = params?.errorMessage;

      const sendErrorMessage = isNoticeKey(errorMessage)
        ? NOTICE[errorMessage]
        : errorMessage;

      if (params?.isBlockedNotify) {
        sendError(SERVER_ERROR, sendErrorMessage ?? e, appPath);
        console.error(e);

        return;
      }

      const notifyMessage = isNoticeKey(errorMessage)
        ? errorMessage
        : 'SERVER_ERROR';

      if (params?.isExternalError) {
        !params?.isHideErrorNotify &&
          notification.error(notifyMessage, {
            message: errorMessage,
            dsc: res?.data?.additional?.detail,
            key: params?.notifyKey,
          });

        return;
      }

      if (res?.status === 401) return;

      sendError(SERVER_ERROR, sendErrorMessage ?? e, appPath);

      !params?.isHideErrorNotify &&
        notification.error(notifyMessage, {
          dsc: params?.notifyErrorDsc ?? message,
          key: params?.notifyKey,
          message: errorMessage,
        });

      return;
    }

    if (e instanceof ZodError) {
      !params?.isHideErrorNotify && notification.error('INCORRECT_DATA_TYPES');

      sendError('Ошибка Zod', e, appPath);
    }

    console.error(e);
  }
};

export default tryCatchWrapper;
