import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import { IoIosWarning } from 'react-icons/io';

import { sliceTitleBy } from '../../../../../../../helpers/sliceTitle';
import rootStore from '../../../../../../../stores/rootStore/rootStore';
import { System } from '../../../../../../../ts/enums/enums';
import { TL } from '../../../../../../../ts/models/tl.model';
import IcoDevice from '../../../../../../IcoDevice/IcoDevice';
import PopoverContent from '../../../../../../ui-kit/Accordion/AccordionItem/PopoverContent/PopoverContent';
import Popover, {
  PopoverProps,
} from '../../../../../../ui-kit/Popover/Popover';
import { DisabledTypes } from '../../../../../MainPanelTabs/AllSystemsTab/constants/constants';
import { getTLWarnings } from '../../DipatchGroupDetailed/ControlCard/helpers/helpers';

import styles from './LabelItem.module.scss';

interface LabelItemProps {
  tl: TL;
  disabledType: N<DisabledTypes>;
}

const MAX_LENGTH = 18;

const LabelItem: FC<LabelItemProps> = ({ tl, disabledType }) => {
  const { interfaceTipsDelay } = rootStore.uiStore;
  const { selectedFeatureSomeArray } = rootStore.scriptsControlStore;

  const {
    deviceInfo: { caption, controlAccess },
    id,
  } = tl;

  const { isAccessMode } = getTLWarnings(controlAccess);

  const popoverProps: PopoverProps = {
    content: (
      <PopoverContent
        disabledType={disabledType}
        mapObject={tl}
        system={System.Lights}
      />
    ),
    tipsDelay: interfaceTipsDelay,
    placement: 'right',
  };

  const isActive = selectedFeatureSomeArray.some(
    ({ id: itemId }) => id === itemId
  );

  return (
    <Popover {...popoverProps}>
      <div className={styles.container}>
        <span
          className={classNames({
            [styles.id]: true,
            [styles.active]: isActive,
          })}
        >
          {id}
        </span>
        <p>{sliceTitleBy(caption, MAX_LENGTH)}</p>
        <div className={styles.status}>
          {!isAccessMode && <IoIosWarning className={styles.warningIco} />}
          <IcoDevice id={id} system={System.Lights} iconStyle={styles.icon} />
        </div>
      </div>
    </Popover>
  );
};

export default observer(LabelItem);
