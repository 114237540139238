import { Form } from 'antd';
import { Dayjs } from 'dayjs';
import { useEffect } from 'react';

import rootStore from '../../../../../../stores/rootStore/rootStore';
import { SEPARATE } from '../../../../../Panel/ScriptsControl/SelectModule/DispatchGroupControl/SelectItem/helpers/helpers';
import { getValidationRules } from '../../../DeviceAdminCard/DeviceFormContent/helpers/validationHelpers';
import {
  HandleChangeDate,
  HandleChangeSelect,
  SourceFormFields,
} from '../../../models/DeviceAdmin.model';
import { FORM_RULES_SOURCES, FormSourceKeys } from '../constants/constants';

const { LifetimeFrom, LifetimeTo, SystemTypeId, SystemId, DeviceInfo } =
  FormSourceKeys;

const useSourceForm = (initialProps: U<SourceFormFields>) => {
  const { setAdminsData } = rootStore.adminPanelStore;

  const [form] = Form.useForm<SourceFormFields>();

  const values = Form.useWatch([], form);

  useEffect(() => {
    initialProps && form.setFieldsValue(initialProps);
  }, [form, initialProps]);

  useEffect(() => {
    if (initialProps) return;

    const systemId = values?.[SystemId]?.split(SEPARATE)[0];
    const xeomaInfo = values?.[DeviceInfo]?.split(SEPARATE);
    const xeomaId = xeomaInfo?.[0];
    const xeomaName = xeomaInfo?.[1];

    if (!systemId || !xeomaId || !xeomaName) {
      setAdminsData('previewSource', null);

      return;
    }

    setAdminsData('previewSource', {
      systemId: Number(systemId),
      sourceName: xeomaName,
      sourceId: xeomaId,
    });
  }, [values, initialProps, setAdminsData]);

  // clear preview source
  useEffect(() => {
    return () => {
      setAdminsData('previewSource', null);
    };
  }, [setAdminsData]);

  const getSourceRules = (value: FormSourceKeys) =>
    getValidationRules(value, FORM_RULES_SOURCES);

  const handleChangeDate: HandleChangeDate = (value, key) => {
    if (key === LifetimeFrom) {
      const endDate: U<Dayjs> = form.getFieldValue(LifetimeTo);

      endDate &&
        value &&
        value.diff(endDate) >= 0 &&
        form.setFieldValue(LifetimeTo, null);
    }

    form.setFieldValue(key, value);
  };

  //clear dependency select systemId and deviceId
  const handleChangeSelect: HandleChangeSelect = (key, value) => {
    if (key === SystemTypeId) {
      form.setFieldsValue({
        systemId: undefined,
        deviceInfo: undefined,
      });
    }

    if (key === SystemId) {
      form.setFieldValue(DeviceInfo, undefined);
    }

    form.setFieldValue(key, value);
  };

  return {
    form,
    isEditForm: !!initialProps,
    getSourceRules,
    handleChangeDate,
    handleChangeSelect,
  };
};

export default useSourceForm;
