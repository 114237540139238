import { Feature, MapBrowserEvent } from 'ol';
import type { FeatureLike } from 'ol/Feature';
import EsriJSON from 'ol/format/EsriJSON';
import { Geometry } from 'ol/geom';
import VectorLayer from 'ol/layer/Vector';
import VectorTileLayer from 'ol/layer/VectorTile';

import { ILayer } from '../../stores/gisDataStore/gisDataStore.model';
import { TMap } from '../../stores/mapStore/mapStore.model';
import rootStore from '../../stores/rootStore/rootStore';
import { IIdentifyItem } from '../../ts/models/gis/identify.model';
import { BASEMAPS_PROPS } from '../constants/basemaps/utils';
import { DRAPING_LAYER_ID } from '../constants/map';
import { gisHttp } from '../gisHttp';
import { WebGLLayer } from '../layers/helpers';
import {
  IServiceDefinition,
  IServiceDefinitionFindProps,
} from '../models/services/servicesDefinitions';

const OBJECTID_FIELD = 'OBJECTID';
const ID_FIELD = 'id';
const MIN_PIXELS = 4;

const serviceDefinitions: IServiceDefinition[] = [];

export const findServiceDefinition = (
  props: IServiceDefinitionFindProps
): U<IServiceDefinition> => {
  return serviceDefinitions.find((element) => element.url === props.url);
};

export const executeIdentify = (map: TMap, evt: MapBrowserEvent<any>) => {
  const items: IIdentifyItem[] = [];

  const { layersState: definitions } = rootStore.gisDataStore;

  if (!map) {
    return items;
  }

  const layers = map.getLayers().getArray();

  const resolution = map.getView().getResolution() || 1;
  const { coordinate } = evt;
  const [x, y] = coordinate;
  const dR = resolution * MIN_PIXELS;
  const clickExtent = [x - dR, y - dR, x + dR, y + dR];

  const features: IIdentifyItem[] = [];

  for (let i = 0; i < definitions.length; i++) {
    const definition: ILayer = definitions[i];

    const { id, alias, url, displayField, tooltipDefinition } = definition;

    const layer = layers.find((item) => {
      const layerId = item.get('id');

      return layerId === id || layerId === id + BASEMAPS_PROPS.MVT_ID_PROPERTY;
    });

    const isInvisibleLayer = layer?.get('id') === DRAPING_LAYER_ID;

    if (
      layer &&
      layer.getVisible() &&
      !isInvisibleLayer &&
      (layer instanceof WebGLLayer ||
        layer instanceof VectorLayer ||
        layer instanceof VectorTileLayer)
    ) {
      const source = layer?.getSource();

      if (!source) {
        continue;
      }

      const sourceFeatures = source?.getFeaturesInExtent(clickExtent) || [];

      sourceFeatures.forEach((feature: FeatureLike) => {
        const properties = feature.getProperties();

        const tooltipFields =
          tooltipDefinition && tooltipDefinition.fields
            ? tooltipDefinition.fields
            : null;

        const title =
          displayField && properties[displayField]
            ? properties[displayField]
            : 'неизвестный объект';

        const subtitle = tooltipFields
          ? tooltipFields
              .map((field) => properties[field])
              .filter((value) => !!value)
              .join(', ')
          : null;

        const featureId = properties[OBJECTID_FIELD] || properties[ID_FIELD];

        const item: IIdentifyItem = {
          id: Math.random(),
          layerName: alias || id,
          layerId: id,
          featureId,
          serviceUrl: url,
          title,
          subtitle,
          feature,
        };

        features.push(item);
      });
    }
  }

  return features;
};

export const getFeature = async (item: IIdentifyItem): Promise<FeatureLike> => {
  try {
    const { serviceUrl, featureId, layerId } = item;

    let definition: U<IServiceDefinition> = findServiceDefinition({
      id: layerId,
      url: serviceUrl,
    });

    if (!definition) {
      const serviceDefinition: U<IServiceDefinition> = (
        await gisHttp.get(serviceUrl)
      ).data as IServiceDefinition;

      serviceDefinition.url = serviceUrl;

      serviceDefinitions.push(serviceDefinition);

      definition = Object.assign({}, serviceDefinition);
    }

    const { objectIdField } = definition;

    const { data: featureSet } = await gisHttp.get(
      `${serviceUrl}/query?where=${objectIdField}='${featureId}'`
    );

    const format = new EsriJSON();
    const features = format.readFeatures(featureSet, {}) as Feature<Geometry>[];

    if (features && features.length > 0) {
      return features[0];
    }

    return item.feature;
  } catch (e) {
    return item.feature;
  }
};
