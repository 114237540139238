import { Button } from 'antd';
import { FC } from 'react';

import Confirm from '../../../../../ui-kit/Confirm/Confirm';

const TEXT_CONFIRM = 'Вы уверены? Ваш черновик не будет сохранен';

interface FooterProps {
  OkBtn: FC;
  CancelBtn: FC;
  disabledReset: boolean;
  confirmClose: boolean;
  onCancel: () => void;
  handleReset: () => void;
}

const Footer: FC<FooterProps> = ({
  OkBtn,
  CancelBtn,
  disabledReset,
  confirmClose,
  onCancel,
  handleReset,
}) => {
  return (
    <>
      <Button
        danger
        type="primary"
        disabled={disabledReset}
        onClick={handleReset}
      >
        Отменить изменения
      </Button>
      {confirmClose ? (
        <Confirm
          title={TEXT_CONFIRM}
          okButtonProps={{ danger: true }}
          onConfirm={onCancel}
        >
          <Button type="default">Закрыть</Button>
        </Confirm>
      ) : (
        <CancelBtn />
      )}
      <OkBtn />
    </>
  );
};

export default Footer;
