import { observer } from 'mobx-react-lite';

import rootStore from '../../../../../stores/rootStore/rootStore';

import MapContent from './MapContent/MapContent';
import VideoContent from './VideoContent/VideoContent';

import styles from './AdditionalContent.module.scss';

const AdditionalContent = () => {
  const { isCameraAdminDevice } = rootStore.adminPanelStore;

  return (
    <div className={styles.container}>
      <MapContent />
      {isCameraAdminDevice ? <VideoContent /> : null}
    </div>
  );
};

export default observer(AdditionalContent);
