import { SourceDisplayType } from '../../../../../../stores/adminPanelStore/constants/constants';
import { RulesItems } from '../../../models/DeviceAdmin.model';

export enum FormSourceKeys {
  Sname = 'sname',
  Dsc = 'dsc',
  SystemTypeId = 'systemTypeId',
  SystemId = 'systemId',
  DeviceInfo = 'deviceInfo',
  StatusId = 'status_id',
  LifetimeFrom = 'lifetime_from',
  LifetimeTo = 'lifetime_to',
}

export const FORM_RULES_SOURCES: Record<FormSourceKeys, RulesItems> = {
  sname: {
    regExp: '^.{0,100}$',
    required: true,
  },
  dsc: {
    regExp: '^(?:.{0,500}(?:\r?\n|$)){1,4}$',
    required: false,
  },
  systemTypeId: {
    regExp: null,
    required: true,
  },
  systemId: {
    regExp: null,
    required: true,
  },
  deviceInfo: {
    regExp: null,
    required: true,
  },
  status_id: {
    regExp: null,
    required: true,
  },
  lifetime_from: {
    regExp: null,
    required: true,
  },
  lifetime_to: {
    regExp: null,
    required: false,
  },
};

export const BUTTON_TEXT: Record<
  Exclude<SourceDisplayType, 'sourceWatchForm'>,
  { submit: string; reset: string }
> = {
  sourceAddForm: {
    submit: 'Добавить',
    reset: 'Очистить форму',
  },
  sourceEditForm: {
    submit: 'Обновить данные',
    reset: 'Отменить изменения',
  },
};
