import { Form } from 'antd';
import { useEffect } from 'react';

import { AddFormFields } from '../../../../models/DeviceAdmin.model';
import { FormKeys } from '../../constants/constants';
import { getCoordsTooltip } from '../../helpers/coordHelpers';
import getWarningNotify from '../../helpers/getWarningNotify';

import useFormValues from './useFormValues';

interface UseDeviceAddCardProps {
  initialProps?: AddFormFields;
  pointUdsUid: Nullish<string>;
}

const useDeviceAddCard = ({
  initialProps,
  pointUdsUid,
}: UseDeviceAddCardProps) => {
  const [form] = Form.useForm<AddFormFields>();

  useFormValues(form);

  const warningNotify = getWarningNotify();

  const tooltipCoordLongitude = getCoordsTooltip(
    pointUdsUid,
    FormKeys.Longitude
  );
  const tooltipCoordLatitude = getCoordsTooltip(pointUdsUid, FormKeys.Latitude);

  useEffect(() => {
    initialProps && form.setFieldsValue(initialProps);
  }, [form, initialProps]);

  return { form, warningNotify, tooltipCoordLongitude, tooltipCoordLatitude };
};

export default useDeviceAddCard;
