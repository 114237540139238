import { List } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { FC, useEffect, useLayoutEffect, useState } from 'react';
import { HiMagnifyingGlassPlus } from 'react-icons/hi2';
import { IoLocationOutline } from 'react-icons/io5';
import {
  MdOutlineFormatListBulleted,
  MdOutlineOpenInNew,
} from 'react-icons/md';

import {
  flyTo,
  hideFeatures,
  showFeatures,
} from '../../../apiGIS/layers/utils/flashFeatures';
import { getFeature } from '../../../apiGIS/tasks/identify';
import { getClientUrl } from '../../../apiGIS/utils/client';
import rootStore from '../../../stores/rootStore/rootStore';
import { IIdentifyItem } from '../../../ts/models/gis/identify.model';
import FeatureForm from '../../ui-kit/FeatureForm/FeatureForm';
import { ActionItem } from '../../ui-kit/GisFeatureActionItem/ActionItem';

import EmptyListGISObjects from './EmptyListGISObjects';
import { getItemProperties, IFeatureProperty } from './utils/utils';

import styles from './IdentificationInfo.module.scss';

const HIDE_FEATURE_TTL = 5000;

const PAGE_SETTINGS = {
  position: 'bottom',
  align: 'center',
  size: 'default',
} as const;

const LIST_ITEM_MAX_SIZE = 120;
const getPageSize = () =>
  Math.round(document.body.clientHeight / LIST_ITEM_MAX_SIZE);

const IdentificationInfo: FC = () => {
  const { identifiedItems } = rootStore.uiStore;
  const { layersState: definitions } = rootStore.gisDataStore;

  const [items, setItems] = useState<IIdentifyItem[]>([]);
  const [selected, setSelected] = useState<U<IIdentifyItem>>();

  const [selectedProperties, setSelectedProperties] = useState<
    U<IFeatureProperty[]>
  >([]);

  const [pageSize, setPageSize] = useState(getPageSize());

  useEffect(() => {
    setItems(identifiedItems);
  }, [identifiedItems]);

  useLayoutEffect(() => {
    const updateSize = () => {
      setPageSize(getPageSize());
    };

    window.addEventListener('resize', updateSize);

    updateSize();

    return () => window.removeEventListener('resize', updateSize);
  }, []);

  const showFeature = async (item: IIdentifyItem) => {
    if (!item) {
      return;
    }

    hideFeatures([]);

    const feature = await getFeature(item);

    showFeatures([feature]);

    setTimeout(() => {
      hideFeatures([feature]);
    }, HIDE_FEATURE_TTL);
  };

  const hideFeature = async (item: IIdentifyItem) => {
    if (!item) {
      return;
    }

    const feature = await getFeature(item);

    hideFeatures([feature]);
  };

  const showItemForm = async (item: IIdentifyItem) => {
    item.feature = await getFeature(item);

    const definition = definitions.find(
      (element) => element.id === item.layerId
    );

    const properties = getItemProperties(item, definition);

    setSelectedProperties(properties);

    setSelected(item);
  };

  const zoomTo = async (item: IIdentifyItem) => {
    if (!item) {
      return;
    }

    const feature = await getFeature(item);

    flyTo(feature);
  };

  const toGIS = async (item: IIdentifyItem | undefined) => {
    if (!item) {
      return;
    }

    const { layerId, featureId } = item;

    if (!layerId || !featureId) {
      return;
    }

    getClientUrl().then((clientUrl) => {
      const url = `${clientUrl}&className=${layerId}&id=${featureId}`;

      window.open(url, `_blank`);
    });
  };

  const paginationSettings = () => {
    if (items.length <= pageSize) {
      return undefined;
    }

    return {
      ...PAGE_SETTINGS,
      pageSize,
    };
  };

  if (selected)
    return (
      <FeatureForm
        onClick={() => setSelected(undefined)}
        dataSource={selectedProperties}
        onZoom={() => zoomTo(selected)}
        onGIS={() => toGIS(selected)}
      />
    );

  if (!items.length) return <EmptyListGISObjects />;

  return (
    <List
      pagination={paginationSettings()}
      dataSource={items}
      renderItem={(item, index) => (
        <div className={styles.listItem}>
          <List.Item
            onMouseOver={() => showFeature(item)}
            onMouseLeave={() => hideFeature(item)}
            key={'feature-' + index}
          >
            <div>
              <div className={styles.listItemTitle}>
                <IoLocationOutline className={styles.icon} /> {item.title}
              </div>
              <div className={styles.listItemSubTitle}>
                {item.subtitle || item.layerName}
              </div>

              <div className={styles.actionsContainer}>
                <ActionItem
                  icon={HiMagnifyingGlassPlus}
                  text="перейти к объекту"
                  onClick={() => zoomTo(item)}
                />
                <ActionItem
                  icon={MdOutlineFormatListBulleted}
                  text="карточка"
                  onClick={() => showItemForm(item)}
                />
                <ActionItem
                  icon={MdOutlineOpenInNew}
                  text="открыть в ГИС"
                  onClick={() => toGIS(item)}
                />
              </div>
            </div>
          </List.Item>
        </div>
      )}
    />
  );
};

export default observer(IdentificationInfo);
