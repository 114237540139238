import { gisHttp } from '../gisHttp';

const URL_SEPARATOR = '/{z}';

interface VectorStyleItem {
  filter: any;
  style: any;
}

const getVectorTileStyle = async (url: string): Promise<VectorStyleItem[]> => {
  try {
    const parts = url.split(URL_SEPARATOR);

    let [styleUrl] = parts;

    styleUrl += `/styles`;

    const { data } = await gisHttp.get(styleUrl);

    return data;
  } catch (e) {
    return [];
  }
};

export { getVectorTileStyle };

export type { VectorStyleItem };
