import {
  ColumnTypes,
  IFilter,
  ITableColumn,
  TableData,
} from '../../../../../../ts/models/table.model';
import { DEFAULT_TABLE_VALUES } from '../../../constants';
import { getLibraryItem } from '../../getLibraryItem';

import {
  TYPE_RENDERS,
  getTypeRendersWithLibrary,
} from './prepareColumnDataView/prepareColumnDataView';

const getMatchedValue = (initialValue: any, matchValue: any) => {
  return initialValue
    .toString()
    .trim()
    .toLowerCase()
    .replaceAll(' ', '')
    .includes(matchValue.toString().toLowerCase().replaceAll(' ', ''));
};

export const addFilters = (column: ITableColumn, tableData: TableData) => {
  column.onFilter = (value, record) => {
    const recordValue = record[column.dataIndex];

    if (!recordValue && recordValue !== false) return false;

    if (
      column.type === ColumnTypes.jArrayNumber ||
      column.type === ColumnTypes.jArrayString
    ) {
      return recordValue.includes(value);
    }

    if (column.type === ColumnTypes.boolean) {
      return recordValue === value;
    }

    if (column.type === ColumnTypes.date) {
      const render = TYPE_RENDERS['date'];

      if (!render) return false;

      const renderedValue = render(recordValue) || '';

      return getMatchedValue(renderedValue, value);
    }

    return getMatchedValue(recordValue, value);
  };

  const filters = column.filterSettings?.filters;

  if (!filters) return;

  const parsedFilters: IFilter[] = [];

  const uniqueValues = tableData.dataRows.reduce((acc, row) => {
    const colValue = row[column.referens];

    const isArray = Array.isArray(colValue);
    const defaultText = DEFAULT_TABLE_VALUES[column.type];

    if (isArray) {
      colValue.forEach((el) => acc.add(el));
    } else {
      acc.add(defaultText ? colValue ?? defaultText : colValue);
    }

    return acc;
  }, new Set<string | number | boolean>());

  const libraryItem = getLibraryItem(column, tableData);

  uniqueValues.forEach((value) => {
    const renderTypes = getTypeRendersWithLibrary(libraryItem);

    const renderFunc = renderTypes[typeof value as keyof typeof renderTypes];

    const parsedValue = renderFunc?.(value as any)?.toString() || '';

    parsedFilters.push({
      text: parsedValue,
      value,
    });
  });

  column.filters = parsedFilters;
};
