import { Descriptions } from 'antd';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import { ExternalLink } from '../../../../../../api/devices/model/devices.model';

import getSourceInfo from './helpers/getSourceInfo';
import VideoContainer from './VideoContainer/VideoContainer';

import styles from './SourceInfo.module.scss';

interface SourceInfoProps {
  fullSourceInfo: U<ExternalLink>;
  isFullInfo?: boolean;
}

const SourceInfo: FC<SourceInfoProps> = ({
  fullSourceInfo,
  isFullInfo = true,
}) => {
  const {
    fullInfo: items,
    cameraExtId,
    name,
  } = getSourceInfo(fullSourceInfo, isFullInfo);

  return (
    <div
      className={classNames(styles.container, { [styles.full]: !isFullInfo })}
    >
      {!isFullInfo ? (
        <p className={styles.title}>Информация об источнике</p>
      ) : null}
      <VideoContainer
        systemId={fullSourceInfo?.sc_system_id}
        cameraExtId={cameraExtId}
        name={name}
        dsc={fullSourceInfo?.dsc}
      />
      <Descriptions
        layout="vertical"
        items={items}
        column={isFullInfo ? 2 : 1}
        bordered={isFullInfo}
        className={styles.desc}
      />
    </div>
  );
};

export default observer(SourceInfo);
