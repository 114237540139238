import { Path } from '../../api.model';
import { getAppPathHeaders } from '../../helpers/getAppPathHeaders';
import http from '../../http';
import tryCatchWrapper from '../../tryCatchWrapper';
import { SERVICES } from '../dtStatistics/constants/constants';

import { Profile } from './coordGroup/profiles/profilesApi.model';
import useCheckGroupQuery from './query/useCheckGroupQuery';
import {
  CheckGroup,
  GroupCheckRes,
  TLCoordination,
  WaveType,
} from './tlCoordination.model';

export const COORDINATION = SERVICES + '/tl-coordination';

const COORD_GROUP = COORDINATION + '/coord-group/';
const DIAGRAMS = '/coord-diagrams';
const CHECK = '/v1/check';

class TLCoordinationApi {
  fetchCoordinationDiagram = (
    uuid: string,
    wave: WaveType,
    profile: Profile,
    appPath: Path
  ) =>
    tryCatchWrapper(async () => {
      const res = await http.post<TLCoordination>(
        COORD_GROUP + uuid + DIAGRAMS,
        profile,
        {
          headers: getAppPathHeaders(appPath),
          params: {
            wave,
            part: wave,
          },
        }
      );

      return res.data;
    });

  checkGroup: CheckGroup = (tls, appPath) =>
    tryCatchWrapper(async () => {
      if (tls.length <= 1) return;

      const res = await http.get<GroupCheckRes>(COORDINATION + CHECK, {
        headers: getAppPathHeaders(appPath),
        params: {
          tls: tls.toString(),
        },
      });

      return res.data;
    });

  useCheckGroup = (tlsIds: number[], path: Path) =>
    useCheckGroupQuery(tlsIds, path, this.checkGroup);
}

const tlCoordinationApi = new TLCoordinationApi();

export default tlCoordinationApi;
