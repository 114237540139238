import { FC } from 'react';

import { COLORS } from '../../../../constants/colorsConstants';
import {
  DeviceFormType,
  SourceDisplayType,
} from '../../../../stores/adminPanelStore/constants/constants';
import DeviceAddContainer from '../DeviceAdminCard/DeviceFormContent/DeviceAddCard/DeviceAddContainer/DeviceAddContainer';
import DeviceEditCard from '../DeviceAdminCard/DeviceFormContent/DeviceEditCard/DeviceEditCard';
import SourceAddForm from '../SourceEdit/SourceAddForm/SourceAddForm';
import SourceEditForm from '../SourceEdit/SourceEditForm/SourceEditForm';
import SourceWatchCard from '../SourceEdit/SourceWatchCard/SourceWatchCard';

export const EMPTY_SOURCE_TITLE =
  'Для просмотра источников сначала необходимо выбрать или добавить устройство';

export const DEVICE_FORM_CONTENT: Record<DeviceFormType, FC> = {
  addForm: DeviceAddContainer,
  editForm: DeviceEditCard,
};

export const ERROR_TEXT = {
  ERROR: 'Ошибка получения источников устройства',
  NO_DATA: 'Источники для данного устройства отсутствуют',
};

export const LIST_SIZE = 6;

export enum ExternalMnemoStatus {
  Active = 'active',
  NotActive = 'notactive',
  Duplicate = 'dublicate',
}

export const STATUS_COLORS: Record<ExternalMnemoStatus, string> = {
  active: COLORS.SUCCESS,
  notactive: COLORS.DISABLED,
  dublicate: COLORS.WARNING,
};

export const SOURCE_FORM_CONTENT: Record<SourceDisplayType, FC> = {
  sourceAddForm: SourceAddForm,
  sourceEditForm: SourceEditForm,
  sourceWatchForm: SourceWatchCard,
};

export const SOURCE_LABELS: Record<SourceDisplayType, string> = {
  sourceAddForm: 'Добавление источника',
  sourceEditForm: 'Редактирование источника',
  sourceWatchForm: 'Информация об источнике',
};

export const DEVICE_LABELS: Record<DeviceFormType, string> = {
  addForm: 'Добавление устройства',
  editForm: 'Редактирование устройства',
};
