import type { TableRef } from 'antd/lib/table';
import { RefObject, useCallback, useEffect, useState } from 'react';

import { DEFAULT_PAGES } from '../../../../constants/tableConstants';
import eventBus from '../../../../eventBus';
import {
  PaginationOnChange,
  TableKeys,
} from '../../../../ts/models/table.model';
import { ITable } from '../Table';

interface useTableHeightControlParams {
  ref: RefObject<TableRef>;
  notFullscreenMode: boolean;
  dependenciesArray: unknown[];
  tableConfigType: TableKeys;
  refreshData: ITable['refreshData'];
}

const TABLE_PADDING = 10;
const CONSTANT_DIFF = 20;
const TABLE_PAGINATION_MARGINS = 10 * 2;
const DIVIDER_ANIMATION_TIME_MC = 600;

export const useTableHeightControl = (options: useTableHeightControlParams) => {
  const {
    ref,
    dependenciesArray,
    notFullscreenMode,
    tableConfigType,
    refreshData,
  } = options;
  const [pageSize, setPageSize] = useState<number>(DEFAULT_PAGES);
  const [minPageSize, setMinPageSize] = useState<number>(DEFAULT_PAGES);

  const setCorrectTableHeight = useCallback(() => {
    if (notFullscreenMode || !ref.current?.nativeElement) return;
    const { nativeElement } = ref.current;

    const table = nativeElement.querySelector('.ant-table');

    if (!table) return;

    const tableHeader = table.querySelector(
      '.ant-table-thead'
    ) as N<HTMLElement>;

    const tableBody = table.querySelector(
      '.ant-table-content'
    ) as N<HTMLElement>;

    const tableFooter = table.querySelector(
      '.ant-table-footer'
    ) as N<HTMLElement>;

    const tablePagination = nativeElement.querySelector(
      '.ant-pagination'
    ) as N<HTMLElement>;

    if (!tableBody) return;

    const windowHeight = window.innerHeight;

    const rect = table.getBoundingClientRect();

    let tableBodyHeight =
      windowHeight - rect.top - TABLE_PADDING - CONSTANT_DIFF;

    if (tableHeader) tableBodyHeight -= tableHeader.offsetHeight;

    if (tableFooter) tableBodyHeight -= tableFooter.offsetHeight;

    if (tablePagination)
      tableBodyHeight -=
        tablePagination.offsetHeight + TABLE_PAGINATION_MARGINS;

    const newHeight = tableBodyHeight + (tableHeader?.offsetHeight ?? 0);

    tableBody.style.maxHeight = '';
    tableBody.style.height = newHeight + 'px';

    const rowItem = nativeElement.querySelector(
      '.ant-table-row'
    ) as N<HTMLElement>;

    const rowsElements = nativeElement.getElementsByClassName('ant-table-row');

    if (rect.top > windowHeight || rect.top < 0) {
      setTimeout(setCorrectTableHeight, DIVIDER_ANIMATION_TIME_MC);

      return;
    }

    if (rowItem && rowsElements.length) {
      const pageSize = Math.floor(tableBodyHeight / rowItem.offsetHeight);

      setPageSize(pageSize);

      setMinPageSize(pageSize);
    }
  }, [ref, notFullscreenMode]);

  const setInitialTableHeight = useCallback(() => {
    if (notFullscreenMode || !ref.current?.nativeElement) return;
    const { nativeElement } = ref.current;

    const tableBody = nativeElement.querySelector(
      '.ant-table-content'
    ) as N<HTMLElement>;

    if (!tableBody) return;

    tableBody.style.height = 'initial';
  }, [ref, notFullscreenMode]);

  useEffect(() => {
    const subHandle: PaginationOnChange = (
      key: TableKeys,
      pageSize: number
    ) => {
      if (key !== tableConfigType) return;

      setPageSize(pageSize);

      if (pageSize === minPageSize) {
        return refreshData();
      }

      if (pageSize > minPageSize) {
        return setInitialTableHeight();
      }

      setCorrectTableHeight();
    };

    const unsubscribe = eventBus.tablePaginationChange.subscribe(subHandle);

    return unsubscribe;
  }, [
    notFullscreenMode,
    minPageSize,
    tableConfigType,
    ref,
    setCorrectTableHeight,
    setInitialTableHeight,
    refreshData,
  ]);

  useEffect(() => {
    //производим перерасчёт высоты таблицы при изменении переданного массива зависимостей
    setCorrectTableHeight();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependenciesArray);

  useEffect(() => {
    //производим перерасчёт высоты таблицы при изменении масштаба и размера страницы
    window.addEventListener('resize', setCorrectTableHeight);

    return () => {
      window.removeEventListener('resize', setCorrectTableHeight);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [pageSize, minPageSize];
};
