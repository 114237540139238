import { getInitialLayerFilterSettings } from '../../utils/filters';

export const LAYER_FILTER_COMPONENTS_ITEMS = [
  { text: 'Контраст', property: 'contrast', min: 0, max: 300, step: 1 },
  { text: 'Яркость', property: 'brightness', min: 0, max: 300, step: 1 },
  { text: 'Насыщенность', property: 'saturate', min: 0, max: 200, step: 1 },
  { text: 'Цветовой сдвиг', property: 'hueRotate', min: 0, max: 360, step: 1 },
  { text: 'Размытие', property: 'blur', min: 0, max: 50, step: 1 },
  { text: 'Инверсия', property: 'invert', min: 0, max: 100, step: 1 },
  { text: 'Сепия', property: 'sepia', min: 0, max: 100, step: 1 },
];

const LAYER_DEFAULT_FILTER_SETTINGS = getInitialLayerFilterSettings();
const LAYER_FILTER_GRAY_SETTINGS = {
  ...LAYER_DEFAULT_FILTER_SETTINGS,
  grayscale: 100,
  saturate: 0,
};
const LAYER_FILTER_CONTRAST_SETTINGS = {
  ...LAYER_DEFAULT_FILTER_SETTINGS,
  contrast: 150,
};
const LAYER_FILTER_BACKGROUND_SETTINGS = {
  ...LAYER_DEFAULT_FILTER_SETTINGS,
  contrast: 60,
  brightness: 110,
  saturate: 25,
  sepia: 5,
};
const LAYER_FILTER_SATURATE_SETTINGS = {
  ...LAYER_DEFAULT_FILTER_SETTINGS,
  contrast: 100,
  brightness: 100,
  saturate: 120,
};
const LAYER_FILTER_BLUR_SETTINGS = {
  ...LAYER_DEFAULT_FILTER_SETTINGS,
  blur: 3,
};

export const LAYER_SETTINGS_PREDEFINED_ITEMS = [
  { text: 'сброс', value: LAYER_DEFAULT_FILTER_SETTINGS },
  { text: 'серый', value: LAYER_FILTER_GRAY_SETTINGS },
  { text: 'контраст', value: LAYER_FILTER_CONTRAST_SETTINGS },
  { text: 'фон', value: LAYER_FILTER_BACKGROUND_SETTINGS },
  { text: 'сочный', value: LAYER_FILTER_SATURATE_SETTINGS },
  { text: 'размытие', value: LAYER_FILTER_BLUR_SETTINGS },
];
