import classNames from 'classnames';
import { isUndefined } from 'lodash';
import { FC } from 'react';

import { System } from '../../../ts/enums/enums';
import { switchIco } from '../../DetailedMain/helpers/switchHeaderIco';
import { ALL_SYSTEM_TITLE } from '../../DetailedStatistics/constants/constants';
import { getDeviceColor } from '../../MapBtns/MapInfoWrapper/InfoBtn/ContentInfo/helpers/getMapInfo';
import { getTLIcon } from '../../Mapper/helpers/getStyle';
import { DevIcoProps } from '../models/DeviceIco.model';

import styles from './DevIco.module.scss';

const DevIco: FC<DevIcoProps> = ({
  system,
  mode,
  statusDevice,
  bgColor,
  iconStyle,
}) => {
  const { ico, isBg } =
    system !== ALL_SYSTEM_TITLE
      ? switchIco(system)
      : { isBg: false, ico: null };

  const isCorrectStatus = Number.isFinite(mode);

  const isTL = system === System.Lights && isCorrectStatus;

  const { ico: icoSystem, borderColor } = isTL
    ? getTLIcon(mode ?? null)
    : { ico, borderColor: null };

  if (isBg) return icoSystem;

  const colorStatus = isUndefined(statusDevice)
    ? 'none'
    : getDeviceColor(statusDevice);

  const styleContainer = classNames(styles.ico, iconStyle);

  return (
    <span
      className={styleContainer}
      style={{
        backgroundColor: bgColor ?? colorStatus,
        borderWidth: !borderColor ? 0 : undefined,
        borderColor: borderColor ?? undefined,
      }}
    >
      {icoSystem}
    </span>
  );
};

export default DevIco;
