import classNames from 'classnames';
import { FC } from 'react';

import styles from './DescriptionItem.module.scss';

interface DescriptionItemProps {
  id: U<number>;
  name: string;
  errorText?: string;
  isActive?: boolean;
}

const DescriptionItem: FC<DescriptionItemProps> = ({
  id,
  name,
  errorText,
  isActive = false,
}) => {
  if (!id) return <>{errorText ?? null}</>;

  return (
    <div className={styles.title}>
      <span className={classNames(styles.id, { [styles.active]: isActive })}>
        {id ?? null}
      </span>
      <p>{name}</p>
    </div>
  );
};

export default DescriptionItem;
