import createStepArray from '../helpers/createStepArray';

export const Y_OFFSET = 50;
export const Y_MIN = -Y_OFFSET;
export const MAX_DISTANCE = 1200;
export const X_STEP = 20;
export const MIN_Y_TICK_DISTANCE = 25;
const DISTANCE_STEP = 50;

export const Y_TICKS = [Y_MIN].concat(
  createStepArray(MAX_DISTANCE, DISTANCE_STEP)
);

export const DETAILED_HEADER = 'Группа координации';
