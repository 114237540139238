import { Form, Select } from 'antd';
import { type Rule } from 'antd/es/form';
import { FormInstance } from 'antd/lib';
import { isString } from 'lodash';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import { SourceFormFields } from '../../../models/DeviceAdmin.model';
import { FormSourceKeys } from '../constants/constants';

import useExtDevices from './hooks/useExtDevices';

import styles from './ExternalDevicesSelect.module.scss';

const { DeviceInfo } = FormSourceKeys;

interface ExternalDevicesSelectProps {
  form: FormInstance<SourceFormFields>;
  getSourceRules: (value: FormSourceKeys) => U<Rule[]>;
  isShow: boolean;
}

const ExternalDevicesSelect: FC<ExternalDevicesSelectProps> = ({
  form,
  getSourceRules,
  isShow,
}) => {
  const values = Form.useWatch([], form);

  const systemId = values?.systemId;

  const { options, isLoading, isDisabled } = useExtDevices(systemId);

  if (!isShow) return null;

  const placeholder =
    isDisabled && isString(systemId)
      ? 'Ошибка получения внешних устройств'
      : 'Внешнее устройство';

  return (
    <Form.Item
      label="Внешнее устройство"
      name={DeviceInfo}
      rules={getSourceRules(DeviceInfo)}
    >
      <Select
        placeholder={placeholder}
        loading={isLoading}
        options={options ?? undefined}
        allowClear
        showSearch
        filterOption={(input, option) =>
          (option?.value ?? '')
            .toString()
            .toUpperCase()
            .includes(input.toUpperCase())
        }
        disabled={!values?.systemTypeId || !values?.systemId || isDisabled}
        popupClassName={styles.dropdown}
      />
    </Form.Item>
  );
};

export default observer(ExternalDevicesSelect);
