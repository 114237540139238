import { Descriptions } from 'antd';
import { DescriptionsProps } from 'antd/lib';
import { FC } from 'react';

import InfoBtn from '../../../../../../ui-kit/InfoBtn/InfoBtn';

import styles from './SourceInfo.module.scss';

type Item = NonNullable<DescriptionsProps['items']>[number];

interface SourceInfoProps {
  lifetimeFrom: Item;
  lifetimeTo: Item;
}

const SourceInfo: FC<SourceInfoProps> = ({ lifetimeFrom, lifetimeTo }) => {
  const items = [lifetimeFrom, lifetimeTo].map((el) => ({
    ...el,
    children: el.children ?? 'Не задано',
  }));

  return (
    <InfoBtn
      popoverContent={
        <Descriptions
          layout="vertical"
          items={items}
          column={1}
          className={styles.dsc}
        />
      }
    />
  );
};

export default SourceInfo;
