import { LonLat } from '../../../../../../stores/mapDataStore/mapDataStore.model';
import rootStore from '../../../../../../stores/rootStore/rootStore';

const DEFAULT_COORD_TEXT = 'Допустимый диапазон значений:';
const { Latitude, Longitude } = LonLat;

const getCoordInfo = (key: string) => {
  const { regionData } = rootStore.uiStore;

  const isLatitude = key.includes(Latitude);
  const isLongitude = key.includes(Longitude);

  if ((!isLatitude && !isLongitude) || !regionData) return 'Введите координаты';

  const { mapCenterCoords } = regionData;

  const [lon, lat] = mapCenterCoords;

  if (isLongitude) return lon;

  return lat;
};

export const getCoordPlaceholder = (key: string) => {
  return getCoordInfo(key).toString().replace('.', ',');
};

export const getCoordTemplate = (
  min: number,
  max: number,
  defaultText?: string
) =>
  `${DEFAULT_COORD_TEXT} ${`${min} - ${max}`.replaceAll('.', ',')}. ${
    defaultText ?? ''
  }`;

export const getCoordsTooltip = (pointUdsUid: Nullish<string>, key: string) => {
  const { regionData } = rootStore.uiStore;

  const isLongitude = key.includes(Longitude);

  const defaultText = pointUdsUid
    ? `Устройство привязано к точке УДС с uid: ${pointUdsUid}. Рекомендовано редактировать координаты через модуль - Конструктор`
    : undefined;

  if (!regionData) return defaultText;

  const { extent } = regionData;

  const [xMin, yMin, xMax, yMax] = extent;

  if (isLongitude) return getCoordTemplate(xMin, xMax, defaultText);

  return getCoordTemplate(yMin, yMax, defaultText);
};
