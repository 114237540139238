import { Tabs } from 'antd';
import { observer } from 'mobx-react-lite';

import { DeviceFormType } from '../../../../stores/adminPanelStore/constants/constants';
import rootStore from '../../../../stores/rootStore/rootStore';

import { ADDITIONAL_ITEMS } from './constants/constants';

import styles from './AdditionalCard.module.scss';

const { EditForm } = DeviceFormType;

const AdditionalCard = () => {
  const { deviceFormType, selectedDevice } = rootStore.adminPanelStore;

  const isAdditionalHidden = deviceFormType === EditForm && !selectedDevice;

  if (!deviceFormType || isAdditionalHidden) return null;

  return (
    <div className={styles.block}>
      <Tabs className={styles.tabsCustom} items={ADDITIONAL_ITEMS} />
    </div>
  );
};

export default observer(AdditionalCard);
