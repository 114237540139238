import { Tag } from 'antd';
import { FC } from 'react';

import { QueryParamItem } from '../../../../../../../ts/models/table.model';
import { parseFilterValue } from '../helpers';

import styles from './FiltersList.module.scss';

type Filter = [string, QueryParamItem];

interface IFiltersList {
  filters: Filter[];
}

const FiltersList: FC<IFiltersList> = ({ filters }) => {
  return (
    <ul className={styles.wrapper}>
      {filters.map((filter, i) => (
        <li key={i} className={styles.filter}>
          <p className={styles.key}>{filter[0]}:</p>
          <Tag className={styles.tag}>{parseFilterValue(filter[1])}</Tag>
        </li>
      ))}
    </ul>
  );
};

export default FiltersList;
