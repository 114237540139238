import { Form, Select } from 'antd';
import { type Rule } from 'antd/es/form';
import { FormInstance } from 'antd/lib';
import { isNumber } from 'lodash';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import {
  HandleChangeSelect,
  SourceFormFields,
} from '../../../models/DeviceAdmin.model';
import { FormSourceKeys } from '../constants/constants';

import useSystemsSelect from './hooks/useSystemsSelect';

import styles from './SystemsSelect.module.scss';

const { SystemId } = FormSourceKeys;

interface SystemsSelectProps {
  form: FormInstance<SourceFormFields>;
  getSourceRules: (value: FormSourceKeys) => U<Rule[]>;
  handleChangeSelect: HandleChangeSelect;
  isShow: boolean;
}

const SystemsSelect: FC<SystemsSelectProps> = ({
  form,
  getSourceRules,
  handleChangeSelect,
  isShow,
}) => {
  const values = Form.useWatch([], form);

  const systemTypeId = values?.systemTypeId;

  const { options, isLoading, isDisabled } = useSystemsSelect(systemTypeId);

  if (!isShow) return null;

  const placeholder =
    isDisabled && isNumber(systemTypeId)
      ? 'Ошибка получения систем'
      : 'Выберите систему';

  return (
    <Form.Item label="Система" name={SystemId} rules={getSourceRules(SystemId)}>
      <Select
        placeholder={placeholder}
        loading={isLoading}
        options={options ?? undefined}
        allowClear
        disabled={!values?.systemTypeId || isDisabled}
        onChange={(value) => handleChangeSelect(SystemId, value)}
        popupClassName={styles.dropdown}
      />
    </Form.Item>
  );
};

export default observer(SystemsSelect);
