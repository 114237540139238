import classNames from 'classnames';

import { MODES_INFO } from '../../../../../../constants/commands';
import {
  DEVICES_INFO,
  DEVICES_STATE,
  TDeviceStatus,
} from '../../../../../../constants/devicesConstants';
import { findBy } from '../../../../../../helpers/findBy';
import { System } from '../../../../../../ts/enums/enums';
import { TlMode } from '../../../../../../ts/models/tl.model';
import { ALL_SYSTEM_TITLE } from '../../../../../DetailedStatistics/constants/constants';
import DevIco from '../../../../../IcoDevice/DevIco/DevIco';
import IcoDevice from '../../../../../IcoDevice/IcoDevice';
import { CartographyTabName } from '../../../../../Panel/MainPanel/constants';

import styles from '../ContentInfo.module.scss';

interface DataItem {
  value: string;
  icon: JSX.Element;
}

export interface MapInfoItem {
  title: string;
  data: DataItem[];
}

type GetMapInfoFunc = () => MapInfoItem[];

const { Off, Critical, Unknown } = MODES_INFO;
const { NotWork, NotConnection, Work, Undefined } = DEVICES_INFO.STATE;

export const getDeviceColor = (deviceStatus: N<TDeviceStatus>) => {
  const color =
    deviceStatus && findBy(DEVICES_STATE, deviceStatus, 'com')?.color;

  return color ?? Undefined.color;
};

const MODES_COLORS = {
  OFF: {
    modes: [Off.com, Critical.com],
    color: getDeviceColor(NotWork.com),
  },
  NOT_CONNECT: {
    modes: [Unknown.com],
    color: getDeviceColor(NotConnection.com),
  },
} as const;

const getModeTLColor = (mode: TlMode) => {
  const modesInfo = Object.values(MODES_COLORS);
  const color =
    modesInfo.find(({ modes }) => modes.some((id) => id === mode))?.color ??
    getDeviceColor(Work.com);

  return color;
};

export const getMapInfo: GetMapInfoFunc = () => {
  const dataSubsystems = Object.values(System).reduce(
    (acc: DataItem[], system) => {
      acc.push({
        value: system,
        icon: (
          <IcoDevice
            system={system}
            iconStyle={classNames({
              [styles.icon]: true,
              [styles.meteoIcon]: system === System.Meteo,
            })}
          />
        ),
      });

      return acc;
    },
    []
  );

  const modesInfo = Object.values(MODES_INFO);

  return [
    { title: CartographyTabName.Subsystems, data: dataSubsystems },
    {
      title: 'Статусы подсистем',
      data: DEVICES_STATE.map(({ com, dsc }) => ({
        value: dsc,
        icon: <DevIco system={ALL_SYSTEM_TITLE} statusDevice={com} />,
      })),
    },
    {
      title: 'Режимы СО',
      data: modesInfo.map(({ com, dsc }) => ({
        value: dsc,
        icon: (
          <DevIco
            system={System.Lights}
            mode={com}
            bgColor={getModeTLColor(com)}
          />
        ),
      })),
    },
  ];
};
