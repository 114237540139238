/* eslint-disable camelcase */
import { isNumber } from 'lodash';

import { LibraryKeys } from '../../../../../api/libraries/enums/enums';
import { NormilizeData } from '../../../../../api/mapObjects/detectors/data/model/detectors.model';
import { neverTypeCheck } from '../../../../../helpers/neverTypeCheck';
import sortByStr from '../../../../../helpers/sortByStr';
import rootStore from '../../../../../stores/rootStore/rootStore';
import { Detector } from '../../../../../ts/models/mapObject.model';
import { LaneParams } from '../../../../../ts/models/MapObjects/detectors.model';
import { ChartBDKeys } from '../../../../InfoPanel/SystemsInfo/PopupDetector/Chart/constants/constants';
import { DirectionsTypes } from '../../../../InfoPanel/SystemsInfo/PopupDetector/PopupDetector.model';
import {
  FilterData,
  FilterNestedItem,
} from '../../../../ui-kit/Chart/models/chart.model';
import {
  DIRECTIONS_ICONS,
  FILTERED_INFO,
  FilteredTypes,
  GraphTypes,
  NORMATIVE_VALUES,
  NormativeIds,
  VEHICLES_ICONS,
} from '../../constants/constants';
import NormativeIco from '../NormativeIco/NormativeIco';

const { OnCamera, OutCamera, Undefined } = DirectionsTypes;
const { ChartFields } = LibraryKeys;

const getLanesData = (
  laneParams: U<LaneParams[]>,
  parentFilter: FilteredTypes
): U<FilterNestedItem[]> => {
  const lanesData = laneParams?.reduce((acc: FilterNestedItem[], { id }) => {
    const isNumberValid = isNumber(id);

    if (isNumberValid) {
      acc.push({
        value: <p>{id}</p>,
        type: id,
        name: `Полоса № ${id}`,
        parentFilter,
        filterType: FilteredTypes.Lanes,
      });
    }

    return acc;
  }, []);

  return lanesData;
};

const getDirData = (dai_jdt83: Detector['dai_jdt83']): FilterData[] => {
  if (!dai_jdt83) return DIRECTIONS_ICONS;

  const { laneParams, fromCamera, toCamera } = dai_jdt83;

  const laneParamsObj: Record<DirectionsTypes, U<LaneParams[]>> = {
    [OnCamera]: toCamera?.laneParams,
    [OutCamera]: fromCamera?.laneParams,
    [Undefined]: laneParams,
  };

  const isDefault = !toCamera && !fromCamera;

  return DIRECTIONS_ICONS.map((el) => {
    const laneParamFormatted = laneParamsObj[el.type];

    const isOnlyOnCamera = isDefault && el.type !== OnCamera;

    if ((!laneParamFormatted && !isDefault) || isOnlyOnCamera) return el;

    return {
      ...el,
      nestedValues: getLanesData(
        isDefault ? laneParams : laneParamFormatted,
        FilteredTypes.Directions
      ),
    };
  });
};

const getCorrectId = (mnemocode: Nullish<string>) => {
  return NORMATIVE_VALUES.find(({ name }) =>
    Array.isArray(name)
      ? name.some((el) => mnemocode?.includes(el))
      : mnemocode?.includes(name)
  )?.id;
};

const getNormativeDocuments = (
  normativeDict: U<NormilizeData['normativeDict']>,
  graphType: GraphTypes,
  graphTitle: ChartBDKeys
) => {
  const chartFields = rootStore.uiStore.dictionaries[ChartFields];

  const fields = chartFields
    .map((el) => ({ ...el, id: getCorrectId(el.mnemocode) }))
    .filter(
      ({ mnemocode, id }) => mnemocode?.includes(graphTitle) && isNumber(id)
    );

  const normativeDocs = fields.reduce((acc: FilterData[], { id, name }) => {
    const disabled = !normativeDict?.some((el) => el.id === id);
    const isShow = id !== NormativeIds.Standard;

    isNumber(id) &&
      isShow &&
      acc.push({
        value: <NormativeIco id={id} />,
        type: id,
        name: name ?? `Нормативный документ ${name}`,
        disabled,
      });

    return acc;
  }, []);

  if (graphType === GraphTypes.CandlesChart) return [];

  return sortByStr(normativeDocs, 'type');
};

const checkDisabledVehicles = (
  normativeData: U<NormilizeData['normativeDict']>
) => {
  if (!normativeData?.length) return false;

  const isDisabled = normativeData.every(
    ({ id }) => id !== NormativeIds.Standard
  );

  return isDisabled;
};

interface FilterInfo {
  activeDetector: Detector;
  normativeDict: U<NormilizeData['normativeDict']>;
  graphType: GraphTypes;
  graphTitle: ChartBDKeys;
}

export const getFilteredInfo = ({
  activeDetector,
  normativeDict,
  graphType,
  graphTitle,
}: FilterInfo) => {
  const { dai_jdt83 } = activeDetector;

  const dirData = getDirData(dai_jdt83);
  const normativeData = getNormativeDocuments(
    normativeDict,
    graphType,
    graphTitle
  );

  const isDisabledVehicles = checkDisabledVehicles(normativeDict);

  const res = FILTERED_INFO.map((filterItem) => {
    let data: FilterData[] = [];

    switch (filterItem.filterType) {
      case FilteredTypes.Directions:
        data = dirData;
        break;
      case FilteredTypes.Lanes:
        break;
      case FilteredTypes.VehicleTypes:
        data = VEHICLES_ICONS.map((el) => ({
          ...el,
          disabled: isDisabledVehicles,
        }));
        break;
      case FilteredTypes.Normative:
        data = normativeData;
        break;
      default:
        neverTypeCheck(filterItem.filterType);
        break;
    }

    return { ...filterItem, data };
  });

  return res;
};
