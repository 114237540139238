import { Feature } from 'ol';
import type { FeatureLike } from 'ol/Feature';
import { z } from 'zod';

import {
  DetectorSchema,
  InfoDtSchema,
} from '../../api/mapObjects/detectors/detectors.zod';
import {
  BasicMapDataSchema,
  DeviceInfoSchema,
  LinkedDeviceIdsSchema,
  controlAccess,
} from '../../api/mapObjects/mapObjects.zod';
import { DEVICE_TYPES_ID, TDevicesIds } from '../../constants/constants';
import {
  DEVICES_STATUS,
  DEVICES_STATUS_NAMES,
  TDeviceStatus,
} from '../../constants/devicesConstants';
import { TObjMap } from '../../stores/mapDataStore/mapDataStore.model';
import { System } from '../enums/enums';

import { TL } from './tl.model';

type TDeviceInfoZod = z.infer<typeof DeviceInfoSchema>;

export interface TDeviceInfo extends TDeviceInfoZod {
  deviceStatusId: TDeviceStatus;
  deviceTypeId: TDevicesIds;
}

export type BasicMapDataZod = z.infer<typeof BasicMapDataSchema>;

export interface LonLat {
  latitude: number;
  longitude: number;
}

export interface IBasicMapData extends BasicMapDataZod, LonLat {
  caption: string;
  isCorrectCoordinate?: boolean;
}

export type DetectorZod = z.infer<typeof DetectorSchema>;

export interface DetectorData extends DetectorZod {
  deviceInfo: TDeviceInfo;
  isShownLanes?: boolean;
}

export type Jdt80Dt = z.infer<typeof InfoDtSchema>;

export type Detector = IBasicMapData & DetectorData;

export type DeviceControlAccess = N<z.infer<typeof controlAccess>>;

export type LinkedDeviceIds = z.infer<typeof LinkedDeviceIdsSchema>;
export type TLinkedDevices = N<LinkedDeviceIds>;

export const isSystem = (system: Nullish<string>): system is System =>
  Object.values(System).some((el) => el === system);

export const isMapObj = (item: object): item is TObjMap =>
  Object.hasOwn(item, 'deviceInfo');

export const isTL = (item: Nullish<object>): item is TL =>
  !!item &&
  isMapObj(item) &&
  DEVICE_TYPES_ID[item.deviceInfo.deviceTypeId] === System.Lights;

export const isDeviceStatus = (
  status: Nullish<number>
): status is TDeviceStatus => DEVICES_STATUS.some((el) => el === status);

export const isDeviceStatusName = (
  statusName: string
): statusName is typeof DEVICES_STATUS_NAMES[number] =>
  DEVICES_STATUS_NAMES.some((el) => el === statusName);

export type FeatureType = Feature | FeatureLike | undefined;

export type DeviceTypeId = 2 | 3 | 4 | 6;
