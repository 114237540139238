import { type DefaultOptionType } from 'antd/es/select';
import { SelectProps } from 'antd/lib';
import { isNumber, isString } from 'lodash';

import { Device } from '../../../../../../api/devices/model/devices.model';
import {
  DEVICE_TYPES_ID,
  DEVICES_TYPES,
  TDevicesIds,
} from '../../../../../../constants/constants';
import { getObjectEntries } from '../../../../../../helpers/getObjectEntries';
import sortByStr from '../../../../../../helpers/sortByStr';
import { isSystem } from '../../../../../../ts/models/mapObject.model';
import { SEPARATE } from '../../../../../Panel/ScriptsControl/SelectModule/DispatchGroupControl/SelectItem/helpers/helpers';
import SelectItem from '../SelectItem/SelectItem';

type SelectionProps = NonNullable<SelectProps['options']>;

type SelectedOptions = Record<TDevicesIds, SelectionProps>;

const getFormattedKey = (
  id: number,
  caption: string,
  deviceTypeId: TDevicesIds
) => {
  return `${id}${SEPARATE}${caption}${SEPARATE}${deviceTypeId}`;
};

const getDefaultSystems = () =>
  DEVICES_TYPES.reduce((acc: SelectedOptions, id) => {
    acc[id] = [];

    return acc;
  }, {} as SelectedOptions);

export const getOptionsSelect = (devices: U<Device[]>) => {
  const defaultSystems = getDefaultSystems();

  if (!devices) return null;

  const res = devices.reduce((acc: SelectedOptions, item) => {
    const {
      device_type_id: deviceTypeId,
      sname,
      id,
      device_status_id: deviceStatusId,
    } = item;

    const typeName = DEVICE_TYPES_ID[deviceTypeId];

    const system = isSystem(typeName) ? typeName : null;

    system &&
      acc[deviceTypeId].push({
        value: getFormattedKey(id, sname, deviceTypeId),
        label: (
          <SelectItem
            key={id}
            system={system}
            id={id}
            caption={sname}
            deviceStatusId={deviceStatusId}
            isIcoMain={false}
          />
        ),
        id: `${id}${SEPARATE}${deviceTypeId}`,
      });

    return acc;
  }, defaultSystems);

  const items = getObjectEntries(res).reduce(
    (acc: SelectionProps, [deviceTypeId, data]) => {
      if (data.length) {
        const typeName = DEVICE_TYPES_ID[deviceTypeId];

        typeName &&
          acc.push({
            label: <span>{typeName}</span>,
            title: typeName,
            options: sortByStr(data, 'id'),
          });
      }

      return acc;
    },
    []
  );

  return items;
};

const getOption = (options: Nullish<DefaultOptionType[]>, activeId: number) => {
  return (
    options?.reduce(
      (acc: N<string>, { id: itemInfo, value }: DefaultOptionType) => {
        if (isString(value) && !acc) {
          const [id] = itemInfo.split(SEPARATE);

          const idNum = Number(id);

          if (idNum === activeId) return value;
        }

        return acc;
      },
      null
    ) ?? null
  );
};

export const getOptionValue = (
  optionsValue: Nullish<DefaultOptionType[]>,
  activeId: U<number>
) => {
  if (!isNumber(activeId) || !optionsValue) return null;

  const isStandardOption = optionsValue.some(({ options }) => !options);

  if (isStandardOption) {
    return getOption(optionsValue, activeId);
  }

  let option: N<string> = null;

  optionsValue.forEach(({ options }) => {
    if (!option) {
      const res = getOption(options, activeId);

      if (res) {
        option = getOption(options, activeId);
      }
    }
  });

  return option;
};
