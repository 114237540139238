import { useQueryClient } from '@tanstack/react-query';
import { FormInstance } from 'antd/lib';
import { useState } from 'react';

import api from '../../../../../../../../api';
import { QUERY_KEY } from '../../../../../../../../constants/queryConstants';
import useValidateForm from '../../../../../../../../hooks/formHooks/useValidateForm';
import rootStore from '../../../../../../../../stores/rootStore/rootStore';
import notification from '../../../../../../../ui-kit/Notification/Notification';
import { AddFormFields } from '../../../../../models/DeviceAdmin.model';
import transformValues from '../helpers/transformValues';

const { createDevice, updateDevice } = api.devices.manage.manageDeviceApi;
const { DEVICE_FORM_INFO, DEVICES_INFO } = QUERY_KEY;

const APP_PATH =
  'Admin.DeviceAdminCard.DeviceFormContent.DeviceAddCard.Buttons.hooks.useButtons';

const useButtons = (form: FormInstance, initialProps: U<AddFormFields>) => {
  const { isEditForm, selectedDevice, setAdminsData } =
    rootStore.adminPanelStore;
  const { regionData } = rootStore.uiStore;

  const [isLoading, setIsLoading] = useState(false);

  const queryClient = useQueryClient();

  const {
    isChangeForm,
    isDisabledConfirm,
    setIsChangeForm,
    handleReset,
    handleClear,
  } = useValidateForm<AddFormFields>({
    form,
    transformValues: (values) => transformValues(values, initialProps),
    initialProps,
    isEdit: isEditForm,
  });

  const handleSubmit = () => {
    form
      .validateFields()
      .then(async (values) => {
        setIsLoading(true);

        const isCreate = !selectedDevice;

        const data = transformValues(values, initialProps);

        const res = isCreate
          ? await createDevice(data, APP_PATH)
          : await updateDevice(selectedDevice.id, data, APP_PATH);

        if (!res) return;

        notification.success(isCreate ? 'ADD_DEVICE' : 'UP_DEVICE');
        form.resetFields();
        setIsChangeForm(false);

        if (!isCreate) {
          queryClient.refetchQueries({
            queryKey: [DEVICES_INFO, regionData?.id, selectedDevice],
            exact: true,
          });
          queryClient.invalidateQueries({ queryKey: [DEVICE_FORM_INFO] });

          return;
        }

        const device = {
          id: res.data.id,
          caption: res.data.sname,
          deviceTypeId: res.data.device_type_id,
        };

        setAdminsData('selectedDevice', device);

        queryClient.refetchQueries({
          queryKey: [DEVICES_INFO, regionData?.id, device],
          exact: true,
        });
      })
      .catch(() => {
        notification.error('ERROR');
      })
      .finally(() => setIsLoading(false));
  };

  const onReset = isEditForm ? handleReset : handleClear;

  return {
    isLoading,
    isDisabledConfirm,
    isChangeForm,
    handleSubmit,
    onReset,
  };
};

export default useButtons;
