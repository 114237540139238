import { QueryClient } from '@tanstack/react-query';

import { Device } from '../../../../../../../api/devices/model/devices.model';
import { QUERY_KEY } from '../../../../../../../constants/queryConstants';
import rootStore from '../../../../../../../stores/rootStore/rootStore';
import { VideoCameraProps } from '../../../../../../VideoCamera/models/VideoCamera.model';
import getIsMapAction from '../../../../../AdminSystemObject/ASOTable/EditDeviceWrapper/EditDeviceForm/helpers/getIsMapAction';

const { DEVICE_FORM_INFO } = QUERY_KEY;

const getCameraData = (queryClient: QueryClient): N<VideoCameraProps> => {
  const { selectedDevice } = rootStore.adminPanelStore;

  const camera = queryClient.getQueryData<{ data: Nullish<Device> }>([
    DEVICE_FORM_INFO,
    selectedDevice,
  ]);

  const cameraInfo = camera?.data;

  if (!cameraInfo) return null;

  const { sname, dsc, longitude, latitude, id } = cameraInfo;

  const isMapAction = getIsMapAction(longitude, latitude);

  return {
    dsc: dsc ?? 'Описание отсутствует',
    caption: sname,
    deviceId: id,
    longitude,
    latitude,
    isMapAction,
  };
};

export default getCameraData;
