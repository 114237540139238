import { useEffect } from 'react';

import { findBy } from '../../../../../../helpers/findBy';
import rootStore from '../../../../../../stores/rootStore/rootStore';
import { FilteredTypes } from '../../../../../TrafficLightDetailed/TlDetectors/constants/constants';
import { FilterInfo } from '../../../models/chart.model';

interface UseFilterButtonsProps {
  filterInfo: FilterInfo[];
}

const useFilterButtons = ({ filterInfo }: UseFilterButtonsProps) => {
  const { filterDtData, setFilteredDtData } = rootStore.detailedStore;

  //change filter type vehicle if disabled
  useEffect(() => {
    const vehicleData = findBy(
      filterInfo,
      FilteredTypes.VehicleTypes,
      'filterType'
    )?.data;

    const isNeedChange = vehicleData?.every(({ disabled }) => disabled);
    const isVehicleNotEmpty = !!filterDtData[FilteredTypes.VehicleTypes];

    isNeedChange &&
      isVehicleNotEmpty &&
      setFilteredDtData({
        filteredType: FilteredTypes.VehicleTypes,
        value: null,
      });
  }, [filterDtData, filterInfo, setFilteredDtData]);

  return null;
};

export default useFilterButtons;
