import { AiOutlineVideoCamera } from 'react-icons/ai';
import {
  RiCompasses2Line,
  RiAdminLine,
  RiRemoteControlLine,
} from 'react-icons/ri';
import { VscDebugAlt } from 'react-icons/vsc';

import { ReactComponent as Subsystems } from '../../../../assets/icons/cartography_tabs/subsystems.svg';
import { ReactComponent as Cartography } from '../../../../assets/icons/ic_dashboard.svg';
import { ReactComponent as Reports } from '../../../../assets/icons/ic_docs.svg';
import { ManagementModules } from '../../../../stores/uiStore/uiStore.model';
import { PanelType } from '../../../../ts/enums/enums';
import { SidebarCode } from '../../../../ts/enums/userData';

export const PANEL_TITLES = {
  [PanelType.ModulesSubsystems]: 'Модули и подсистемы',
  [PanelType.Cartography]: 'Картография',
  [PanelType.VideoWall]: 'Видеонаблюдение',
  [PanelType.Reports]: 'Отчеты',
  [PanelType.Constructor]: 'Конструктор',
  [PanelType.Admins]: 'Панель администратора',
  [PanelType.Testing]: 'Тестирование',
  profile: 'Профиль пользователя',
  [PanelType.CoordManagement]: 'Диспетчерское управление',
  [ManagementModules.CoordControl]: 'Координированное управление',
  [ManagementModules.GroupDispatchManagement]: 'Групповое ДУ',
  [ManagementModules.CoordinatedTraffic]: 'Координированное управление',
};

export const MAIN_BTNS = [
  {
    name: PanelType.ModulesSubsystems,
    code: SidebarCode.ModulesSubsystems,
    className: 'reactIco',
    Component: Subsystems,
  },
  {
    name: PanelType.Cartography,
    code: SidebarCode.Cartography,
    className: 'ico',
    Component: Cartography,
  },
  {
    name: PanelType.CoordManagement,
    code: SidebarCode.CoordControl,
    additionalCode: SidebarCode.GroupDispatchControl,
    className: 'reactIco',
    Component: RiRemoteControlLine,
  },
  {
    name: PanelType.VideoWall,
    code: SidebarCode.Video,
    className: 'reactIco',
    Component: AiOutlineVideoCamera,
  },
  {
    name: PanelType.Reports,
    code: SidebarCode.Reports,
    className: 'ico',
    Component: Reports,
  },
  {
    name: PanelType.Constructor,
    code: SidebarCode.Constructor,
    className: 'reactIco',
    Component: RiCompasses2Line,
  },
  {
    name: PanelType.Admins,
    code: SidebarCode.Admin,
    className: 'reactIco',
    Component: RiAdminLine,
  },
  {
    name: PanelType.Testing,
    code: SidebarCode.Testing,
    className: 'reactIco',
    Component: VscDebugAlt,
  },
];
