import { useMutation, useQueryClient } from '@tanstack/react-query';
import { App } from 'antd';
import { MouseEvent } from 'react';

import api from '../../../../../../../../api';
import { QUERY_KEY } from '../../../../../../../../constants/queryConstants';
import { SourceDisplayType } from '../../../../../../../../stores/adminPanelStore/constants/constants';
import rootStore from '../../../../../../../../stores/rootStore/rootStore';
import notification from '../../../../../../../ui-kit/Notification/Notification';
import getDeviceInfo from '../../../../../DeviceAdminCard/DeviceFormContent/DeviceEditCard/helpers/getDeviceInfo';

const TITLE = 'Вы точно хотите удалить источник?';
const APP_PATH =
  'Admin.DeviceAdmin.SourceEdit.SourceContainer.SourceContent.SourceButtons.hooks.useSourceButtons';

const { deleteSource } = api.devices.externalLinks.externalLinksApi;
const { EXTERNAL_LINKS_INFO } = QUERY_KEY;
const { SourceEditForm } = SourceDisplayType;

const useSourceButtons = (extSourceId: number, name: N<string>) => {
  const { selectedDevice, setAdminsData } = rootStore.adminPanelStore;

  const { modal } = App.useApp();
  const queryClient = useQueryClient();

  const handleEditSource = (e: U<MouseEvent<HTMLElement>>) => {
    e?.stopPropagation();

    setAdminsData('selectedSource', {
      id: extSourceId,
      formType: SourceEditForm,
    });
  };

  const { mutateAsync } = useMutation({
    mutationFn: async () => {
      if (!selectedDevice) return;

      const { id } = selectedDevice;

      const res = await deleteSource(id, extSourceId, APP_PATH);

      if (!res) {
        throw new Error(`Ошибка удаления источника для ${id} устройства`);
      }
    },
    onSuccess: () => {
      notification.success('EXTERNAL_SOURCE_DELETE');
      queryClient.invalidateQueries({ queryKey: [EXTERNAL_LINKS_INFO] });
    },
    onError: (e) => {
      console.error(e.message);
    },
  });

  const handleDeleteSource = (e: U<MouseEvent<HTMLElement>>) => {
    e?.stopPropagation();

    if (!selectedDevice) return;

    const { id } = selectedDevice;

    const caption = getDeviceInfo(queryClient, id, selectedDevice)?.sname;

    const onOk = async () => {
      try {
        await mutateAsync();
      } catch (error) {
        console.error(error);

        return;
      }
    };

    modal.confirm({
      title: TITLE,
      content: `Источник ${extSourceId} | ${name} для устройства ${id} | ${caption} будет удален!`,
      onOk,
      okText: 'Удалить',
      okButtonProps: { danger: true },
      centered: true,
    });
  };

  return { handleEditSource, handleDeleteSource };
};

export default useSourceButtons;
