import { FC } from 'react';
import { Rectangle, Text } from 'recharts';

import { Y_OFFSET } from './constants/constants';
import { TactBlockProps } from './TrafficDetailed.model';

const HEIGHT = 18;

const TactBlock: FC<TactBlockProps> = ({
  x1,
  fill,
  x2,
  y: yProp,
  shift = 0,
  ...props
}) => {
  const xAxis = props.xAxisMap[0];
  const yAxis = props.yAxisMap[0];

  const xMin = xAxis?.domain[0];
  const xMax = xAxis?.domain[1];

  const xShift = shift >= 0 ? shift : 0;

  const xRate = xAxis?.width / (xMax - xMin);
  const yRate = yAxis.height / (yAxis.domain[1] + Y_OFFSET);

  const x = xAxis.x + (x1 + xShift) * xRate;
  const y = yAxis.y + (yProp + Y_OFFSET - HEIGHT / 2) * yRate;

  const width = (x2 - x1) * xRate;
  const height = HEIGHT * yRate;

  const xCenter = x + width / 2;
  const yCenter = y + height / 2;

  const rectProps = { x, y, width, height, fill };

  return (
    <>
      <Rectangle {...rectProps} />
      <Text x={xCenter} y={yCenter} textAnchor="middle" verticalAnchor="middle">
        {`${x2 - x1}c`}
      </Text>
    </>
  );
};

export default TactBlock;
