import { TlsData, TlsItem } from '../TrafficDetailed.model';

export const getXDomain = (tls: TlsItem[], offset: number) =>
  tls.reduce(
    (acc: [number, number], { directions }) => {
      Object.values(directions).forEach((tacts) => {
        if (!tacts) return;

        const minVal = tacts[0].start + offset;
        const maxVal = (tacts.at(-1)?.stop ?? 0) + offset;

        if (minVal < acc[0]) acc[0] = minVal;
        if (maxVal > acc[1]) acc[1] = maxVal;
      });

      return acc;
    },
    [0, 0]
  );

const getTlsData = (tls: TlsItem[], offset: number, maxX: number) =>
  tls.reduce(
    (acc: TlsData, { device_id, directions, distance }) => {
      acc.tlsData.push({ tlId: device_id, x: maxX, y: distance });
      acc.distances.push(distance);

      Object.entries(directions).forEach(([key, values]) => {
        const value = values?.[0];

        value &&
          acc.tlsData.push({
            dirNum: key,
            x: value.start + offset,
            y: value.distance,
          });
      });

      return acc;
    },
    { tlsData: [], distances: [] }
  );

export default getTlsData;
