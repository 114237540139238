import type { PickingInfo } from '@deck.gl/core';
import { ArcLayer, ColumnLayer, TextLayer } from '@deck.gl/layers';
import {
  booleanContains,
  point as turfPoint,
  polygon as turfPolygon,
  toMercator,
  toWgs84,
} from '@turf/turf';
import dayjs from 'dayjs';
import { Feature } from 'ol';
import { LineString, Point, Polygon } from 'ol/geom';

import graphApi from '../../../api/integration/gis/graph';
import { queryFeatures } from '../../../apiGIS/sources/FeatureServerSource';

import {
  DEFAULT_BBOX,
  DEFAULT_WHERE,
  DIRECTION_ALL,
  DIRECTION_FROM,
  DIRECTION_TO,
  LINK_MAX_WIDTH,
  LINK_MIN_WIDTH,
  MAX_NAME_CHARS_LENGTH,
  MAX_RESULTS_FROM_SERVICE,
  MIN_TILT,
  NODE_MERGE_OPTION_ATTRIBUTE,
  NODE_MERGE_OPTION_LAYER,
  POLYGONS_TEST_SERVICE_URL,
  START_NODE_COLOR,
  TARGET_NODE_COLOR,
  TEXT_LAYER_FONT_COLOR,
  TEXT_LAYER_LABEL_SIZE,
  TEXT_LAYER_OFFSET_LABEL,
  TEXT_LAYER_PADDING,
  TRAFFIC_LAYER_LINK,
  TRAFFIC_LAYER_VERTEX,
  TRAFFIC_LAYER_VERTEX_SELECTED,
  TRAFFIC_LAYER_VERTEX_TEXT,
  VERTEX_COLOR,
  VERTEX_COLOR_SELECTED,
  VERTEX_EDGES,
  VERTEX_RADIUS,
  VERTEX_RADIUS_SELECTED,
} from './constants';
import {
  IGraphDataSet,
  ILink,
  IODDataset,
  IODDatasetExtended,
  IODMatrixItem,
  IPointUds,
  IVertex,
  LinkSegmentData,
  VertexData,
} from './trafficGraph.model';

export const getPointFromVertexItem = (item: IPointUds): Point => {
  const [lon, lat] = item.centerPoint;

  const { coordinates } = toMercator(turfPoint([lon, lat])).geometry;

  return new Point(coordinates);
};

export const getVertexesItems = async (
  items: IPointUds[],
  mergeMethod: string
): Promise<IVertex[]> => {
  const filteredItems = items.filter((item) => item.centerPoint);

  if (mergeMethod === NODE_MERGE_OPTION_ATTRIBUTE) {
    const features = filteredItems
      .map((item) => {
        const geometry = getPointFromVertexItem(item);

        return new Feature({
          id: item.uid,
          nodes: [item.uid],
          ...item,
          name: item.name,
          geometry,
        });
      })
      .filter((item) => !!item);

    return features
      .filter((feature) => !!feature)
      .map((feature, index: number) => {
        const vertex: IVertex = {
          id: feature.get('id'),
          name: feature?.get('name') || index.toString(),
          nodes: [],
          feature,
        };

        return vertex;
      });
  }

  if (mergeMethod === NODE_MERGE_OPTION_LAYER) {
    const layer = { url: POLYGONS_TEST_SERVICE_URL };
    const where = DEFAULT_WHERE;
    const num = MAX_RESULTS_FROM_SERVICE;

    const params = {
      bbox: '',
      url: layer.url,
      where,
      num,
    };

    params.bbox = DEFAULT_BBOX;

    const { features: polygons } = await queryFeatures(params);

    const features = polygons
      .filter((item) => !!item)
      .map((feature: Feature) => {
        const geometry = feature.getGeometry();

        if (geometry && geometry instanceof Polygon) {
          const coordinates = geometry.getCoordinates();

          // @ts-ignore
          const center = geometry.getExtent()
            ? geometry.getInteriorPoint()
            : null;

          if (!center) {
            return undefined;
          }

          const polygon = turfPolygon(coordinates);

          const nodes = filteredItems.filter((item) => {
            try {
              const [lon, lat] = item.centerPoint;

              if (lat && lon) {
                const point = toMercator(turfPoint([lon, lat]));

                return booleanContains(polygon, point);
              }
            } catch (e) {
              return false;
            }

            return false;
          });

          const id = nodes.map((item) => item.uid).join(',');

          return new Feature({
            id,
            name: feature.get('name'),
            nodes,
            geometry: center,
          });
        }

        return undefined;
      })
      .filter((item) => !!item);

    const results = features
      .filter((feature) => {
        return feature instanceof Feature;
      })
      .map((feature: Feature | undefined, index: number) => {
        const nodes = feature?.getProperties()['nodes'] || [];

        const vertex: IVertex = {
          id: feature?.getProperties()['id'] || index.toString(),
          name: feature?.getProperties()['name'] || index.toString(),
          nodes,
          feature,
          isAggregated: true,
        };

        return vertex;
      });

    return results;
  }

  return [];
};

export const getLinksItems = async (
  vertexes: IPointUds[],
  links: IODMatrixItem[]
): Promise<ILink[]> => {
  // @ts-ignore
  return links
    .map((link, i) => {
      const { from: id1, to: id2, count: intensity } = link;

      const vertex1 = vertexes.find((vertex) => vertex.uid === id1);
      const vertex2 = vertexes.find((vertex) => vertex.uid === id2);

      if (!vertex1 || !vertex2) {
        return null;
      }

      const coordinates1 = vertex1.centerPoint;
      const coordinates2 = vertex2.centerPoint;

      const geometry = new LineString([coordinates1, coordinates2]);

      const result: ILink = {
        id1,
        id2,
        geometry,
        intensity,
      };

      return result;
    })
    .filter((item) => !!item);
};

export const getODDataset = async (
  dateStart: string,
  dateEnd: string,
  regionId: string | number
): Promise<{ vertexes: IPointUds[]; links: IODMatrixItem[] }> => {
  try {
    if (!dateStart || !dateEnd) {
      return {
        vertexes: [],
        links: [],
      };
    }

    const data: IODDataset = await graphApi.getODDataset(
      'gis',
      regionId,
      dateStart,
      dateEnd
    );

    const { ODMatrix, pointsUDS } = data;

    return {
      vertexes: pointsUDS,
      links: ODMatrix,
    };
  } catch (e) {
    return {
      vertexes: [] as IPointUds[],
      links: [] as IODMatrixItem[],
    };
  }
};

export const getDataset = async (
  regionId: number,
  dateStart: string,
  dateEnd: string
): Promise<IODDatasetExtended> => {
  const dateStartView = dateStart.toString();
  const dateEndView = dateEnd.toString();

  const { vertexes, links } = await getODDataset(
    dateStartView,
    dateEndView,
    regionId
  );

  const key = [dateStart, dateEnd, 'OD_MATRIX'].join('-');

  return {
    key,
    vertexes,
    links,
  };
};

export const getDate = (date?: unknown) => {
  if (!date) {
    const value = new Date();

    value.setHours(0);
    value.setMinutes(0);
    value.setSeconds(0);

    return dayjs(value);
  }

  return date;
};

export interface IDateRange {
  dateStart: string | undefined;
  dateEnd: string | undefined;
}

export const getDateStart = (date: string | undefined) => {
  return date ? date + ':00' : '';
};

export const getDateEnd = (date: string | undefined) => {
  return date ? date + ':00' : '';
};

export const linkLayer = (data: LinkSegmentData[]) => {
  return new ArcLayer<LinkSegmentData>({
    id: TRAFFIC_LAYER_LINK,
    data,
    getSourcePosition: (d: LinkSegmentData) => d.from.coordinates,
    getTargetPosition: (d: LinkSegmentData) => d.to.coordinates,
    getSourceColor: START_NODE_COLOR,
    getTargetColor: TARGET_NODE_COLOR,
    getWidth: (d: LinkSegmentData) => d.width,
    getTilt: () => {
      return -MIN_TILT + 2 * MIN_TILT * Math.random();
    },
    widthMinPixels: LINK_MIN_WIDTH,
    widthMaxPixels: LINK_MAX_WIDTH,
    pickable: true,
  });
};

export const selectedVertexLayer = (
  data: VertexData[],
  selectedNode: IVertex
) => {
  const filteredData = data.filter((item) => item.id === selectedNode.id);

  return new ColumnLayer({
    id: TRAFFIC_LAYER_VERTEX_SELECTED,
    data: filteredData,
    getFillColor: VERTEX_COLOR_SELECTED,
    getPosition: (d: VertexData) =>
      d.coordinates ? [d.coordinates[0], d.coordinates[1]] : [0, 0],
    diskResolution: VERTEX_EDGES,
    extruded: true,
    radius: VERTEX_RADIUS_SELECTED,
    getElevation: VERTEX_RADIUS * 2,
    pickable: true,
  });
};

export const vertexLayer = (data: VertexData[]) => {
  return new ColumnLayer({
    id: TRAFFIC_LAYER_VERTEX,
    data,
    getFillColor: VERTEX_COLOR,
    getPosition: (d: VertexData) =>
      d.coordinates ? [d.coordinates[0], d.coordinates[1]] : [0, 0],
    diskResolution: VERTEX_EDGES,
    extruded: true,
    radius: VERTEX_RADIUS,
    getElevation: VERTEX_RADIUS,
    pickable: true,
  });
};

export const vertexTextLayer = (data: VertexData[]) => {
  return new TextLayer({
    id: TRAFFIC_LAYER_VERTEX_TEXT,
    data,
    getColor: TEXT_LAYER_FONT_COLOR,
    background: true,
    backgroundPadding: TEXT_LAYER_PADDING,
    getText: (d: VertexData) => {
      const { id, name } = d;
      // @ts-ignore
      const idView = name ? name : String(id);

      const len = idView.length;

      return len >= MAX_NAME_CHARS_LENGTH
        ? idView.slice(0, MAX_NAME_CHARS_LENGTH) + '...'
        : idView;
    },
    getPosition: (d: VertexData) =>
      d.coordinates ? [d.coordinates[0], d.coordinates[1]] : [0, 0],
    getAlignmentBaseline: 'bottom',
    getSize: TEXT_LAYER_LABEL_SIZE,
    sizeMaxPixels: TEXT_LAYER_LABEL_SIZE,
    getTextAnchor: 'start',
    getPixelOffset: TEXT_LAYER_OFFSET_LABEL,
    fontFamily: 'arial',
    pickable: false,
    wordBreak: 'break-all',
    maxWidth: MAX_NAME_CHARS_LENGTH,
  });
};

const getLinkItem = (link: ILink, reverse: boolean) => {
  const { id1, id2, geometry } = link;

  const coordinates = geometry.getCoordinates();
  const c1 = coordinates[0];
  const c2 = coordinates[coordinates.length - 1];

  const count = link.intensity || 0;
  const width = count / 100;

  const item: LinkSegmentData = {
    id1: reverse ? id2 : id1,
    id2: reverse ? id1 : id2,
    count,
    width,
    to: {
      coordinates: [c1[0], c1[1]],
      name: '',
    },
    from: {
      coordinates: [c2[0], c2[1]],
      name: '',
    },
  };

  return item;
};

export const getLinkFeatures = (
  dataset: IGraphDataSet | null,
  selectedNode?: N<IVertex>,
  direction?: number
) => {
  const links = dataset?.links || [];

  if (!selectedNode) {
    return links.map((link: ILink) => {
      return getLinkItem(link, false);
    });
  }

  const { id } = selectedNode;

  if (direction === DIRECTION_FROM) {
    return links
      .filter((link: ILink) => {
        const { id1 } = link;

        return id1 === id;
      })
      .map((link) => getLinkItem(link, false));
  }

  if (direction === DIRECTION_TO) {
    return links
      .filter((link: ILink) => {
        const { id2 } = link;

        return id2 === id;
      })
      .map((link) => getLinkItem(link, true));
  }

  if (direction === DIRECTION_ALL) {
    const filteredLinks = links.filter(
      (link) => link.id1 === id || link.id2 === id
    );

    const idsSet = new Set();

    filteredLinks.forEach((link) => {
      idsSet.add(link.id1);
      idsSet.add(link.id2);
    });

    idsSet.delete(id);

    const ids = [...idsSet];

    const results = [];

    for (let i = 0; i < ids.length; i++) {
      const id2 = ids[i] as string;

      const connectedLinks = filteredLinks.filter((link) => {
        return link.id1 === id2 || link.id2 === id2;
      });

      const count = connectedLinks.reduce((sum: number, currentItem: ILink) => {
        return sum + currentItem.intensity;
      }, 0);

      const width = count / 100;

      const { geometry } = connectedLinks[0];

      const coordinates = geometry.getCoordinates();
      const c1 = coordinates[0];
      const c2 = coordinates[coordinates.length - 1];

      const link: LinkSegmentData = {
        id1: id,
        id2,
        count,
        to: {
          coordinates: [c1[0], c1[1]],
          name: '',
        },
        from: {
          coordinates: [c2[0], c2[1]],
          name: '',
        },
        width,
      };

      results.push(link);
    }

    return results;
  }

  return [];
};

export const getVertexesFeatures = (dataset: IGraphDataSet | null) => {
  return (dataset?.vertexes || []).map((vertex: IVertex, index) => {
    const { feature } = vertex;

    const featureName = feature?.getProperties()['name'];

    const name = `${featureName}`
      .replace('СО № ', 'CO N')
      .replace('Точка с ', 'Point N')
      .replace('ДТ', 'Dt')
      .replace('камер', 'camera');

    if (!feature) {
      return {
        id: index,
        coordinates: [0, 0],
        name,
      };
    }

    // @ts-ignore
    const coords = feature.getGeometry().getCoordinates() || [0, 0];

    const point = toWgs84(turfPoint(coords)).geometry.coordinates;

    const item: VertexData = {
      id: vertex.id,
      name,
      coordinates: [point[0], point[1]],
    };

    return item;
  });
};

export const getSelectedNodeName = (selectedNode: IVertex) => {
  const { name } = selectedNode;

  const nameView = name.toString().split('_').join(',');
  const len = name.length;

  return len >= MAX_NAME_CHARS_LENGTH
    ? `${nameView.slice(0, MAX_NAME_CHARS_LENGTH)}...`
    : `${nameView}`;
};

export const getTooltip = ({ object, layer }: PickingInfo) => {
  if (!object || !layer) {
    return null;
  }

  if (layer.id === TRAFFIC_LAYER_VERTEX) {
    return `Узел {${object.name || object.id}}`;
  }

  if (layer.id === TRAFFIC_LAYER_LINK) {
    return `${object.count} проездов`;
  }

  return null;
};

export const getNodeIdView = (
  item: LinkSegmentData,
  vertexes: VertexData[]
) => {
  const { id2 } = item;

  const vertex = vertexes.find((element) => element.id === id2);

  if (vertex) {
    return vertex.name;
  }

  const len = id2.toString().length;
  const maxLen = 30;

  return len >= maxLen
    ? id2.toString().slice(0, maxLen) + '...'
    : id2.toString();
};
