import { AxiosError } from 'axios';
import { isNumber, isString } from 'lodash';
import { useEffect, useState } from 'react';

import api from '../../../../api';
import { VideoSourceItem } from '../../models/VideoCamera.model';

const { getVideoSourcesByDeviceId, getVideoSourcesByExtDeviceId } =
  api.mapObjects.cameras.camerasApi;

const APP_PATH = 'VideoCamera.VideoCameraWithPreloader.hooks.useVideoSources';

const useVideoSources = (deviceId: Nullish<SN>, systemId?: number) => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<N<string>>(null);

  const [videoSources, setVideoSources] = useState<VideoSourceItem[]>([]);

  const fetchVideoSources = async () => {
    try {
      setIsLoading(true);

      if (!deviceId)
        throw new Error(
          'Ошибка: невозможно получить список потоков камеры, т.к. не найден идентификатор устройства камеры'
        );

      if (isString(deviceId) && !isNumber(systemId))
        throw new Error('Ошибка: у камеры отсутствует система');

      const isExtDevice = isString(deviceId) && isNumber(systemId);

      const videoSources = await (isExtDevice
        ? getVideoSourcesByExtDeviceId({
            extId: deviceId,
            systemId,
            appPath: APP_PATH,
          })
        : getVideoSourcesByDeviceId(Number(deviceId)));

      if (!videoSources)
        throw new Error('Ошибка: не удалось получить список потоков камеры');

      if (!videoSources.length)
        throw new Error('Ошибка: Список потоков камеры пуст');

      setVideoSources(videoSources);
      setError(null);
    } catch (e: any) {
      let errorMes: string = e.message;

      if (e instanceof AxiosError) {
        errorMes = e?.response?.data?.message || errorMes;
      }

      setError(errorMes);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchVideoSources();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceId, systemId]);

  return { isLoading, error, videoSources };
};

export default useVideoSources;
