export const CELLS_WIDTH = {
  xs: 150,
  s: 200,
};

export const DEFAULT_SCROLL = {
  X: 1350,
  Y: 500,
};

export const DEFAULT_PAGES = 10;
