import { isString } from 'lodash';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useRef, useState } from 'react';

import api from '../../../api';
import { getObjectEntries } from '../../../helpers/getObjectEntries';
import rootStore from '../../../stores/rootStore/rootStore';
import {
  IQueryParamRequest,
  TableData,
  TableKeys,
} from '../../../ts/models/table.model';
import { APP_PATH_REPORT } from '../../Panel/ReportsPanel/ReportTree/ReportTree';
import { DEFAULT_TABLE } from '../../ui-kit/Table/constants';
import Table from '../../ui-kit/Table/Table';

import styles from './ReportTable.module.scss';

const { Reports } = TableKeys;

const ReportTable = () => {
  const { activeKey, activeReportInfo } = rootStore.reportsPanelStore;

  const [tableData, setTableData] = useState<TableData>(DEFAULT_TABLE);
  const [isLoading, setIsLoading] = useState(false);

  const controller = useRef<AbortController>();

  const fetchReportByKey = useCallback(
    async (queryParamsReq?: IQueryParamRequest) => {
      setTableData(DEFAULT_TABLE);

      setIsLoading(true);

      if (!activeKey || !activeReportInfo) return setIsLoading(false);

      const params = getObjectEntries(queryParamsReq ?? {}).reduce(
        (acc: OWKS<unknown>, [key, value]) => {
          const correctValue = isString(value) ? value.trim() || null : value;

          return { ...acc, [key]: correctValue };
        },
        {}
      );

      const res = await api.reports.fetchReportByKey(
        activeKey,
        APP_PATH_REPORT,
        activeReportInfo.params,
        params,
        controller.current?.signal
      );

      if (!res) return setIsLoading(false);

      setTableData(res);
      setIsLoading(false);
    },
    [activeKey, activeReportInfo]
  );

  useEffect(() => {
    controller.current = new AbortController();

    fetchReportByKey();

    return () => {
      controller.current?.abort();
    };
  }, [fetchReportByKey]);

  return (
    <div className={styles.container}>
      <Table
        key={activeKey}
        tableData={tableData}
        tableConfigType={Reports}
        refreshData={fetchReportByKey}
        isLoading={isLoading}
        exportBtnTitle={activeReportInfo?.label}
      />
    </div>
  );
};

export default observer(ReportTable);
