import { Map } from 'ol';
import { GeoJSON } from 'ol/format';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { Fill, Stroke, Style } from 'ol/style';

import { ILayer } from '../../../stores/gisDataStore/gisDataStore.model';
import { TMap } from '../../../stores/mapStore/mapStore.model';
import { BLUR_FILTER } from '../../constants/basemaps/utils';
import { COLOR_DRAPING_LAYER } from '../../constants/colors';
import {
  DRAPING_LAYER_ID,
  DRAPING_LAYER_OPACITY,
  Z_INDEX_DRAPING_LAYER,
} from '../../constants/map';
import { gisHttp } from '../../gisHttp';
import { addFilter, restoreDefaultFilter } from '../basemaps';

export const createDrapingLayer = (map: Map, definitions: ILayer[]) => {
  if (!map) {
    return;
  }

  const drapingLayerDefinition = definitions.find(
    (item) => item.id === DRAPING_LAYER_ID
  );

  if (!drapingLayerDefinition) {
    return;
  }

  const { url } = drapingLayerDefinition;

  const queryUrl = url + '/query?f=geojson';

  gisHttp(queryUrl)
    .then((response) => {
      const { data } = response;

      const format = new GeoJSON();

      const features = format.readFeatures(data);

      const source: VectorSource<any> = new VectorSource({
        features,
      });

      const layer = new VectorLayer({
        source,
        style: new Style({
          fill: new Fill({
            color: COLOR_DRAPING_LAYER,
          }),
          stroke: new Stroke({
            width: 1,
            color: COLOR_DRAPING_LAYER,
          }),
        }),
      });

      map.addLayer(layer);

      layer.set('id', DRAPING_LAYER_ID);
      layer.setZIndex(Z_INDEX_DRAPING_LAYER);
      layer.setOpacity(DRAPING_LAYER_OPACITY);

      restoreDefaultFilter(layer);

      addFilter(layer, BLUR_FILTER);
    })
    .catch((err) => {
      console.error(err);
    });
};

export const getDrapingLayer = (map: TMap) => {
  if (!map) {
    return undefined;
  }

  return map
    .getAllLayers()
    .find((element) => element.get('id') === DRAPING_LAYER_ID);
};
