import { Dayjs } from 'dayjs';

import { ExternalLinkUpdateProps } from '../../../../../../../api/devices/model/devices.model';
import { DATE_FORMAT } from '../../../../../../../constants/constants';
import { SEPARATE } from '../../../../../../Panel/ScriptsControl/SelectModule/DispatchGroupControl/SelectItem/helpers/helpers';
import {
  ExternalProps,
  SourceFormFields,
} from '../../../../models/DeviceAdmin.model';

const preparedDate = (date: Nullish<Dayjs>) =>
  date?.format(DATE_FORMAT) ?? null;

const preparedEditData = (
  values: SourceFormFields,
  initialProps: U<SourceFormFields>
): N<ExternalLinkUpdateProps> => {
  const { sname, dsc, lifetime_from, lifetime_to, status_id } = values;

  const name = sname ?? initialProps?.sname;

  const lifetimeFrom = preparedDate(lifetime_from);

  if (!lifetimeFrom || !name || !status_id) return null;

  return {
    sname: name,
    dsc: dsc || null,
    status_id,
    lifetime_from: lifetimeFrom,
    lifetime_to: preparedDate(lifetime_to),
  };
};

const preparedDataToConfirm = (
  values: U<SourceFormFields>,
  isEdit: boolean,
  initialProps: U<SourceFormFields>
): N<ExternalProps> => {
  if (!values) return null;

  if (isEdit) return preparedEditData(values, initialProps);

  const { dsc, lifetime_from, lifetime_to, status_id, systemId, deviceInfo } =
    values;

  const lifetimeFrom = preparedDate(lifetime_from);

  if (!deviceInfo || !lifetimeFrom || !dsc || !status_id) return null;

  const [sc_system_id] = systemId?.split(SEPARATE) ?? [];
  const [deviceId, sname] = deviceInfo?.split(SEPARATE) ?? [];

  return {
    sname: sname,
    dsc,
    jexternaldevice_id: {
      xeomaChainId: deviceId,
    },
    sc_system_id: Number(sc_system_id),
    lifetime_from: lifetimeFrom,
    lifetime_to: preparedDate(lifetime_to),
    status_id,
  };
};

export default preparedDataToConfirm;
