import type { PickingInfo } from '@deck.gl/core';
import { List } from 'antd';
import React, { useEffect, useState } from 'react';

import { TMap } from '../../../stores/mapStore/mapStore.model';
import Scene from '../../Scene/Scene';

import {
  DIRECTION_ALL,
  DIRECTION_FROM,
  DIRECTION_TO,
  DEFAULT_PAGE_SETTINGS,
  DEFAULT_SCENE_ZOOM,
  TRAFFIC_LAYER_VERTEX,
  DIRECTION_ALL_TITLE,
  DIRECTION_FROM_TITLE,
  DIRECTION_TO_TITLE,
} from './constants';
import {
  getLinkFeatures,
  getNodeIdView,
  getSelectedNodeName,
  getTooltip,
  getVertexesFeatures,
  linkLayer,
  selectedVertexLayer,
  vertexLayer,
  vertexTextLayer,
} from './helpers';
import {
  IGraphDataSet,
  IVertex,
  LinkSegmentData,
  VertexData,
} from './trafficGraph.model';

import styles from './TrafficGraphButton.module.scss';

const GraphVisualizer = (props: {
  dataset: IGraphDataSet | null;
  map: TMap;
}) => {
  const { dataset } = props;

  const [layerData, setLayerData] = useState<{
    links: LinkSegmentData[];
    vertexes: VertexData[];
  }>({
    links: [],
    vertexes: [],
  });

  const [direction, setDirection] = useState<number>(DIRECTION_ALL);

  const [selectedNode, setSelectedNode] = useState<IVertex | null>(null);

  const layers = selectedNode
    ? [
        selectedVertexLayer(layerData.vertexes, selectedNode),
        vertexLayer(layerData.vertexes),
        linkLayer(layerData.links),
        vertexTextLayer(layerData.vertexes),
      ]
    : [
        vertexLayer(layerData.vertexes),
        linkLayer(layerData.links),
        vertexTextLayer(layerData.vertexes),
      ];

  useEffect(() => {
    const vertexesFeatures: VertexData[] = getVertexesFeatures(dataset);

    const linkFeatures: LinkSegmentData[] = getLinkFeatures(
      dataset,
      selectedNode,
      direction
    );

    setLayerData({ links: linkFeatures, vertexes: vertexesFeatures });
  }, [dataset, selectedNode, direction]);

  if (!dataset) {
    return null;
  }

  const onFeatureClick = (info: PickingInfo) => {
    if (info?.object && info?.layer?.id === TRAFFIC_LAYER_VERTEX) {
      const item: IVertex = {
        id: info?.object.id,
        name: info?.object.name,
        nodes: [],
      };

      setSelectedNode(item);
    } else {
      setSelectedNode(null);
    }
  };

  const setDirectionsAll = () => {
    setDirection(DIRECTION_ALL);
  };

  const setDirectionsFrom = () => {
    setDirection(DIRECTION_FROM);
  };

  const setDirectionsTo = () => {
    setDirection(DIRECTION_TO);
  };

  const getTitle = () => {
    if (direction === DIRECTION_ALL) {
      return DIRECTION_ALL_TITLE;
    }

    if (direction === DIRECTION_FROM) {
      return DIRECTION_FROM_TITLE;
    }

    if (direction === DIRECTION_TO) {
      return DIRECTION_TO_TITLE;
    }
  };

  return (
    <div>
      {selectedNode ? (
        <div className={styles.infoContainer}>
          <div className={styles.title}>Выбранный узел</div>
          <div>{getSelectedNodeName(selectedNode)}</div>

          <div className={styles.buttonsBlock}>
            <div
              className={
                direction === DIRECTION_ALL
                  ? `${styles.dirBtnActive} ${styles.dirBtnLeft}`
                  : `${styles.dirBtn} ${styles.dirBtnLeft}`
              }
              role={'presentation'}
              onClick={setDirectionsAll}
            >
              В обе стороны
            </div>
            <div
              className={
                direction === DIRECTION_FROM
                  ? `${styles.dirBtnActive}`
                  : `${styles.dirBtn}`
              }
              role={'presentation'}
              onClick={setDirectionsFrom}
            >
              От узла
            </div>
            <div
              className={
                direction === DIRECTION_TO
                  ? `${styles.dirBtnActive} ${styles.dirBtnRight}`
                  : `${styles.dirBtn} ${styles.dirBtnRight}`
              }
              role={'presentation'}
              onClick={setDirectionsTo}
            >
              К узлу
            </div>
          </div>

          <div className={styles.title}>{getTitle()}</div>
          <div className={styles.rowItem}>
            <div className={styles.rowItemNum}>#</div>
            <div className={styles.rowItemId}>Узел</div>
            <div className={styles.rowItemCount}>ТС, [шт]</div>
          </div>

          <List
            dataSource={layerData.links}
            renderItem={(item: LinkSegmentData, index: number) => (
              <div className={styles.rowItem}>
                <div className={styles.rowItemNum}>{index + 1}</div>
                <div className={styles.rowItemId}>
                  {getNodeIdView(item, layerData.vertexes)}
                </div>
                <div className={styles.rowItemCount}>{item.count}</div>
              </div>
            )}
            pagination={DEFAULT_PAGE_SETTINGS}
          ></List>
        </div>
      ) : null}

      <Scene
        map={props.map}
        visible={true}
        zoom={DEFAULT_SCENE_ZOOM}
        layers={layers}
        onClick={onFeatureClick}
        getTooltip={getTooltip}
      />
    </div>
  );
};

export default GraphVisualizer;
