import { useCallback, useEffect, useRef, useState } from 'react';

import { AUTO_UPDATE_STATIC_COMPONENTS_TIME } from '../../../../constants/constants';
import eventBus from '../../../../eventBus';
import { findBy } from '../../../../helpers/findBy';
import {
  ERROR_CAMERA,
  ERROR_TEXT,
  ERROR_URL,
  INITIAL_PROPERTIES,
} from '../../constants/constants';
import { getPreparedActiveStreamUrl } from '../../helpers/getPreparedActiveStreamUrl/getPreparedActiveStreamUrl';
import {
  HandleSetStream,
  LoadProperties,
  StreamType,
  VideoCameraProps,
  VideoSourceItem,
} from '../../models/VideoCamera.model';

interface useVideoCameraProps {
  cameraData: U<VideoCameraProps>;
  videoSources: VideoSourceItem[];
}

const useVideoCamera = ({ cameraData, videoSources }: useVideoCameraProps) => {
  const [loadProperties, setLoadProperties] =
    useState<LoadProperties>(INITIAL_PROPERTIES);
  const [activeStream, setActiveStream] = useState<N<VideoSourceItem>>(null);
  const cameraRef = useRef<HTMLIFrameElement | HTMLImageElement>(null);

  const handleUpdate = useCallback(
    (isLoad = true) => {
      cameraRef.current?.removeAttribute('src');

      activeStream?.url &&
        cameraRef.current?.setAttribute('src', activeStream.url);
      isLoad && setLoadProperties(INITIAL_PROPERTIES);
    },
    [activeStream?.url]
  );

  useEffect(() => {
    let intervalId: U<NodeJS.Timer>;

    handleUpdate();

    if (activeStream?.streamType === StreamType.Jpeg) {
      intervalId = setInterval(() => {
        handleUpdate(false);
      }, AUTO_UPDATE_STATIC_COMPONENTS_TIME);
    }

    return () => clearInterval(intervalId);
  }, [activeStream, handleUpdate]);

  useEffect(() => {
    const unsubscribe = eventBus.videoListUpdate.subscribe(handleUpdate);

    return () => {
      unsubscribe();
    };
  }, [handleUpdate]);

  const setStream = useCallback<HandleSetStream>((stream, isCorrectCam) => {
    setLoadProperties(INITIAL_PROPERTIES);

    if (!stream) return onError(isCorrectCam ? ERROR_URL : ERROR_CAMERA);

    const activeStreamUrl = getPreparedActiveStreamUrl(stream.url);

    const { url, ...props } = stream;

    const newStream: VideoSourceItem = {
      ...props,
      url: activeStreamUrl,
    };

    setActiveStream(newStream);
  }, []);

  useEffect(() => {
    const defaultPlayer =
      findBy(videoSources ?? [], true, 'defaultView') || videoSources[0];

    setStream(defaultPlayer, !!videoSources);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cameraData, setStream]);

  const onLoad = () =>
    setLoadProperties((prev) => ({ ...prev, isLoad: false }));

  const onError = (errorText = ERROR_TEXT) =>
    setLoadProperties({
      textError: errorText,
      isLoad: false,
    });

  return {
    activeStream,
    loadProperties,
    cameraRef,
    onLoad,
    onError,
    handleUpdate,
    setStream,
  };
};

export default useVideoCamera;
