import classNames from 'classnames';
import { isNumber } from 'lodash';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import { IoIosDocument } from 'react-icons/io';

import { findById } from '../../../../../helpers/findBy';
import rootStore from '../../../../../stores/rootStore/rootStore';
import { FilteredTypes, NORMATIVE_VALUES } from '../../constants/constants';

import styles from './NormativeIco.module.scss';

const { Normative } = FilteredTypes;

interface NormativeIcoProps {
  id: U<number>;
}

const NormativeIco: FC<NormativeIcoProps> = ({ id }) => {
  const { filterDtData } = rootStore.detailedStore;

  const ico = isNumber(id) ? findById(NORMATIVE_VALUES, id)?.icon : null;

  const isActiveId = filterDtData[Normative]?.some((el) => el === id);

  return (
    <span className={classNames(styles.ico, { [styles.active]: isActiveId })}>
      {ico ?? <IoIosDocument />}
    </span>
  );
};

export default observer(NormativeIco);
