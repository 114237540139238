import { observer } from 'mobx-react-lite';
import { useState } from 'react';

import { TMap } from '../../../../../stores/mapStore/mapStore.model';
import rootStore from '../../../../../stores/rootStore/rootStore';
import { System } from '../../../../../ts/enums/enums';
import NoData from '../../../../ui-kit/Errors/NoData/NoData';
import CrossroadSchemaWrapper from '../CrossroadSchemaWrapper/CrossroadSchemaWrapper';

import { getTLInfo } from './helpers/getTLInfoByDt';
import InfoBtn from './InfoBtn/InfoBtn';
import MapWrapper from './MapWrapper/MapWrapper';

import styles from './CrossroadWrapper.module.scss';

const ERROR_SCHEMA =
  'Изображение перекрестка отсутствует. Необходимо создать точку УДС';

const CrossroadWrapper = () => {
  const { infoData } = rootStore.uiStore;
  const { activeDtId, setMapPropsDetailed } = rootStore.detailedStore;

  const [map, setMap] = useState<TMap>(null);

  const isTL = infoData?.system === System.Lights;
  const dtId = isTL ? activeDtId : infoData?.id;

  const { coord, udsInfo } = getTLInfo(dtId ?? null);

  if (!dtId || !coord)
    return <NoData isNeedBackground={false} textError={ERROR_SCHEMA} />;

  return (
    <div className={styles.container}>
      <MapWrapper
        map={map}
        setMap={setMap}
        setMapProps={setMapPropsDetailed}
        centerCoord={coord}
      />
      <CrossroadSchemaWrapper map={map} dtId={dtId} />
      <InfoBtn pointUdsInfo={udsInfo} />
    </div>
  );
};

export default observer(CrossroadWrapper);
