import { EditOutlined } from '@ant-design/icons';
import { FC } from 'react';

import ModalBody from '../../../PhaseHold/ModalBody/ModalBody';
import ModalConfirm, {
  ModalConfirmProps,
} from '../../../PhaseHold/ModalConfirm/ModalConfirm';
import styles from '../../../styles/basicStyles.module.scss';
import { UseEditTableProps } from '../../model/temporaryPlan.model';

import useEditTable from './hooks/useEditTable';

interface EditTableProps extends UseEditTableProps {
  editProps: Omit<ModalConfirmProps, 'icon'>;
}

const EditTable: FC<EditTableProps> = ({ editProps, ...props }) => {
  const { editBodyProps, isErrorSgnProgram } = useEditTable(props);

  const { handleCancel, okButtonProps } = editProps;

  const disableReset = !isErrorSgnProgram && okButtonProps?.disabled;

  return (
    <ModalConfirm
      {...editProps}
      confirmClose={!disableReset}
      closableModal={false}
      handleReset={handleCancel}
      disabledReset={disableReset}
      icon={<EditOutlined className={styles.editIcon} />}
    >
      <ModalBody {...editBodyProps} />
    </ModalConfirm>
  );
};

export default EditTable;
