import { Heatmap as HeatmapLayer, VectorImage } from 'ol/layer';
import BaseLayer from 'ol/layer/Base';
import { getPointResolution, METERS_PER_UNIT } from 'ol/proj';
import VectorSource from 'ol/source/Vector';

import { ILayer } from '../../stores/gisDataStore/gisDataStore.model';
import { TMap } from '../../stores/mapStore/mapStore.model';
import { IRenderer } from '../../ts/models/gis/renderer.model';
import { gisHttp } from '../gisHttp';
import { RendererTypes } from '../styles/constants';
import { IHeatmapDefinition } from '../styles/utils/fromRenderer/renderer.model';

export class WebGLLayer extends VectorImage<VectorSource> {}

export const getScaleResolution = (map: TMap, scale: number): number => {
  if (!map) {
    return 1;
  }

  const center = map.getView().getCenter();

  if (!center) {
    return 1;
  }

  const dpiCoefficient = 4.2;
  const mpu = METERS_PER_UNIT.m;
  const mmPerMeter = 1000;

  const value =
    scale /
    getPointResolution(
      map.getView().getProjection(),
      mpu * mmPerMeter * dpiCoefficient,
      center
    );

  return Number.isFinite(value) ? value : 1;
};

export function updateHeatmapProps(
  rendererType: N<string>,
  rendererDefinition: U<IRenderer>,
  layer: BaseLayer
) {
  if (
    rendererType !== RendererTypes.HeatMap &&
    !(layer instanceof HeatmapLayer)
  ) {
    return;
  }

  const renderer = rendererDefinition?.renderer as IHeatmapDefinition;

  const colorStops = renderer.colorStops || [];

  const gradient = colorStops.map((stop) => {
    const { color } = stop;
    const [r, g, b, a] = color;
    const alpha = a / 255;

    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  });

  const field = renderer.field || 'OBJECTID';
  const radius = renderer.blurRadius || 10;

  layer.set('field', field);
  layer.set('radius', radius);
  layer.set('gradient', gradient);
}

export const getLayerFeaturesCount = async (definition: ILayer) => {
  const { url } = definition;

  if (!url) {
    return null;
  }

  try {
    const { data } = await gisHttp.get(
      `${url}/query?returnCountOnly=true&where=1=1`
    );

    const { count } = data;

    return Number.isFinite(Number(count)) ? Number(count) : null;
  } catch (e) {
    return null;
  }
};

export const getWordDegree = (value: number, words: string[]) => {
  const valueView = Math.abs(value) % 100;
  const num = valueView % 10;

  if (valueView > 10 && valueView < 20) return words[2];

  if (num > 1 && num < 5) return words[1];

  if (num === 1) return words[0];

  return words[2];
};
