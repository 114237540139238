import { useIsFetching, useQueryClient } from '@tanstack/react-query';

import { QUERY_KEY } from '../../../../../../../constants/queryConstants';
import rootStore from '../../../../../../../stores/rootStore/rootStore';
import getCameraData from '../helpers/getCameraData';

const { DEVICE_FORM_INFO } = QUERY_KEY;

const useVideoContent = () => {
  const { selectedDevice } = rootStore.adminPanelStore;

  const queryClient = useQueryClient();
  const isFetchingCamera = useIsFetching({
    queryKey: [DEVICE_FORM_INFO, selectedDevice],
  });

  const cameraData = getCameraData(queryClient);

  return { cameraData, isFetchingCamera };
};

export default useVideoContent;
