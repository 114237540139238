import { useQuery } from '@tanstack/react-query';
import { isNumber } from 'lodash';
import { useEffect } from 'react';

import api from '../../../../../../api';
import {
  DEFAULT_UPDATE_DEVICE_ADMIN,
  QUERY_KEY,
} from '../../../../../../constants/queryConstants';
import rootStore from '../../../../../../stores/rootStore/rootStore';
import transformSourceData from '../helpers/transformSourceData';

const { EXTERNAL_LINK_INFO } = QUERY_KEY;
const { fetchExternalLinkBySourceId } =
  api.devices.externalLinks.externalLinksApi;

const APP_PATH =
  'Admin.DeviceAdmin.SourceEdit.SourceEditForm.hooks.useSourceInfo';

const useSourceInfo = () => {
  const { selectedDevice, selectedSource } = rootStore.adminPanelStore;

  const {
    data: sourceInfo,
    isLoading,
    isError,
    refetch,
  } = useQuery({
    queryKey: [EXTERNAL_LINK_INFO, selectedDevice, selectedSource?.id],
    queryFn: async () => {
      const id = selectedSource?.id;

      if (!selectedDevice || !isNumber(id)) return null;

      const res = await fetchExternalLinkBySourceId(
        selectedDevice.id,
        id,
        APP_PATH
      );

      return transformSourceData(res);
    },
    refetchInterval: DEFAULT_UPDATE_DEVICE_ADMIN,
    enabled: !!(selectedDevice && selectedSource),
  });

  useEffect(() => {
    refetch();
  }, [selectedDevice, refetch]);

  return {
    source: sourceInfo?.source,
    isLoading,
    isError,
    fullSourceInfo: sourceInfo?.fullInfo,
  };
};

export default useSourceInfo;
