import { Form } from 'antd';
import { type FormInstance } from 'antd/lib';
import { isEqual, isNil, isString } from 'lodash';
import { useCallback, useEffect, useState } from 'react';

interface ValidateFormProps<T extends object> {
  form: FormInstance;
  initialProps?: T;
  isPreparedInitial?: boolean;
  transformValues?: (values: T) => N<object>;
  isEdit?: boolean;
}

const useValidateForm = <T extends object>({
  form,
  initialProps,
  isPreparedInitial = false,
  transformValues,
  isEdit = false,
}: ValidateFormProps<T>) => {
  const [isReadySubmit, setIsReadySubmit] = useState(false);
  const [isChangeForm, setIsChangeForm] = useState(false);

  const values = Form.useWatch([], form);

  const handleReset = useCallback(() => {
    form.setFieldsValue(initialProps);
  }, [form, initialProps]);

  const handleClear = useCallback(() => {
    form.resetFields();
  }, [form]);

  useEffect(() => {
    form.validateFields({ validateOnly: true }).then(
      () => {
        setIsReadySubmit(true);
      },
      () => {
        setIsReadySubmit(false);
      }
    );
  }, [form, values]);

  useEffect(() => {
    const isEmpty =
      values &&
      Object.values(values).every((el) => isNil(el) || (isString(el) && !el));

    setIsChangeForm(!isEmpty);
  }, [values]);

  useEffect(() => {
    const initValues =
      isPreparedInitial && initialProps
        ? transformValues?.(initialProps)
        : initialProps;

    values &&
      initValues &&
      setIsChangeForm(
        !isEqual(initValues, transformValues?.(values) ?? values)
      );
  }, [values, initialProps, isPreparedInitial, transformValues]);

  const isNoChanges = isEdit && !isChangeForm;

  const isDisabledConfirm = !isReadySubmit || isNoChanges;

  return {
    values,
    isReadySubmit,
    isDisabledConfirm,
    isChangeForm,
    setIsChangeForm,
    handleReset,
    handleClear,
  };
};

export default useValidateForm;
