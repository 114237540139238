import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import { TDeviceStatus } from '../../../../../../constants/devicesConstants';
import { sliceTitleBy } from '../../../../../../helpers/sliceTitle';
import rootStore from '../../../../../../stores/rootStore/rootStore';
import { System } from '../../../../../../ts/enums/enums';
import DevIco from '../../../../../IcoDevice/DevIco/DevIco';
import Popover, { PopoverProps } from '../../../../../ui-kit/Popover/Popover';

import PopoverContent from './PopoverContent/PopoverContent';

import styles from './SelectItem.module.scss';

interface SelectItemProps {
  system: System;
  id: SN;
  caption: string;
  deviceStatusId: N<TDeviceStatus>;
  isActiveId?: boolean;
  isMaxLen?: boolean;
  isIcoMain?: boolean;
}

const MAX_LENGTH = 40;

const SelectItem: FC<SelectItemProps> = ({
  system,
  id,
  caption,
  deviceStatusId,
  isActiveId = true,
  isMaxLen = true,
  isIcoMain = true,
}) => {
  const { interfaceTipsDelay } = rootStore.uiStore;
  const { selectedDevice } = rootStore.adminPanelStore;

  const popoverProps: PopoverProps = {
    content: <PopoverContent caption={caption} statusDevice={deviceStatusId} />,
    tipsDelay: interfaceTipsDelay,
    placement: 'right',
  };

  const title = isMaxLen ? sliceTitleBy(caption, MAX_LENGTH) : caption;

  return (
    <Popover {...popoverProps}>
      <div className={styles.container}>
        {isIcoMain ? (
          <DevIco
            system={system}
            iconStyle={styles.icon}
            statusDevice={deviceStatusId}
          />
        ) : null}
        <span
          className={classNames({
            [styles.id]: true,
            [styles.active]: isActiveId && selectedDevice?.id === id,
          })}
        >
          {id}
        </span>
        <p>{title}</p>
        {!isIcoMain ? (
          <div className={styles.status}>
            <DevIco
              system={system}
              iconStyle={styles.icon}
              statusDevice={deviceStatusId ?? undefined}
            />
          </div>
        ) : null}
      </div>
    </Popover>
  );
};

export default observer(SelectItem);
