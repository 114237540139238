import { Dayjs } from 'dayjs';

import {
  ExternalLink,
  ExternalLinkCreateProps,
  ExternalLinkUpdateProps,
} from '../../../../api/devices/model/devices.model';
import { TDevicesIds } from '../../../../constants/constants';
import { DataType } from '../../../../ts/models/table.model';
import { FormKeys } from '../DeviceAdminCard/DeviceFormContent/constants/constants';
import { FormSourceKeys } from '../SourceEdit/SourceForm/constants/constants';

export interface AddFormFields {
  [FormKeys.SName]: string;
  [FormKeys.Dsc]: N<string>;
  [FormKeys.DeviceTypeId]: TDevicesIds;
  [FormKeys.Longitude]: number;
  [FormKeys.Latitude]: number;
}

export interface SourceFormFields {
  [FormSourceKeys.SystemTypeId]: Nullish<number>;
  [FormSourceKeys.SystemId]: Nullish<string>;
  [FormSourceKeys.DeviceInfo]: Nullish<string>;
  [FormSourceKeys.Sname]: Nullish<string>;
  [FormSourceKeys.Dsc]: Nullish<string>;
  [FormSourceKeys.StatusId]: Nullish<number>;
  [FormSourceKeys.LifetimeFrom]: Nullish<Dayjs>;
  [FormSourceKeys.LifetimeTo]: Nullish<Dayjs>;
}

export interface DeviceItemExt {
  eputsDeviceId: N<number>;
  ext_name: N<string>;
  ext_id: N<string>;
  key: string;
}

export type ExternalProps = ExternalLinkUpdateProps | ExternalLinkCreateProps;

type EmptyCard = { isEmpty: boolean };

export type CardsContent = (ExternalLink | EmptyCard)[];

export const isEmptyCard = (value: CardsContent[number]): value is EmptyCard =>
  Object.hasOwn(value, 'isEmpty');

export const isFormKey = (key: string): key is FormKeys =>
  Object.values(FormKeys).some((el) => el === key);

export const isFormSourceKey = (key: string): key is FormSourceKeys =>
  Object.values(FormSourceKeys).some((el) => el === key);

export const isDeviceCorrect = (obj: DataType): obj is DeviceItemExt =>
  Object.hasOwn(obj, 'eputsDeviceId') &&
  Object.hasOwn(obj, 'ext_name') &&
  Object.hasOwn(obj, 'ext_id');

export const isExternalCreateProps = (
  obj: ExternalProps
): obj is ExternalLinkCreateProps => Object.hasOwn(obj, 'sc_system_id');

export const isExternalCamera = (
  obj: object
): obj is ExternalLinkCreateProps => {
  const jExtDevId = (obj as ExternalLinkCreateProps).jexternaldevice_id;

  return (
    Object.hasOwn(obj, 'sc_system_id') &&
    Object.hasOwn(obj, 'jexternaldevice_id') &&
    jExtDevId &&
    Object.hasOwn(jExtDevId, 'xeomaChainId')
  );
};

export interface RulesItems {
  regExp: N<string>;
  required: boolean;
}

export type HandleChangeDate = (value: N<Dayjs>, key: FormSourceKeys) => void;

export type HandleChangeSelect = (key: FormSourceKeys, value: U<SN>) => void;

export interface DeviceData extends AddFormFields {
  point_uds_profile_uid: N<string>;
}
