import { Card, Tabs } from 'antd';
import { observer } from 'mobx-react-lite';
import { FC, useEffect } from 'react';

import { TlManagementKeys } from '../../../../constants/commands';
import rootStore from '../../../../stores/rootStore/rootStore';
import { isTabControlKey } from '../model/postCommands.model';
import { ManageCardProps } from '../model/tlManagement.model';

import ConfirmBtn from './ConfirmBtn/ConfirmBtn';
import getIsNeedSwitchTab from './helpers/getIsNeedSwitchTab';
import { getTabpanesManagment } from './helpers/getTabpanesManagment';
import useOutsideAlert from './hooks/useOutsideAlert';

import styles from './ManageCard.module.scss';

const { Management } = TlManagementKeys;

const ManageCard: FC<ManageCardProps> = ({
  activeTL,
  setIsOpenTutorialTooltip,
}) => {
  const { activeControlKey, setDetailedValue } = rootStore.detailedStore;

  const { wrapperRef, handleClickManageCard } = useOutsideAlert(
    setIsOpenTutorialTooltip
  );

  const TABPANES = getTabpanesManagment(activeTL.id);

  const handleChange = (key: string) => {
    isTabControlKey(key) && setDetailedValue('activeControlKey', key);
  };

  useEffect(() => {
    const isNeedSwitch = getIsNeedSwitchTab(activeTL, activeControlKey);

    if (isNeedSwitch) {
      setDetailedValue('activeControlKey', Management);
    }
  }, [activeControlKey, activeTL, setDetailedValue]);

  return (
    <Card
      className={styles.manageCard}
      ref={wrapperRef}
      onClick={handleClickManageCard}
    >
      <Tabs
        className={styles.tabsCustom}
        popupClassName={styles.tooltipDotter}
        defaultActiveKey={Management}
        items={TABPANES}
        onChange={handleChange}
        activeKey={activeControlKey}
      />
      <ConfirmBtn />
    </Card>
  );
};

export default observer(ManageCard);
