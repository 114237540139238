import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import rootStore from '../../../../../../../stores/rootStore/rootStore';
import { FilteredTypes } from '../../../../../../TrafficLightDetailed/TlDetectors/constants/constants';
import ButtonIco from '../../../../../ButtonIco/ButtonIco';
import { FilterItem as FilterItemType } from '../../../../models/chart.model';
import { checkIsLaneActive } from '../helpers/checkIsLaneActive';
import getDisabledInfo from '../helpers/getDisabledInfo';

export interface FilterItemData {
  isDisabledItems: boolean;
  filterType: FilteredTypes;
  isLoading: boolean;
  isLast: boolean;
  parentFilter?: FilteredTypes;
  parentType?: number;
  isFirst?: boolean;
}

type FilterItemProps = FilterItemData & FilterItemType;

const FilterItem: FC<FilterItemProps> = ({
  name,
  type,
  value,
  isDisabledItems,
  filterType,
  isLoading,
  isLast,
  parentFilter,
  parentType,
  isFirst = false,
  disabled,
}) => {
  const { filterDtData, disabledChartTypes, setFilteredDtData } =
    rootStore.detailedStore;

  const isActiveChild = !!filterDtData[filterType]?.find((el) => el === type);

  const isActive = parentFilter
    ? checkIsLaneActive(filterType, type, parentFilter, parentType)
    : isActiveChild;

  const { isDisabledDir, tooltip } = getDisabledInfo({
    filterType,
    disabled,
    isActive,
    isDisabledItems,
    name,
  });

  const isOnceClick =
    (!disabledChartTypes[filterType] &&
      filterType === FilteredTypes.Directions &&
      !!parentFilter) ||
    filterType === FilteredTypes.Normative;

  return (
    <ButtonIco
      key={type}
      onClick={() => {
        setFilteredDtData({
          filteredType: filterType,
          value: type,
          parentFilter,
          parentType,
          isOnceClick,
        });
      }}
      popoverProps={{ content: tooltip }}
      isActive={!isDisabledDir && isActive}
      disabled={isDisabledDir || isLoading}
      isRoundRight={isLast}
      isRoundLeft={isFirst}
    >
      {value}
    </ButtonIco>
  );
};

export default observer(FilterItem);
