import { FC } from 'react';

import { SourceDisplayType } from '../../../../../../stores/adminPanelStore/constants/constants';
import rootStore from '../../../../../../stores/rootStore/rootStore';
import { CardsContent, isEmptyCard } from '../../../models/DeviceAdmin.model';
import SourceCard from '../SourceCard/SourceCard';
import SourceContent from '../SourceContent/SourceContent';

const { SourceAddForm, SourceWatchForm } = SourceDisplayType;

interface SourceItemProps {
  item: CardsContent[number];
}

const SourceItem: FC<SourceItemProps> = ({ item }) => {
  const { setAdminsData } = rootStore.adminPanelStore;

  const handleClick = () => {
    if (!isEmptyCard(item)) {
      setAdminsData('selectedSource', {
        id: item.id,
        formType: SourceWatchForm,
      });

      return;
    }

    setAdminsData('sourceDisplayType', SourceAddForm);
  };

  return (
    <SourceCard handleClick={handleClick}>
      <SourceContent {...item} />
    </SourceCard>
  );
};

export default SourceItem;
