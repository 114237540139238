import { Table as AntdTable } from 'antd';
import { TableProps } from 'antd/lib';
import { type TableRef } from 'antd/lib/table';
import { TableRowSelection } from 'antd/lib/table/interface';
import classNames from 'classnames';
import { FC, useCallback, useEffect, useRef, useState } from 'react';

import {
  DataType,
  IFilteredInfo,
  IIteractionButton,
  IQueryParamRequest,
  TableData,
  TableKeys,
} from '../../../ts/models/table.model';

import Columns from './Columns/Columns';
import { DEFAULT_TABLE } from './constants';
import FilterInfo from './FilterInfo/FilterInfo';
import Footer, { FooterProps } from './Footer/Footer';
import { checkFilters } from './helpers/checkFilters';
import { getMutTableData } from './helpers/getColumns/getMutTableData';
import { getTableConfig } from './helpers/getTableConfig';
import { useTableHeightControl } from './hooks/useTableHeightControl';
import RefreshButton from './TableServerSearch/RefreshButton/RefreshButton';
import TableServerSearch from './TableServerSearch/TableServerSearch';
import TableWrapper from './TableWrapper/TableWrapper';

import styles from './Table.module.scss';

export interface ITable {
  tableData: TableData;
  collaborationRender?: (collaboration: any) => JSX.Element;
  tableConfigType: TableKeys;
  title?: string;
  iteractionButtons?: IIteractionButton[];
  rowSelection?: TableRowSelection<DataType>;
  selectedRowKeysFunc?: (tableData: TableData) => string[];
  refreshData:
    | ((queryParamsReq?: IQueryParamRequest) => Promise<void>)
    | (() => void);
  exportBtnTitle?: string;
  limitIsExceeded?: boolean;
  notFullscreenMode?: true;
  isLoading: boolean;
  isOrderNumber?: boolean;
}

const Table: FC<ITable> = ({
  tableData,
  tableConfigType,
  collaborationRender,
  title,
  iteractionButtons,
  rowSelection,
  selectedRowKeysFunc,
  refreshData,
  exportBtnTitle = 'Таблица',
  limitIsExceeded,
  notFullscreenMode,
  isLoading,
  isOrderNumber = false,
}) => {
  const { queryParams } = tableData;
  const [mutTableData, setMutTableData] = useState<TableData>(DEFAULT_TABLE);
  const [filteredInfo, setFilteredInfo] = useState<IFilteredInfo>(null);
  const [maxPageSize, setMaxPageSize] = useState<N<number>>(null);
  const [queryParamsRequest, setQueryParamsRequest] =
    useState<U<IQueryParamRequest>>();
  const tableRef = useRef<TableRef>(null);

  const [pageSize, minPageSize] = useTableHeightControl({
    ref: tableRef,
    notFullscreenMode: Boolean(notFullscreenMode),
    dependenciesArray: [filteredInfo, mutTableData.dataRows],
    refreshData,
    tableConfigType,
  });

  useEffect(() => {
    const newMutTableData = getMutTableData(
      tableData,
      collaborationRender,
      styles.activeSearch,
      isOrderNumber
    );

    setMutTableData(newMutTableData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableData]);

  useEffect(() => {
    setMutTableData((prev) => {
      const newHeaderItem = prev.headerItem.map((column) => {
        const newColumn = {
          ...column,
          filteredValue: filteredInfo?.[column.dataIndex] || null,
        };

        return newColumn;
      });

      return {
        ...prev,
        headerItem: newHeaderItem,
      };
    });
  }, [filteredInfo, isLoading]);

  const handleChange = useCallback<
    NonNullable<TableProps<DataType>['onChange']>
  >((pagination, filters, sorter, extra) => {
    setMaxPageSize(extra.currentDataSource.length);
    if (extra.action !== 'filter') return;

    if (Object.keys(filters).length) {
      checkFilters(filters) ? setFilteredInfo(null) : setFilteredInfo(filters);

      return;
    }

    setFilteredInfo(null);
  }, []);

  const rowSelectionPrepared = (): TableRowSelection<DataType> | undefined => {
    if (!rowSelection) return;

    const selectedRowKeys = selectedRowKeysFunc
      ? selectedRowKeysFunc(tableData)
      : rowSelection?.selectedRowKeys;

    return {
      ...rowSelection,
      selectedRowKeys: selectedRowKeys,
    };
  };

  const selectRow = (record: any) => {
    rowSelection?.onChange?.([record.key], [record], { type: 'multiple' });
  };

  const footerTableProps: FooterProps = {
    tableData: mutTableData,
    exportBtnTitle,
    iteractionButtons,
  };

  return (
    <TableWrapper>
      <div className={styles.filterAndSearch}>
        {title && <h3 className={styles.title}>{title}</h3>}
        <FilterInfo
          filteredInfo={filteredInfo}
          setFilteredInfo={setFilteredInfo}
          tableData={mutTableData}
          setMaxPageSize={setMaxPageSize}
        />
        <div className={styles.leftBlock}>
          <RefreshButton
            queryParamsRequest={queryParamsRequest}
            refreshData={refreshData}
          />
          {queryParams?.length ? (
            <TableServerSearch
              queryParams={queryParams}
              refreshData={refreshData}
              limitIsExceeded={limitIsExceeded}
              queryParamsRequest={queryParamsRequest}
              setQueryParamsRequest={setQueryParamsRequest}
            />
          ) : null}
        </div>
      </div>
      <AntdTable
        size="small"
        ref={tableRef}
        rowSelection={rowSelectionPrepared()}
        onRow={(record) => ({
          onClick: () => selectRow(record),
        })}
        showSorterTooltip={false}
        columns={mutTableData.headerItem}
        loading={
          isLoading
            ? {
                size: 'large',
              }
            : false
        }
        components={{
          header: {
            cell: Columns,
          },
        }}
        dataSource={mutTableData.dataRows}
        onChange={handleChange}
        {...getTableConfig(
          tableConfigType,
          maxPageSize ?? mutTableData.dataRows.length,
          minPageSize,
          pageSize
        )}
        className={classNames(styles.table, {
          [styles.clickable]: rowSelection,
        })}
        footer={() => <Footer {...footerTableProps} />}
      />
    </TableWrapper>
  );
};

export default Table;
