import { FC } from 'react';
import { RxUpdate } from 'react-icons/rx';

import ButtonOnlyIcon from '../../ButtonOnlyIcon/ButtonOnlyIcon';

interface UpdateBtnProps {
  isFetching?: boolean;
  onUpdateBtn: () => void;
}

const UpdateBtn: FC<UpdateBtnProps> = ({ isFetching = false, onUpdateBtn }) => {
  const tip = isFetching ? 'Идет автообновление...' : 'Обновить';

  return (
    <ButtonOnlyIcon
      onClick={onUpdateBtn}
      tip={tip}
      isSmall
      isDisabled={isFetching}
    >
      <RxUpdate />
    </ButtonOnlyIcon>
  );
};

export default UpdateBtn;
