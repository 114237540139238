import type { CanvasFilterOptions } from 'ol-ext/filter/CanvasFilter';

export const BASEMAPS_PROPS = {
  CATEGORY_PROPERTY: 'category',
  CATEGORY_PROPERTY_VALUE: 'basemap',
  LAYER_ID_PROPERTY: 'id',
  MVT_ID_PROPERTY: '_MVT',
  MVT_LAYER_Z_INDEX: 10,
  IS_PERSISTENT: true,
};

export const BASEMAPS_TYPES = {
  XYZ: 'esri/layers/OpenStreetMapLayer',
  VECTOR: 'esri/layers/VectorTileLayer',
};

export const BW_BASEMAP_FILTER: CanvasFilterOptions = {
  blur: 0,
  brightness: 100,
  hueRotate: 0,
  invert: 0,
  saturate: 0,
  sepia: 0,
  shadow: undefined,
  shadowBlur: 0,
  shadowColor: 0,
  url: '',
  grayscale: 180,
  contrast: 120,
};

export const BLUR_FILTER: CanvasFilterOptions = {
  blur: 30,
  brightness: 105,
  hueRotate: 0,
  invert: 0,
  saturate: 0,
  sepia: 0,
  shadow: undefined,
  shadowBlur: 0,
  shadowColor: 0,
  url: '',
  grayscale: 50,
  contrast: 100,
};

export const BLUR_GRAY_FILTER: CanvasFilterOptions = {
  blur: 30,
  brightness: 105,
  hueRotate: 0,
  invert: 0,
  saturate: 0,
  sepia: 0,
  shadow: undefined,
  shadowBlur: 0,
  shadowColor: 0,
  url: '',
  grayscale: 100,
  contrast: 100,
};
