import { Tag } from 'antd';

import { LibraryKeysZod } from '../../../../../../../api/libraries/libraries.zod';
import {
  ColumnTypes,
  ITableColumn,
  TableData,
} from '../../../../../../../ts/models/table.model';
import { convertDate } from '../../../../../../Admin/helpers/helpers';
import JsonView from '../../../../../JsonView/JsonView';
import Popover from '../../../../../Popover/Popover';
import { ITable } from '../../../../Table';
import { ArrayRender } from '../ArrayRender/ArrayRender';
import { getParsedLibraryValue } from '../getParsedLibraryValue';

export const TYPE_RENDERS: Record<ColumnTypes, ITableColumn['render']> = {
  string: (data: any) => data,
  collaboration: undefined,
  number: (data: any) => data,
  date: (data: any) => {
    const timestamp = Number(data);

    return !Number.isNaN(timestamp) ? convertDate(timestamp) : data;
  },
  boolean: (data: any) => (data ? 'Да' : 'Нет'),
  jArrayNumber: (data: any) => <ArrayRender data={data} />,
  jArrayString: (data: any) => <ArrayRender data={data} />,
  jObjectAny: (data: any) => <JsonView object={data} />,
  jArrayAny: (data: any) => <JsonView object={data} />,
  jsonAny: (data: any) => <JsonView object={data} />,
};

export const getTypeRendersWithLibrary = (library?: LibraryKeysZod[]) => {
  const typeRendersWithLibrary: Record<ColumnTypes, ITableColumn['render']> = {
    string: (data: any) => getParsedLibraryValue(data, library),
    collaboration: undefined,
    number: (data: any) => getParsedLibraryValue(data, library),
    date: (data: any) => convertDate(Number(data)),
    boolean: (data: any) => {
      const preparedValue = getParsedLibraryValue(data, library);

      if (preparedValue === data) return data ? 'Да' : 'Нет';

      return preparedValue;
    },
    jArrayNumber: (data: any) => <ArrayRender data={data} library={library} />,
    jArrayString: (data: any) => <ArrayRender data={data} library={library} />,
    jObjectAny: (data: any) => <JsonView object={data} />,
    jArrayAny: (data: any) => <JsonView object={data} />,
    jsonAny: (data: any) => <JsonView object={data} />,
  };

  return typeRendersWithLibrary;
};

export const prepareColumnDataView = (
  column: ITableColumn,
  tableData: TableData,
  collaborationRender: ITable['collaborationRender']
) => {
  if (column.referens === 'collaboration' && collaborationRender) {
    column.render = collaborationRender;

    return;
  }

  const getRenderFunc = () => {
    if (column.viewValueLibraryKey && tableData.libraries) {
      const library = tableData?.libraries[column.viewValueLibraryKey];

      return getTypeRendersWithLibrary(library)[column.type];
    }

    return TYPE_RENDERS[column.type];
  };

  const renderFunc = getRenderFunc();

  if (!renderFunc) return;

  column.render = (data: any) => {
    const renderData = renderFunc(data);

    let tableViewData = renderData;

    if (
      column.type === ColumnTypes.jObjectAny ||
      column.type === ColumnTypes.jsonAny
    ) {
      tableViewData = <Tag color="green">Json</Tag>;
    }

    if (column.type.includes('jArray')) {
      if (column.type === ColumnTypes.jArrayAny) {
        tableViewData = (
          <Tag color="cyan">
            Список <br /> json {data.length}
          </Tag>
        );
      } else {
        tableViewData = <Tag color="green">Список {data.length}</Tag>;
      }
    }

    return (
      <Popover content={renderData}>
        <>{tableViewData}</>
      </Popover>
    );
  };
};
