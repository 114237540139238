import { SECOND } from '../../../../../constants/constants';
import { UPDATE_PREDICT_CHART_INTERVAL } from '../../../../../constants/queryConstants';
import rootStore from '../../../../../stores/rootStore/rootStore';
import { ChartBDKeys } from '../../../../InfoPanel/SystemsInfo/PopupDetector/Chart/constants/constants';
import getChartParamName from '../../../../InfoPanel/SystemsInfo/PopupDetector/Chart/helpers/getChartParamName';
import { getIntervalFormatted } from '../../../../ui-kit/Chart/helpers/getIntervalFormatted';
import { GRAPH_TYPE_TITLE, GRAPHS_TITLE } from '../../constants/constants';

const getTitleGraph = (graphTitle: ChartBDKeys, interval: U<number>) => {
  const { graphType, isCandleChart } = rootStore.detailedStore;

  const graphParam = getChartParamName(graphTitle);

  const intervalText = interval
    ? getIntervalFormatted(
        isCandleChart ? UPDATE_PREDICT_CHART_INTERVAL / SECOND : interval
      )
    : null;

  return {
    mainTitleGraph: GRAPH_TYPE_TITLE[graphType],
    additionalTitleGraph: graphParam
      ? `${GRAPHS_TITLE[graphParam].value} потока ${
          intervalText ? `/ ${intervalText}` : null
        }`
      : 'Неизвестный параметр',
  };
};

export default getTitleGraph;
