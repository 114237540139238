import { isNumber } from 'lodash';

import { DEVICES_STORE_NAMES } from '../../../../../../constants/devicesConstants';
import rootStore from '../../../../../../stores/rootStore/rootStore';
import { System } from '../../../../../../ts/enums/enums';
import { getDeviceUdsInfo } from '../../../../../TrafficLightDetailed/TlDetectors/GeneralDtTabs/CrossroadWrapper/helpers/getTLInfoByDt';

const getDeviceInfo = (id: Nullish<number>, system: U<System>) => {
  if (!isNumber(id) || !system) return null;

  const { getById } = rootStore.mapDataStore;

  const deviceStoreName = DEVICES_STORE_NAMES[system];

  const activeDevice = deviceStoreName && getById(id, deviceStoreName);

  if (!activeDevice) return null;

  const { coord, tlId, udsInfo } = getDeviceUdsInfo(activeDevice);

  return {
    tlId,
    coord: coord ?? [activeDevice.longitude, activeDevice.latitude],
    udsInfo,
  };
};

export default getDeviceInfo;
