import { Device } from '../../../api/devices/model/devices.model';
import { ADMIN_TITLE, AdminTitleKeys } from '../../../constants/adminConstants';
import { DEVICE_TYPES_ID } from '../../../constants/constants';
import rootStore from '../../../stores/rootStore/rootStore';
import { isSystem } from '../../../ts/models/mapObject.model';
import DevIco from '../../IcoDevice/DevIco/DevIco';
import { getIsAdminKeys } from '../../Panel/AdminPanel/helpers/getIsAdminKeys';
import { getSystemAdminType } from '../../Panel/AdminPanel/helpers/getSystemAdminType';

const { DevControl } = AdminTitleKeys;

const getSubtitle = (adminType: N<AdminTitleKeys>, device: U<Device>) => {
  const defaultProps = { subtitle: undefined, subHeaderIco: null };

  if (adminType !== DevControl) return defaultProps;

  const system = device ? DEVICE_TYPES_ID[device.device_type_id] : null;

  if (!device || !isSystem(system)) return defaultProps;

  const { sname, device_status_id } = device;

  const subtitle = sname;
  const subHeaderIco = (
    <DevIco system={system} statusDevice={device_status_id} />
  );

  return { subtitle, subHeaderIco };
};

const getAdminHeader = (device: U<Device>) => {
  const { adminTableType } = rootStore.adminPanelStore;

  const type = getSystemAdminType(adminTableType);
  const adminType = getIsAdminKeys(type) ? type : null;

  const header = adminType ? ADMIN_TITLE[adminType] : ADMIN_TITLE.system;

  const { subtitle, subHeaderIco } = getSubtitle(adminType, device);

  return { type, header, subtitle, subHeaderIco };
};

export default getAdminHeader;
