import { COLORS } from '../../../../constants/colorsConstants';

const { SUCCESS, DANGER, YELLOW, WARNING, GREY100 } = COLORS;

const TACT_FILL: Record<number, string> = {
  1: SUCCESS,
  2: SUCCESS,
  3: YELLOW,
  4: WARNING,
  5: DANGER,
};

const getTactFill = (type: number) => TACT_FILL?.[type] ?? GREY100;

export default getTactFill;
