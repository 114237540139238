import {
  MAX_DISTANCE,
  MIN_Y_TICK_DISTANCE,
  Y_TICKS,
} from '../constants/constants';

const getYTicks = (distances: number[]) => {
  const staticTicks = Y_TICKS.filter(
    (tick) =>
      tick < (distances?.at(-1) ?? MAX_DISTANCE) &&
      distances.every((dist) => Math.abs(dist - tick) >= MIN_Y_TICK_DISTANCE)
  );

  return [...staticTicks, ...distances].sort((a, b) => a - b);
};

export default getYTicks;
