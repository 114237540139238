import { Select } from 'antd';
import { observer } from 'mobx-react-lite';

import { INPUT_MAX_LEN } from '../../../../../constants/constants';
import NOTICE from '../../../../../constants/notificationConstants';
import rootStore from '../../../../../stores/rootStore/rootStore';
import { getIsFilterOption } from '../../../../Panel/ScriptsControl/SelectModule/DispatchGroupControl/SelectItem/helpers/helpers';
import { getOptionValue } from '../../DeviceAdminCard/SelectDevice/helpers/optionHelpers';

import useSourceSelect from './hooks/useSourceSelect';

import styles from './SourceSelect.module.scss';

const { EXTERNAL_LINKS_ERROR } = NOTICE;

const SourceSelect = () => {
  const { selectedSource } = rootStore.adminPanelStore;

  const { isDisabled, isLoading, options, onChange } = useSourceSelect();

  const placeholder = isDisabled ? EXTERNAL_LINKS_ERROR : 'Выберите источник';

  return (
    <Select
      className={styles.select}
      showSearch
      allowClear
      disabled={isDisabled}
      placeholder={placeholder}
      optionFilterProp="children"
      filterOption={(input, option) => getIsFilterOption(input, option?.value)}
      value={getOptionValue(options, selectedSource?.id)}
      loading={isLoading}
      maxLength={INPUT_MAX_LEN}
      menuItemSelectedIcon={null}
      options={options ?? []}
      onChange={onChange}
    />
  );
};

export default observer(SourceSelect);
