import { useEffect, useState } from 'react';
import { IoMdEye, IoMdEyeOff } from 'react-icons/io';
import { MdSettings } from 'react-icons/md';
import { RiGridLine } from 'react-icons/ri';

import { TMap } from '../../../../stores/mapStore/mapStore.model';
import rootStore from '../../../../stores/rootStore/rootStore';
import { getLayerVisibility, setLayerVisibility } from '../helpers';

import { LayerFeaturesCountInfo } from './LayerFeaturesCountInfo';

import styles from '../LayersButton.module.scss';

const LayerNode = (props: {
  inRange: boolean;
  alias: string;
  id: string;
  maxScaleView: string;
  minScaleView: string;
  onSettingsClick?: (evt: any) => void;
  onTableClick?: (evt: any) => void;
  map: TMap;
}) => {
  const { layersState, layersVisibilityProxy } = rootStore.gisDataStore;

  const { map, id } = props;

  const [isVisible, setIsVisible] = useState(getLayerVisibility(map, id));
  const [definition] = useState(
    layersState.find((element) => element.id === id)
  );

  const toggleVisibility = () => {
    setLayerVisibility(map, id, !isVisible);

    setIsVisible(!isVisible);
  };

  useEffect(() => {
    // @ts-ignore
    const state = JSON.parse(layersVisibilityProxy) || {};

    const value = state[id];

    setIsVisible(value);
  }, [layersVisibilityProxy, id]);

  return (
    <>
      <div className={styles.layerItemTitleContainer}>
        <div className={styles.rowLine}>
          <div
            className={styles.visibilityIcon}
            onClick={toggleVisibility}
            role={'presentation'}
          >
            {isVisible ? (
              <IoMdEye
                className={styles.iconActive}
                title={'Видимость слоя включена'}
              />
            ) : (
              <IoMdEyeOff
                className={styles.iconPassive}
                title={'Видимость слоя выключена'}
              />
            )}
          </div>
          <div
            role={'presentation'}
            onClick={toggleVisibility}
            className={
              props.inRange ? styles.nodeTitle : styles.nodeTitleNotInRange
            }
          >
            {props.alias || props.id}
          </div>
        </div>
        <div className={styles.nodeContainer}>
          <div>
            <RiGridLine
              className={styles.nodeSettings}
              onClick={props?.onTableClick}
            />
            <MdSettings
              className={styles.nodeSettings}
              onClick={props.onSettingsClick}
            />
          </div>
        </div>
      </div>
      <div className={styles.nodeSubInfoContainer}>
        <LayerFeaturesCountInfo definition={definition} />
        <div
          className={
            props.inRange
              ? styles.nodeTitleScaleContainer
              : styles.nodeTitleScaleContainerNotInRange
          }
        >
          с {props.maxScaleView} по {props.minScaleView}
        </div>
      </div>
    </>
  );
};

export default LayerNode;
