import { Alert, Card, DatePicker, Select, Spin } from 'antd';
import type { RangePickerTimeProps } from 'antd/es/time-picker';
import { Dayjs } from 'dayjs';
import React, { useState } from 'react';
import { MdSquare, MdTipsAndUpdates } from 'react-icons/md';

import { NODE_MERGE_OPTIONS, PANEL_STYLE } from './constants';
import { IGraphDataSet } from './trafficGraph.model';

import styles from './TrafficGraphButton.module.scss';

const { RangePicker } = DatePicker;

const GraphSettingsPanel = (props: {
  onChange: (value: ((prevState: string) => string) | string) => void;
  mergeMethod: string;
  dateStart: string | undefined;
  dateEnd: string | undefined;
  onDateIntervalChange: (value: string[]) => void;
  dataSet: IGraphDataSet | null;
  loading: boolean;
}) => {
  const [dateInterval] = useState<
    RangePickerTimeProps<Dayjs> | null | undefined
  >(undefined);

  const nodesCount = props.dataSet?.vertexes?.length || 0;
  const linksCount = props.dataSet?.links?.length || 0;

  return (
    <Card className={styles.trafficGraphPanel} styles={{ body: PANEL_STYLE }}>
      <div>Граф корреспонденции</div>

      <div className={styles.title}>Способ объединения узлов</div>

      <Select
        className={styles.mergeTypeField}
        onChange={props.onChange}
        options={NODE_MERGE_OPTIONS}
        value={props.mergeMethod}
      />

      <div className={styles.title}>Период</div>
      <div className={styles.timeSelectorsContainer}>
        <RangePicker
          // @ts-ignore
          value={dateInterval}
          showTime={{ format: 'HH:mm' }}
          format="YYYY-MM-DD HH:mm"
          onChange={(value, interval) => {
            props.onDateIntervalChange(interval);
          }}
        />
      </div>

      <div className={styles.tipContainer}>
        <MdTipsAndUpdates className={styles.tipIcon} />
        <div className={styles.tipText}>
          Для формирования графа выберите даты
        </div>
      </div>

      {props.loading ? (
        <>
          <Spin spinning={props.loading}>
            <Alert
              type="info"
              message="Загрузка данных"
              description="Пожалуйста, подождите, производится загрузка данных о проездах на выбранный период"
            />
          </Spin>
        </>
      ) : (
        <>
          <div>
            <div className={styles.title}>Информация</div>
            <div className={styles.tipText}>Число узлов: {nodesCount}</div>
            <div className={styles.tipText}>Число связей: {linksCount}</div>
          </div>

          <div>
            <div className={styles.title}>Легенда</div>
            <div className={styles.legendItemContainer}>
              <div className={styles.legendIconStart}>
                <MdSquare />
              </div>
              <div>начало направления движения</div>
            </div>
            <div className={styles.legendItemContainer}>
              <div className={styles.legendIconEnd}>
                <MdSquare />
              </div>
              <div>завершение направления траффика</div>
            </div>
          </div>
        </>
      )}
    </Card>
  );
};

export default GraphSettingsPanel;
