import { isNumber } from 'lodash';

import { SYSTEM } from '../../../../constants/constants';
import rootStore from '../../../../stores/rootStore/rootStore';
import { VideoCameraProps } from '../../models/VideoCamera.model';

const { CAMERAS } = SYSTEM;

export const showCameraOnMap = (camera: VideoCameraProps) => {
  const { setIsNot, setKeyValue, setInfoData } = rootStore.uiStore;

  const { deviceId, longitude, latitude } = camera;

  if (!isNumber(deviceId) || !isNumber(longitude) || !isNumber(latitude))
    return;

  setIsNot('isPanel', false);
  setIsNot('isDetailedForm', false);
  setKeyValue('panelType', null);

  setInfoData({
    id: deviceId,
    coordinate: [longitude, latitude],
    system: CAMERAS,
  });
};
