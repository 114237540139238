/* eslint-disable jsx-a11y/no-static-element-interactions */
import { Switch } from 'antd';
import { SwitchChangeEventHandler } from 'antd/lib/switch';
import classNames from 'classnames';
import {
  MouseEventHandler,
  useState,
  ReactNode,
  FC,
  CSSProperties,
} from 'react';
import { IoIosArrowForward } from 'react-icons/io';

import { COLORS } from '../../../constants/colorsConstants';
import { TRANSITION_TIME } from '../../../constants/constants';
import ButtonIco from '../ButtonIco/ButtonIco';
import ConfirmSwitcher from '../ConfirmSwitcher/ConfirmSwitcher';
import Popover, { PopoverProps } from '../Popover/Popover';

import styles from './AccordionSimple.module.scss';

export interface AccordionSimpleProps {
  title: string;
  children?: JSX.Element | ReactNode;
  isClosed?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  isSwitch?: boolean;
  isEnabled?: boolean;
  handleActive?: SwitchChangeEventHandler;
  onAction?: any;
  onAction2?: any;
  isActionConfirm?: boolean;
  ActionIco?: any;
  ActionIco2?: any;
  statusColor?: N<string>;
  statusBlock?: JSX.Element;
  isActionDisabled?: boolean;
  popoverProps?: PopoverProps;
  isDisabled?: boolean;
  borderColor?: string;
  onActiveClick?: (isActive: boolean) => void;
  style?: CSSProperties;
}

const AccordionSimple: FC<AccordionSimpleProps> = ({
  title,
  children,
  isClosed = true,
  onClick,
  isSwitch,
  isEnabled,
  handleActive,
  onAction,
  onAction2,
  isActionConfirm,
  ActionIco,
  ActionIco2,
  statusColor,
  statusBlock,
  isActionDisabled = false,
  popoverProps = { tipsDelay: null, content: '' },
  isDisabled = false,
  borderColor = COLORS.BG_TINT,
  onActiveClick,
  style,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(isClosed);

  const handleOnActiveClick = (isActive: boolean) => onActiveClick?.(isActive);

  const handleCollapse = () => {
    if (isDisabled) return;

    onClick && setTimeout(onClick, TRANSITION_TIME);
    handleOnActiveClick(isCollapsed);
    setIsCollapsed(!isCollapsed);
  };

  const isOpenBtnStyle = classNames({
    [styles.collapseBtn]: true,
    [styles.isAccordionOpen]: !isCollapsed,
    [styles.isDisabled]: isDisabled,
  });

  const contentContainerStyle = classNames({
    [styles.contentContainer]: true,
    [styles.isOpen]: !isCollapsed,
    [styles.isHidden]: isCollapsed,
  });

  const headerStyle = classNames({
    [styles.header]: true,
    [styles.isDisabled]: isDisabled,
  });

  const containerStyle: CSSProperties = {
    ...style,
    border: `1px solid ${isDisabled ? COLORS.ERROR : borderColor}`,
  };

  return (
    <section className={styles.container} style={containerStyle}>
      <div className={headerStyle} onClick={handleCollapse}>
        <button
          type="button"
          className={isOpenBtnStyle}
          style={{ backgroundColor: statusColor ?? undefined }}
        >
          <IoIosArrowForward />
        </button>
        <Popover {...popoverProps}>
          <div className={styles.headerContent}>
            <div className={styles.info}>
              <h5 className={styles.title}>{title}</h5>
            </div>
            <div
              className={styles.btnsBlock}
              onClick={(e) => e.stopPropagation()}
            >
              {ActionIco2 && (
                <ButtonIco
                  onClick={(e: any) => {
                    e.stopPropagation();
                    onAction2?.();
                  }}
                  style={{ width: 20, marginLeft: 5 }}
                >
                  <ActionIco2 />
                </ButtonIco>
              )}

              {ActionIco && (
                <ConfirmSwitcher isConfirm={isActionConfirm} onClick={onAction}>
                  <ButtonIco
                    onClick={(e: any) => {
                      e.stopPropagation();
                      !isActionConfirm && onAction?.();
                    }}
                    style={{ width: 35 }}
                    isNotAllowed={isActionDisabled}
                  >
                    <ActionIco />
                  </ButtonIco>
                </ConfirmSwitcher>
              )}

              {statusBlock ?? null}

              {isSwitch && handleActive && (
                <Switch
                  size="small"
                  checked={isEnabled}
                  onChange={(value: boolean, e: any) => {
                    e.stopPropagation();
                    handleActive(value, e);
                  }}
                />
              )}
            </div>
          </div>
        </Popover>
      </div>
      <div
        role="presentation"
        className={contentContainerStyle}
        onClick={() => handleOnActiveClick(!isCollapsed)}
      >
        <div className={styles.content}>{children}</div>
      </div>
    </section>
  );
};

export default AccordionSimple;
