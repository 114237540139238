import { observer } from 'mobx-react-lite';

import { QUERY_KEY } from '../../../../constants/queryConstants';
import { SourceDisplayType } from '../../../../stores/adminPanelStore/constants/constants';
import rootStore from '../../../../stores/rootStore/rootStore';
import DefaultChecked from '../../../ui-kit/DefaultChecked/DefaultChecked';
import { EMPTY_SOURCE_TITLE, SOURCE_LABELS } from '../constants/constants';
import Header from '../Header/Header';

import useSourceEdit from './hooks/useSourceEdit';
import SourceSelect from './SourceSelect/SourceSelect';
import SourceSwitcher from './SourceSwitcher/SourceSwitcher';

import styles from './SourceEdit.module.scss';

const { EXTERNAL_LINKS_INFO } = QUERY_KEY;
const { SourceEditForm, SourceWatchForm } = SourceDisplayType;

const SourceEdit = () => {
  const { selectedDevice, sourceDisplayType } = rootStore.adminPanelStore;

  const { isShowArrow, handleClick } = useSourceEdit();

  const isEmptyData = !selectedDevice;
  const title = !isEmptyData ? 'Список источников' : null;

  const isSourceList = !sourceDisplayType;
  const isShowBtns = isSourceList && !!selectedDevice;
  const isSourceSelect =
    sourceDisplayType === SourceEditForm ||
    sourceDisplayType === SourceWatchForm;

  return (
    <div className={styles.block}>
      <Header
        title={!isSourceList ? SOURCE_LABELS[sourceDisplayType] : title}
        handleClick={handleClick}
        isShowArrow={isShowArrow}
        isShowBtns={isShowBtns}
        updateKeys={[EXTERNAL_LINKS_INFO, selectedDevice]}
        isSmall
      />
      {isSourceSelect ? <SourceSelect /> : null}
      <DefaultChecked
        isEmptyData={isEmptyData}
        noDataProps={{ textError: EMPTY_SOURCE_TITLE }}
      >
        <SourceSwitcher />
      </DefaultChecked>
    </div>
  );
};

export default observer(SourceEdit);
