import { DescriptionsProps } from 'antd/lib';

import { ExternalLink } from '../../../../../../../api/devices/model/devices.model';
import { LibraryKeys } from '../../../../../../../api/libraries/enums/enums';
import { SYSTEM_BY_TYPE_ID } from '../../../../../../../constants/deviceConstants';
import convertDBdate from '../../../../../../../helpers/dateHelpers/convertDBdate';
import { findById } from '../../../../../../../helpers/findBy';
import rootStore from '../../../../../../../stores/rootStore/rootStore';
import { isDeviceStatus } from '../../../../../../../ts/models/mapObject.model';
import { isActualDeviceType } from '../../../../../../../ts/typeGuards/mapObjectsTG';
import SelectItem from '../../../../DeviceAdminCard/SelectDevice/SelectItem/SelectItem';
import { isExternalCamera } from '../../../../models/DeviceAdmin.model';
import SourceStatus from '../../../SourceContainer/SourceContent/SourceStatus/SourceStatus';
import DescriptionItem from '../DescriptionItem/DescriptionItem';
import JsonAdditional from '../JsonAdditional/JsonAdditional';

const { SystemId } = LibraryKeys;
const NO_NAME = 'Название отсутствует';

const getSourceInfo = (fullSourceInfo: U<ExternalLink>, isFullInfo = true) => {
  const { selectedDevice } = rootStore.adminPanelStore;
  const systemDict = rootStore.uiStore.dictionaries[SystemId];

  if (!fullSourceInfo || !selectedDevice)
    return { fullInfo: [], sourceInfo: null };

  const {
    sc_system_id,
    external_status_id,
    status_id,
    sname,
    id,
    dsc,
    external_uuid,
    externaldevice_id,
    jexternaldevice_id,
    insert_time,
    lifetime_from,
    lifetime_to,
  } = fullSourceInfo;

  const cameraExtId = isExternalCamera(fullSourceInfo)
    ? fullSourceInfo.jexternaldevice_id.xeomaChainId
    : null;

  const extIdDevice = externaldevice_id ?? cameraExtId;

  const extId = extIdDevice ?? external_uuid;
  const typeId = selectedDevice.deviceTypeId;
  const system = isActualDeviceType(typeId) ? SYSTEM_BY_TYPE_ID[typeId] : null;
  const deviceStatusId = isDeviceStatus(external_status_id)
    ? external_status_id
    : null;

  const systemName = findById(systemDict, sc_system_id)?.name ?? NO_NAME;

  const name = sname ?? NO_NAME;

  const sourceInfo = {
    nameSource: {
      key: 'nameSource',
      label: 'Имя источника',
      children: <DescriptionItem id={id} name={name} />,
    },
    dscSource: {
      key: 'dscSource',
      label: 'Описание источника',
      children: isFullInfo ? dsc : null,
    },
    systemName: {
      key: 'systemName',
      label: 'Система',
      children: <DescriptionItem id={sc_system_id} name={systemName} />,
    },
    status: {
      key: 'status',
      label: 'Статус',
      children: <SourceStatus status_id={status_id} isAbsolute={false} />,
    },
    extDevice: {
      key: 'extDevice',
      label: 'Внешнее устройство',
      children:
        extId && system ? (
          <SelectItem
            isMaxLen={false}
            isActiveId={false}
            id={extId}
            system={system}
            deviceStatusId={deviceStatusId}
            caption={name}
          />
        ) : null,
    },
    insertTime: {
      key: 'insertTime',
      label: 'Дата добавления',
      children: isFullInfo ? convertDBdate(insert_time) : null,
    },
    lifetimeFrom: {
      key: 'lifetimeFrom',
      label: 'Время жизни с',
      children: isFullInfo ? convertDBdate(lifetime_from) : null,
    },
    lifetimeTo: {
      key: 'lifetimeTo',
      label: 'Время жизни до',
      children: isFullInfo ? convertDBdate(lifetime_to) : null,
    },
    additionalInfo: {
      key: 'additionalInfo',
      label: 'Дополнительная информация',
      children: jexternaldevice_id ? (
        <JsonAdditional object={jexternaldevice_id} />
      ) : null,
    },
  };

  const fullInfo: DescriptionsProps['items'] = Object.values(sourceInfo).filter(
    (el) => el.children
  );

  return { fullInfo, sourceInfo, cameraExtId, name };
};

export default getSourceInfo;
