import { useQueryClient } from '@tanstack/react-query';
import { FormInstance } from 'antd/lib';
import { useState } from 'react';

import api from '../../../../../../../api';
import { QUERY_KEY } from '../../../../../../../constants/queryConstants';
import useValidateForm from '../../../../../../../hooks/formHooks/useValidateForm';
import { SourceDisplayType } from '../../../../../../../stores/adminPanelStore/constants/constants';
import rootStore from '../../../../../../../stores/rootStore/rootStore';
import notification from '../../../../../../ui-kit/Notification/Notification';
import {
  isExternalCreateProps,
  SourceFormFields,
} from '../../../../models/DeviceAdmin.model';
import preparedDataToConfirm from '../helpers/preparedDataToConfirm';

const { EXTERNAL_LINK_INFO, EXTERNAL_LINKS_INFO } = QUERY_KEY;
const { createSource, updateSource } =
  api.devices.externalLinks.externalLinksApi;
const { SourceEditForm } = SourceDisplayType;

const APP_PATH =
  'Admin.DeviceAdmin.SourceEdit.SourceForm.ConfirmButtons.hooks.useConfirm';

const useConfirm = (form: FormInstance, initialProps: U<SourceFormFields>) => {
  const { selectedDevice, selectedSource, setAdminsData } =
    rootStore.adminPanelStore;

  const [isLoading, setIsLoading] = useState(false);

  const queryClient = useQueryClient();

  const isEdit = !!initialProps;

  const {
    isChangeForm,
    isDisabledConfirm,
    setIsChangeForm,
    handleReset,
    handleClear,
  } = useValidateForm<SourceFormFields>({
    form,
    isPreparedInitial: true,
    transformValues: (values) =>
      preparedDataToConfirm(values, isEdit, initialProps),
    initialProps,
    isEdit,
  });

  const handleSubmit = () => {
    form
      .validateFields()
      .then(async (values) => {
        if (!selectedDevice) return;

        setIsLoading(true);

        const data = preparedDataToConfirm(values, isEdit, initialProps);

        if (!data) {
          notification.error('FORM_ERROR');

          return console.error('Ошибка обработки полей формы');
        }

        const res = isExternalCreateProps(data)
          ? await createSource(selectedDevice.id, data, APP_PATH)
          : await updateSource(
              selectedDevice.id,
              selectedSource?.id ?? null,
              data,
              APP_PATH
            );

        if (!res) return;

        notification.success(
          !isEdit ? 'EXTERNAL_SOURCE_ADD' : 'EXTERNAL_SOURCE_UPDATE'
        );
        form.resetFields();
        setIsChangeForm(false);

        if (isEdit) {
          queryClient.invalidateQueries({ queryKey: [EXTERNAL_LINK_INFO] });
          setAdminsData('selectedSource', {
            id: res.data.id,
            formType: SourceEditForm,
          });

          return;
        }

        setAdminsData('sourceDisplayType', null);

        queryClient.refetchQueries({
          queryKey: [EXTERNAL_LINKS_INFO, selectedDevice],
        });
      })
      .catch(() => {
        notification.error('ERROR');
      })
      .finally(() => setIsLoading(false));
  };

  const onReset = isEdit ? handleReset : handleClear;

  return {
    isLoading,
    isDisabledConfirm,
    isChangeForm,
    onReset,
    handleSubmit,
  };
};

export default useConfirm;
