import { isNumber } from 'lodash';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';

import { findById } from '../../helpers/findBy';
import { getSystemTitle } from '../../helpers/getSystemTitle';
import { TObjMap } from '../../stores/mapDataStore/mapDataStore.model';
import rootStore from '../../stores/rootStore/rootStore';
import { IInfoPanelData, MapActions } from '../../stores/uiStore/uiStore.model';
import { RightPanelType, System } from '../../ts/enums/enums';
import IcoDevice from '../IcoDevice/IcoDevice';
import Detailed, { DetailedType } from '../ui-kit/Detailed/Detailed';

import DetailedSwitcher from './DetailedSwitcher';

interface IActiveObj {
  active: TObjMap;
  system: System;
}

function DetailedMain() {
  const {
    setIsNot,
    infoData,
    isOpenInfoPanel,
    setKeyValue,
    setInfoData,
    setRightPanel,
  } = rootStore.uiStore;

  const { systemsInfo } = rootStore.mapDataStore;
  const { setPointsValue } = rootStore.pointsUdsStore;
  const { openClusterByFeatureId } = rootStore.clustersStore;
  const [detailedDataObject, setDetailedDataObject] =
    useState<IInfoPanelData>();
  const [detailed, setDetailed] = useState<DetailedType>();
  const [activeObj, setActiveObj] = useState<IActiveObj>();

  useEffect(() => {
    if (!infoData || !isNumber(infoData?.id)) return;

    const { id, system } = infoData;

    setDetailedDataObject(infoData);

    const systemInfo = systemsInfo[system];

    const activeItem = findById(systemInfo.data, id);

    if (!systemInfo.isHaveDetailed || !activeItem) {
      setIsNot('isDetailedForm', false);
      setInfoData(infoData, MapActions.Center);
      setRightPanel(RightPanelType.InfoPanel);

      return;
    }

    setActiveObj({ active: activeItem, system });
  }, [systemsInfo, infoData, setIsNot, setInfoData, setRightPanel]);

  useEffect(() => {
    if (!activeObj) return;

    const { active, system } = activeObj;

    const detailedProps: DetailedType = {
      headerIco: <IcoDevice id={active.id} system={system} />,
      header: `${getSystemTitle(system, active)} ${active.deviceInfo.caption}`,
      onClose: () => {
        setIsNot('isDetailedForm', false);

        setPointsValue('isShowPhaseCircle', true);
        if (isOpenInfoPanel)
          return openClusterByFeatureId(active.id, system, {
            isNeedOpenFeature: true,
            featureCoord: [active.longitude, active.latitude],
          });

        setInfoData(null);
      },
    };

    setDetailed(detailedProps);
  }, [
    activeObj,
    isOpenInfoPanel,
    setIsNot,
    setKeyValue,
    setInfoData,
    openClusterByFeatureId,
    setPointsValue,
  ]);

  if (!infoData || !detailed || !detailedDataObject) {
    return null;
  }

  return (
    <Detailed {...detailed}>
      <DetailedSwitcher detailedData={detailedDataObject} />
    </Detailed>
  );
}

export default observer(DetailedMain);
