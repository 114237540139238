import { PaginationConfig } from 'antd/lib/pagination';
import { DefaultOptionType } from 'antd/lib/select';

export const TRAFFIC_LAYER_LINK = 'traffic.link.layer';
export const TRAFFIC_LAYER_VERTEX = 'traffic.vertex.layer';
export const TRAFFIC_LAYER_VERTEX_SELECTED = 'traffic.vertex.selected.layer';
export const TRAFFIC_LAYER_VERTEX_TEXT = 'traffic.vertex.text.layer';

export const DEFAULT_SCENE_ZOOM = 11;

export const MAX_NAME_CHARS_LENGTH = 25;
export const TEXT_LAYER_LABEL_SIZE = 10;
export const TEXT_LAYER_OFFSET_LABEL: [number, number] = [25, 0];
export const TEXT_LAYER_PADDING: [number, number, number, number] = [
  1, 1, 1, 1,
];
export const TEXT_LAYER_FONT_COLOR: [number, number, number] = [0, 0, 0];

export const LINK_MIN_WIDTH = 0.1;
export const LINK_MAX_WIDTH = 10;

export const VERTEX_EDGES = 6;
export const VERTEX_COLOR: [number, number, number] = [255, 0, 0];
export const VERTEX_COLOR_SELECTED: [number, number, number] = [0, 0, 255];
export const VERTEX_RADIUS = 30;
export const VERTEX_RADIUS_SELECTED = 60;

export const NODE_MERGE_OPTION_ATTRIBUTE = 'merge.type.attribute';
export const NODE_MERGE_OPTION_LAYER = 'merge.type.layer';

export const NODE_MERGE_OPTIONS: DefaultOptionType[] = [
  { label: 'По идентфикатору перекрёстка', value: NODE_MERGE_OPTION_ATTRIBUTE },
  { label: 'По геометрии слоя c зонами', value: NODE_MERGE_OPTION_LAYER },
];

export const RADIUS_INF_CURVE = 6;

export const LAYER_ID = 'VrnZones_tmp';
export const POLYGONS_TEST_SERVICE_URL =
  'https://gis.eputs.org/feature-server/gis/osut/main/' + LAYER_ID;
export const DEFAULT_BBOX = `{"xmin":0,"ymin":0,"xmax":10000000000,"ymax":1000000000,"spatialReference":{"wkid":102100}}`;
export const MAX_RESULTS_FROM_SERVICE = 1000;
export const DEFAULT_WHERE = '1=1';

export const START_NODE_COLOR: [number, number, number, number] = [
  200, 0, 20, 80,
];

export const TARGET_NODE_COLOR: [number, number, number, number] = [
  30, 200, 230, 230,
];

export const MIN_TILT = 40;

export const DEFAULT_PAGE_SETTINGS: PaginationConfig = {
  hideOnSinglePage: true,
  pageSize: 10,
};

export const PANEL_STYLE = {
  padding: 10,
};

export const DIRECTION_ALL = 1;
export const DIRECTION_FROM = 2;
export const DIRECTION_TO = 3;

export const DIRECTION_ALL_TITLE = 'Проезды между узлами во всех направлениях';
export const DIRECTION_FROM_TITLE = 'Проезды от выбранного узла';
export const DIRECTION_TO_TITLE = 'Проезды к выбранному узлу';
