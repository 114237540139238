import { isNumber, isString } from 'lodash';

import { VideoCameraProps } from '../../../../../../../VideoCamera/models/VideoCamera.model';

interface CameraDataProps {
  systemId: U<number>;
  cameraExtId: Nullish<string>;
  name: U<string>;
  dsc: Nullish<string>;
}

const getCameraDataInfo = ({
  systemId,
  cameraExtId,
  name,
  dsc,
}: CameraDataProps): N<VideoCameraProps> => {
  if (!isNumber(systemId) || !isString(cameraExtId)) return null;

  return {
    dsc: dsc ?? 'Описание отсутствует',
    caption: name ?? 'Название отсутствует',
    deviceId: cameraExtId,
    systemId,
    isMapAction: false,
  };
};

export default getCameraDataInfo;
