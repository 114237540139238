import { isObject } from 'lodash';
import { ReactNode } from 'react';

import { BasicTableColumn, DataType } from '../../../ts/models/table.model';
import { TPartialConfirm } from '../ButtonOnlyIcon/model/buttonIcon.model';
import { PopoverProps } from '../Popover/Popover';

export interface IExport<T extends Record<K, ValueOf<T>>, K extends keyof T> {
  data: T[];
  headers?: BasicTableColumn<K>[];
  title: string;
  popconfirmProps?: TPartialConfirm;
  tip?: ReactNode;
  popoverProps?: Omit<PopoverProps, 'content'> & {
    content?: ReactNode;
  };
  isDisabled?: boolean;
  children?: ReactNode;
}

export interface IChangeExportType {
  value: FileExtensions;
  onChange: (value: FileExtensions) => void;
}

export enum FileExtensions {
  xlsx = 'xlsx',
  xlsxService = `xlsx (сервис)`,
  csv = 'csv',
}

export const isHeaderItem = (
  value: unknown
): value is BasicTableColumn<keyof DataType>[] =>
  isObject(value) && Object.hasOwn(value, 'referens');
