import { Form } from 'antd';
import { FormInstance } from 'antd/lib';
import { isNumber } from 'lodash';
import { useEffect } from 'react';

import tryCatchWrapper from '../../../../../../../api/tryCatchWrapper';
import rootStore from '../../../../../../../stores/rootStore/rootStore';
import { AddFormFields } from '../../../../models/DeviceAdmin.model';
import { FormKeys } from '../../constants/constants';
import {
  getCoordSpecialRules,
  getFeatureFormCoord,
} from '../../helpers/validationHelpers';

const { Longitude, Latitude } = FormKeys;

let timeoutId: NodeJS.Timeout;

const useFormValues = (form: FormInstance<AddFormFields>) => {
  const { adminDeviceCoord, setAdminsData } = rootStore.adminPanelStore;

  const values = Form.useWatch([], form);

  // set feature to coords from form
  useEffect(() => {
    tryCatchWrapper(
      async () => {
        clearTimeout(timeoutId);

        const coords = getFeatureFormCoord(form);

        await getCoordSpecialRules(Longitude, form).validator();
        await getCoordSpecialRules(Latitude, form).validator();

        const long = Number(coords?.long);
        const lat = Number(coords?.lat);

        if (isNaN(long) || isNaN(lat)) return;
        timeoutId = setTimeout(() => {
          setAdminsData('adminDeviceCoord', [long, lat]);
        }, 500);
      },
      { isIgnoreError: true }
    );
  }, [form, setAdminsData, values]);

  // set click to map coords to form
  useEffect(() => {
    if (!adminDeviceCoord) return;
    const lon = adminDeviceCoord?.at(0);
    const lat = adminDeviceCoord?.at(1);

    if (!isNumber(lon) || !isNumber(lat)) return;

    form.setFieldValue(Longitude, lon);
    form.setFieldValue(Latitude, lat);
  }, [form, adminDeviceCoord]);

  return null;
};

export default useFormValues;
