import { Select } from 'antd';
import { SelectProps } from 'antd/lib';
import { observer } from 'mobx-react-lite';
import { FC, useMemo } from 'react';

import rootStore from '../../../../../stores/rootStore/rootStore';
import Popover, { PopoverProps } from '../../../../ui-kit/Popover/Popover';
import {
  ProgramsInfo,
  SelectedPlan,
} from '../../PlanSelect/models/plansControl.model';
import { getSelectLabels } from '../../TemporaryPlan/helpers/getSelectLabels';

import styles from './SelectPlanNumber.module.scss';

interface SelectPlanNumberProps {
  id: number;
  programInfo: ProgramsInfo;
  selectedPlan: SelectedPlan;
  setSelectedPlan: SetState<SelectedPlan>;
}

const SelectPlanNumber: FC<SelectPlanNumberProps> = ({
  id,
  programInfo,
  selectedPlan,
  setSelectedPlan,
}) => {
  const { interfaceTipsDelay } = rootStore.uiStore;
  const { isActiveControlBtn } = rootStore.detailedStore;

  const { isPlansLoading, programs, sgnProgramValues } = programInfo;

  const options = useMemo(
    () =>
      getSelectLabels({
        programs,
        sgnProgramValues,
        id,
      }),
    [id, programs, sgnProgramValues]
  );

  const onChangeSelect = (value: string, option: any) => {
    const phaseGroupNum = Number(option.key);

    setSelectedPlan({
      label: `Выбранный план: ${value}`,
      planId: phaseGroupNum,
    });
  };

  const selectProps: SelectProps = {
    className: styles.select,
    showSearch: true,
    placeholder: 'Выберите базовый план',
    optionFilterProp: 'children',
    onChange: onChangeSelect,
    filterOption: (input: string, option: any) =>
      (option?.value ?? '').toLowerCase().includes(input.toLowerCase()),
    options,
    value: selectedPlan.label,
    disabled: !isActiveControlBtn,
    loading: isPlansLoading,
    getPopupContainer: (trigger) => trigger.parentElement,
  };

  const popoverProps: PopoverProps = {
    content: 'Обязательное поле для заполнения',
    placement: 'right',
    tipsDelay: interfaceTipsDelay,
  } as const;

  return (
    <Popover {...popoverProps}>
      <div className={styles.spaceHorizontal}>
        <h4>Выберите план: * </h4>
        <Select {...selectProps} />
      </div>
    </Popover>
  );
};

export default observer(SelectPlanNumber);
