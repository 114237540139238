import { uniqueId } from 'lodash';
import { observer } from 'mobx-react-lite';
import { FC, useCallback, useEffect, useState } from 'react';

import eventBus from '../../../eventBus';
import { TableData, TableKeys } from '../../../ts/models/table.model';
import { DetailedItemProps } from '../../DetailedMain/DetailedWrapper/constants/constants';
import Table from '../../ui-kit/Table/Table';

import { getPlannedCommandsArray } from './helpers/getPlannedCommandsArray';

const { PlannedCmd } = TableKeys;

const APP_PATH = '.TrafficLightDetailed.TlPlanning';

const TlPlanning: FC<DetailedItemProps> = ({ id }) => {
  const [plannedCommandsTableData, setPlannedCommandsTableData] =
    useState<N<TableData>>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getPlannedCommands = useCallback(async () => {
    setIsLoading(true);
    const commands = await getPlannedCommandsArray({
      id,
      dateIndexFrom: 0,
      appPath: APP_PATH,
      isNeedValidate: false,
    });

    setIsLoading(false);

    if (!commands) {
      return;
    }

    const dataRows = commands.dataRows.map((el) => ({
      ...el,
      key: uniqueId(),
    }));

    setPlannedCommandsTableData({ ...commands, dataRows });
  }, [id]);

  useEffect(() => {
    const unsubscribe = eventBus.phaseHoldChange.subscribe(getPlannedCommands);

    return () => unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getPlannedCommands();
  }, [getPlannedCommands]);

  return (
    <div>
      {plannedCommandsTableData ? (
        <Table
          tableData={plannedCommandsTableData}
          tableConfigType={PlannedCmd}
          isLoading={isLoading}
          refreshData={getPlannedCommands}
          title="Запланированные команды"
        />
      ) : null}
    </div>
  );
};

export default observer(TlPlanning);
