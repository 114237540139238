import { useEffect, useRef } from 'react';

import rootStore from '../../../../../stores/rootStore/rootStore';

const useOutsideAlert = (setIsOpenTutorialTooltip: SetState<U<boolean>>) => {
  const { isActiveControlBtn } = rootStore.detailedStore;

  const wrapperRef = useRef<HTMLDivElement>(null);

  const handleClickManageCard = () => {
    !isActiveControlBtn && setIsOpenTutorialTooltip(!isActiveControlBtn);
  };

  useEffect(() => {
    const handleClickOutside = (event: { target: N<EventTarget> }) => {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target as N<Node>)
      ) {
        setIsOpenTutorialTooltip(undefined);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef, setIsOpenTutorialTooltip]);

  return { wrapperRef, handleClickManageCard };
};

export default useOutsideAlert;
