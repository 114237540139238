import { Form, type PopoverProps, type FormItemProps } from 'antd';
import { FC, ReactNode } from 'react';

import { TipType } from '../../../../stores/uiStore/uiStore.model';
import Popover from '../../../ui-kit/Popover/Popover';

import styles from './Item.module.scss';

export interface ItemProps extends FormItemProps {
  children: ReactNode;
  tipsDelay?: TipType;
  content?: PopoverProps['content'];
}

const Item: FC<ItemProps> = ({
  label,
  children,
  validateStatus,
  content,
  tipsDelay,
  help,
  required,
  style,
}) => (
  <Form.Item
    label={label}
    className={styles.formItem}
    validateStatus={validateStatus}
    help={help}
    required={required}
    style={{
      marginBottom: '7px',
      ...style,
    }}
  >
    <Popover placement="right" tipsDelay={tipsDelay} content={content}>
      {children}
    </Popover>
  </Form.Item>
);

export default Item;
