import SourceEdit from '../../SourceEdit/SourceEdit';
import AdditionalContent from '../AdditionalContent/AdditionalContent';

export const ADDITIONAL_ITEMS = [
  {
    label: 'Дополнительная информация',
    key: 'additionalInfo',
    children: <AdditionalContent />,
  },
  {
    label: 'Источники',
    key: 'source',
    children: <SourceEdit />,
  },
];
