import { isNil } from 'lodash';
import { makeAutoObservable, flow } from 'mobx';

import { Path } from '../../api/api.model';
import { CoordTL } from '../../api/services/tlCoordination/coordGroup/coordGroupApi.model';
import { ProfileParamItem } from '../../api/services/tlCoordination/coordGroup/profiles/profilesApi.model';
import {
  GroupCheckRes,
  TLCoordinationData,
  WaveType,
} from '../../api/services/tlCoordination/tlCoordination.model';
import tlCoordinationApi from '../../api/services/tlCoordination/tlCoordinationApi';
import findElementWithIdx from '../../helpers/findElementWithIdx';
import { SetKeyValueWithId } from '../constructorStore/constructorStore.model';
import RootStore from '../rootStore/rootStore';
import { StoreUtils } from '../storeUtils/storeUtils';

import { CoordProfile, TCoordGroup } from './coordinatedTrafficStore.model';

const { fetchCoordinationDiagram } = tlCoordinationApi;

class CoordinatedTrafficStore {
  rootStore;
  isTrafficDetailed = false;
  coordGroup: TCoordGroup = null;
  groupCheckRes: N<GroupCheckRes> = null;
  profile: CoordProfile = null;
  profileParams: ProfileParamItem[] = [];
  selectedTls: CoordTL[] = [];
  offset = 0;
  coordinationData: U<TLCoordinationData>;
  waveType: WaveType = 1;
  time: N<number> = null;

  setTrafficValue;
  setTrafficValues;
  clearStore;

  constructor(rootStore: typeof RootStore) {
    makeAutoObservable(this, {
      rootStore: false,
      fetchCoordinationDiagram: flow.bound,
    });
    this.rootStore = rootStore;

    const utils = new StoreUtils(this);

    this.setTrafficValue = utils.setKeyValue;
    this.setTrafficValues = utils.setKeysValues;
    this.clearStore = utils.clearStore;
  }

  setSelectedTL: SetKeyValueWithId<CoordTL> = (key, value, id) => {
    const { selectedTls } = this;

    const { el, idx } = findElementWithIdx(selectedTls, id);

    if (!el || isNil(idx)) return;
    el[key] = value;

    this.selectedTls = [
      ...selectedTls.slice(0, idx),
      el,
      ...selectedTls.slice(idx + 1),
    ];
  };

  setProfileParams: SetKeyValueWithId<ProfileParamItem> = (key, value, id) => {
    const { profileParams } = this;

    const { el, idx } = findElementWithIdx(profileParams, id);

    if (isNil(idx) || !el) return;
    const newEl = { ...el, [key]: value };

    this.profileParams = [
      ...profileParams.slice(0, idx),
      newEl,
      ...profileParams.slice(idx + 1),
    ];
  };

  *fetchCoordinationDiagram(path: Path) {
    const { coordGroup, waveType, time, profileParams, profile } = this;

    if (!coordGroup?.uuid || isNil(profile)) return;
    const { id, name } = profile;

    const data: U<TLCoordinationData> = yield fetchCoordinationDiagram(
      coordGroup.uuid,
      waveType,
      { id, name, time, params: profileParams },
      path
    );

    this.coordinationData = data;
  }

  get coordTimes() {
    return this.groupCheckRes?.coordination.coordination_times;
  }

  clear = () => {
    const { setScriptControlValue } = this.rootStore.scriptsControlStore;

    setScriptControlValue('selectedFeatureSomeArray', []);
    this.clearStore();
  };
}

export default CoordinatedTrafficStore;
