import {
  lineOffset,
  lineString,
  toMercator,
  toWgs84,
  length as turfLength,
} from '@turf/turf';
import { LineString, Point } from 'ol/geom';
import { Icon, Stroke, Style } from 'ol/style';

import {
  ARROW_MIN_RESOLUTION,
  ARROW_MIN_SEGMENT_DISTANCE,
  ARROW_SCALE,
  OFFSET_DISTANCE,
  TRAFFIC_ARROWS,
  TRAFFIC_BACKGROUND_PROPS,
  TRAFFIC_FACTOR_LIMITS,
  TRAFFIC_FACTOR_PROPERTY_NAME,
} from '../../constants/styles';
import { VectorStyleItem } from '../../requests/getVectorTileStyle';

import { getStyle } from './index';

const styleFunction = (styles: VectorStyleItem[]) => {
  const mem: Record<string, Style | undefined> = {};

  return (feature: any, resolution: number): Style | undefined => {
    const properties = feature.getProperties();

    const featureKey = `${Object.keys(properties)
      .map((key) => properties[key])
      .join('_')}_${resolution}`;

    if (mem[featureKey]) {
      return mem[featureKey];
    }

    const item: VectorStyleItem | undefined = styles.find(
      (element: VectorStyleItem) => {
        const { filter } = element;

        for (const key in filter) {
          const value = filter[key];

          if (properties[key] !== value) {
            return false;
          }
        }

        return true;
      }
    );

    if (!item) {
      return undefined;
    }

    const { style } = item;

    const value: Style | undefined = getStyle({
      params: style,
      feature,
      resolution,
    });

    mem[featureKey] = value;

    return value;
  };
};

const styleTrafficFunction = (styles: VectorStyleItem[]) => {
  return (feature: any, resolution: number): Style[] | Style | undefined => {
    const properties = feature.getProperties();

    const item: VectorStyleItem | undefined = styles.find(
      (element: VectorStyleItem) => {
        const { filter } = element;

        for (const key in filter) {
          const value = filter[key];

          if (properties[key] !== value) {
            return false;
          }
        }

        return true;
      }
    );

    if (!item) {
      return undefined;
    }

    const geometry = feature.getGeometry();

    if (!geometry) {
      return undefined;
    }

    const coordinates = geometry.getFlatCoordinates();
    const outCoordinates = [];

    for (let i = 0; i < coordinates.length; i += 2) {
      outCoordinates.push([coordinates[i], coordinates[i + 1]]);
    }

    const turfLineString = toWgs84(lineString(outCoordinates));
    const offsetLineString = toMercator(
      lineOffset(turfLineString, OFFSET_DISTANCE, { units: 'meters' })
    );
    const outGeometry = new LineString(offsetLineString.geometry.coordinates);

    const { style } = item;

    const serviceStyle: Style | undefined = getStyle({
      params: style,
      feature,
      resolution,
    });

    if (!serviceStyle) {
      return undefined;
    }

    const lineStyle = new Style({
      // @ts-ignore
      stroke: serviceStyle.getStroke(),
      geometry: outGeometry,
    });

    const backgroundLineStyle = new Style({
      stroke: new Stroke(TRAFFIC_BACKGROUND_PROPS),
      geometry: outGeometry,
    });

    const outOffsetCoords = offsetLineString.geometry.coordinates;

    const [x1, y1] = outOffsetCoords[outOffsetCoords.length - 2];
    const [x2, y2] = outOffsetCoords[outOffsetCoords.length - 1];

    const dx = x2 - x1;
    const dy = y2 - y1;

    const distance = turfLength(turfLineString, { units: 'meters' });

    let arrowStyle = null;
    let arrowImg = TRAFFIC_ARROWS.NORMAL;

    const trafficFactor = properties[TRAFFIC_FACTOR_PROPERTY_NAME];

    if (
      trafficFactor > TRAFFIC_FACTOR_LIMITS.NORMAL &&
      trafficFactor <= TRAFFIC_FACTOR_LIMITS.SLOW
    ) {
      arrowImg = TRAFFIC_ARROWS.SLOW;
    }

    if (trafficFactor > TRAFFIC_FACTOR_LIMITS.SLOW) {
      arrowImg = TRAFFIC_ARROWS.VERY_SLOW;
    }

    if (
      distance > ARROW_MIN_SEGMENT_DISTANCE &&
      resolution < ARROW_MIN_RESOLUTION
    ) {
      const rotation = -Math.atan2(dy, dx);

      arrowStyle = new Style({
        geometry: new Point([x2, y2]),
        image: new Icon({
          src: arrowImg,
          anchor: [0.5, 0.5],
          rotateWithView: true,
          scale: ARROW_SCALE,
          rotation,
        }),
      });
    }

    return arrowStyle
      ? [arrowStyle, backgroundLineStyle, lineStyle]
      : [backgroundLineStyle, lineStyle];
  };
};

export { styleFunction, styleTrafficFunction };
