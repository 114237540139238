import { Input, Form } from 'antd';
import { FC } from 'react';

import {
  NAME_LENGTH,
  TEXT_AREA_PROPS,
} from '../../../constants/constructorConstants';
import {
  InputEl,
  TextArea,
} from '../../Panel/ConstructorPanel/ActionBtns/ActionBtnsModal';
import { Item } from '../UIKitConstructor';

interface PointUdsProps {
  pointUdsName: string;
  pointUdsDsc: string;
  setPointUdsName: SetState<string>;
  setPointUdsDsc: SetState<string>;
  isNotUniqueName: boolean;
}

const PointUdsConstructor: FC<PointUdsProps> = ({
  pointUdsName,
  pointUdsDsc,
  setPointUdsName,
  setPointUdsDsc,
  isNotUniqueName,
}) => {
  const handlePointUdsName = (e: InputEl) => setPointUdsName(e.target.value);
  const handlePointUdsDsc = (e: TextArea) => setPointUdsDsc(e.target.value);

  return (
    <Form layout="vertical">
      <Item
        label="Введите имя точки УДС:"
        help={isNotUniqueName && 'Введено не уникальное имя'}
        validateStatus={isNotUniqueName ? 'error' : ''}
        required
      >
        <Input
          value={pointUdsName}
          onChange={handlePointUdsName}
          maxLength={NAME_LENGTH}
          allowClear
          showCount
        />
      </Item>

      <Item label="Описание для точки УДС:">
        <Input.TextArea
          {...TEXT_AREA_PROPS}
          value={pointUdsDsc}
          onChange={handlePointUdsDsc}
        />
      </Item>
    </Form>
  );
};

export default PointUdsConstructor;
