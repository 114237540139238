import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import rootStore from '../../../../../../../stores/rootStore/rootStore';
import Popover, {
  PopoverProps,
} from '../../../../../../ui-kit/Popover/Popover';

interface StatusSelectItemProps {
  name: string;
  dsc: N<string>;
}

const StatusSelectItem: FC<StatusSelectItemProps> = ({ name, dsc }) => {
  const { interfaceTipsDelay } = rootStore.uiStore;

  const popoverProps: PopoverProps = {
    content: dsc,
    placement: 'left',
    tipsDelay: interfaceTipsDelay,
  };

  return <Popover {...popoverProps}>{name}</Popover>;
};

export default observer(StatusSelectItem);
