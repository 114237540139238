import { useQuery } from '@tanstack/react-query';

import {
  DEFAULT_STALE_TIME,
  QUERY_KEY,
} from '../../../../constants/queryConstants';
import { Path } from '../../../api.model';
import { CheckGroup } from '../tlCoordination.model';

const useCheckGroupQuery = (
  tlsIds: number[],
  path: Path,
  checkGroup: CheckGroup
) =>
  useQuery({
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: [QUERY_KEY.COORD_GROUP_CHECK, tlsIds],
    queryFn: async () => await checkGroup(tlsIds, path),
    staleTime: DEFAULT_STALE_TIME,
  });

export default useCheckGroupQuery;
