/* eslint-disable camelcase */
import { VERSION } from '../../../../constants/versions';
import { getAppPathHeaders } from '../../../helpers/getAppPathHeaders';
import http from '../../../http';
import tryCatchWrapper from '../../../tryCatchWrapper';

const GRAPH_API = `${VERSION}/integration/gis`;
const SERVICES_API = `${VERSION}/services/dt-analytics/vehicle`;

const REQUEST_PARAMS = {
  isExternalError: true,
  errorMessage: 'Ошибка получения доступных узлов',
  ignoreCodes: [423],
};

const OD_MATRIX_TIMEOUT = 100_000;

const graphApi = {
  getVertexes: (appPath: string) =>
    tryCatchWrapper(async () => {
      const res = await http.post(`${GRAPH_API}/vehicle-source`, {
        headers: getAppPathHeaders(appPath),
        timeout: OD_MATRIX_TIMEOUT,
      });

      return res?.data;
    }, REQUEST_PARAMS),

  getODDataset: (
    systemId: string,
    regionId: string | number,
    dateStart: string,
    dateEnd: string
  ) =>
    tryCatchWrapper(
      async () => {
        const requestUrl = `${SERVICES_API}/ODMatrix?regionId=${regionId}&dateFrom=${dateStart}.000&dateTo=${dateEnd}.000`;

        const res = await http.get(requestUrl, {
          timeout: OD_MATRIX_TIMEOUT,
        });

        return res?.data;
      },
      {
        ...REQUEST_PARAMS,
        errorMessage: 'Ошибка получения доступных связей',
        ignoreCodes: [423],
      }
    ),
};

export default graphApi;
