import { ILayer } from '../../../../stores/gisDataStore/gisDataStore.model';
import { IIdentifyItem } from '../../../../ts/models/gis/identify.model';

export interface IFeatureProperty {
  text: string;
  alias?: string;
  value: U<string | number | boolean>;
  type?: string;
}

export const getItemProperties = (
  item: U<IIdentifyItem>,
  layerDefinition: ILayer | undefined
): IFeatureProperty[] => {
  if (!item) {
    return [];
  }

  const fieldsDefinitions = layerDefinition?.fields || [];

  const { feature } = item;

  const properties = feature.getProperties();

  const results: IFeatureProperty[] = [];

  for (const key in properties) {
    const value = properties[key];
    const fieldDefinition = fieldsDefinitions
      ? fieldsDefinitions.find((element) => element.name === key)
      : null;
    const alias = fieldDefinition?.alias;

    if (typeof value !== 'object') {
      results.push({
        text: key,
        alias,
        value: String(value) || 'нет данных',
      });
    }
  }

  return results;
};
