import { Button } from 'antd';
import { FormInstance } from 'antd/lib';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import rootStore from '../../../../../../../stores/rootStore/rootStore';
import { AddFormFields } from '../../../../models/DeviceAdmin.model';
import { BUTTON_TEXT } from '../../constants/constants';

import useButtons from './hooks/useButtons';

import styles from './Buttons.module.scss';

interface ButtonsProps {
  form: FormInstance;
  initialProps: U<AddFormFields>;
}

const Buttons: FC<ButtonsProps> = ({ form, initialProps }) => {
  const { deviceFormType, isEditForm } = rootStore.adminPanelStore;

  const { isDisabledConfirm, isLoading, isChangeForm, handleSubmit, onReset } =
    useButtons(form, initialProps);

  if (!deviceFormType) return null;

  const { submit: submitText, reset: resetText } = BUTTON_TEXT[deviceFormType];

  return (
    <div className={classNames(styles.btns, { [styles.edit]: isEditForm })}>
      <Button onClick={onReset} disabled={!isChangeForm}>
        {resetText}
      </Button>
      <Button
        type="primary"
        onClick={handleSubmit}
        disabled={isDisabledConfirm}
        loading={isLoading}
      >
        {submitText}
      </Button>
    </div>
  );
};

export default observer(Buttons);
