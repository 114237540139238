import { Path } from '../../../api.model';
import { getAppPathHeaders } from '../../../helpers/getAppPathHeaders';
import http from '../../../http';
import tryCatchWrapper from '../../../tryCatchWrapper';
import { COORDINATION } from '../tlCoordinationApi';

import { CoordGroup } from './coordGroupApi.model';
import useGroupsListQuery from './query/useGroupsListQuery';

export const GROUP = COORDINATION + '/coord-group';

class CoordGroupApi {
  fetchCoordGroupsList = (regionId: Nullish<number>, path: Path) =>
    tryCatchWrapper(async () => {
      const res = await http.get<CoordGroup[]>(GROUP, {
        headers: getAppPathHeaders(path),
        params: { regionId },
      });

      return res.data;
    });

  useCoordGroupsList = (regionId: Nullish<number>, path: Path) =>
    useGroupsListQuery(regionId, path, this.fetchCoordGroupsList);

  createCoordGroupsItem = (group: Omit<CoordGroup, 'uuid'>, path: Path) =>
    tryCatchWrapper(async () => {
      const res = await http.post<CoordGroup>(GROUP, group, {
        headers: getAppPathHeaders(path),
      });

      return res.data;
    });

  updateCoordGroupsItem = (
    group: Omit<CoordGroup, 'uuid'>,
    uuid: string,
    path: Path
  ) =>
    tryCatchWrapper(async () => {
      const res = await http.put<CoordGroup>(GROUP + `/${uuid}`, group, {
        headers: getAppPathHeaders(path),
      });

      return res?.data;
    });

  deleteCoordGroupsItem = (uuid: string, path: Path) =>
    tryCatchWrapper(async () => {
      const res = await http.delete(GROUP + `/${uuid}`, {
        headers: getAppPathHeaders(path),
      });

      return res?.data;
    });

  stopCoordGroup = (uuid: string, appPath: Path) =>
    tryCatchWrapper(async () => {
      const res = await http.patch(GROUP + `/${uuid}/stop`, {
        headers: getAppPathHeaders(appPath),
      });

      return res;
    });
}

const coordGroupApi = new CoordGroupApi();

export default coordGroupApi;
