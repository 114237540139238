import { observer } from 'mobx-react-lite';

import { ReactComponent as PhaseCircleBtn } from '../../../../../assets/icons/signs/phase_circle.svg';
import rootStore from '../../../../../stores/rootStore/rootStore';
import ButtonIco from '../../../../ui-kit/ButtonIco/ButtonIco';
import Popover, { PopoverProps } from '../../../../ui-kit/Popover/Popover';
import { PLACEMENT_MAP_BTNS } from '../../../LayersButton/LayersButton';

const CircleBtn = () => {
  const { mapButtonsTipsDelay } = rootStore.uiStore;
  const { isPhaseCircle, setPointsValue, isShowPhaseCircle } =
    rootStore.pointsUdsStore;

  const handlePhaseCircle = () =>
    setPointsValue('isShowPhaseCircle', !isShowPhaseCircle);

  const popOverProps: PopoverProps = {
    placement: PLACEMENT_MAP_BTNS,
    tipsDelay: mapButtonsTipsDelay,
    content: 'Вкл./Откл. фазового круга',
  };

  return (
    <ButtonIco onClick={handlePhaseCircle} isActive={isPhaseCircle}>
      <Popover {...popOverProps}>
        <PhaseCircleBtn />
      </Popover>
    </ButtonIco>
  );
};

export default observer(CircleBtn);
