export enum DeviceFormType {
  AddForm = 'addForm',
  EditForm = 'editForm',
}

export enum SourceDisplayType {
  SourceAddForm = 'sourceAddForm',
  SourceEditForm = 'sourceEditForm',
  SourceWatchForm = 'sourceWatchForm',
}
