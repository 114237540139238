import { ColumnTypes, TableData } from '../../../ts/models/table.model';

export const DEFAULT_TABLE: TableData = {
  dataRows: [],
  headerItem: [],
};

export const EXPORT_DATA_TEXT = 'Экспортировать данные';

export const DEFAULT_TABLE_VALUES: Partial<Record<ColumnTypes, string>> = {
  string: 'Неизвестно',
  date: 'Не задано',
};
