import { Tabs } from 'antd';
import { observer } from 'mobx-react-lite';
import VectorLayer from 'ol/layer/Vector';
import VectorImageLayer from 'ol/layer/VectorImage';
import { HiArrowLeft } from 'react-icons/hi';

import { TMap } from '../../../../../stores/mapStore/mapStore.model';
import {
  ERROR_LAYER_IS_NOT_EXIST,
  ERROR_LAYER_IS_NOT_SUPPORTED,
  ERROR_MAP_IS_NOT_EXIST,
} from '../../constants';
import { ILayerDataNode } from '../../layers.model';
import styles from '../../LayersButton.module.scss';
import ErrorHandler from '../Error/ErrorHandler';

import LayerSettingsCommon from './LayerSettingsCommon';
import LayerSettingsFilter from './LayerSettingsFilter';
import LayerSettingsLabeling from './LayerSettingsLabeling';
import LayerSettingsStyle from './LayerSettingsStyle';

const LayerSettings = (props: {
  onBack: (evt: any) => void;
  selectedNode: ILayerDataNode;
  map?: TMap;
}) => {
  const { map, selectedNode } = props;

  const id = selectedNode.item?.id;
  const aliasView = selectedNode?.item?.alias || id || 'Выбранный слой';

  if (!map) {
    return ErrorHandler(aliasView, ERROR_MAP_IS_NOT_EXIST, props.onBack);
  }

  if (!id) {
    return ErrorHandler(aliasView, ERROR_LAYER_IS_NOT_EXIST, props.onBack);
  }

  const layer = map
    .getAllLayers()
    .find(
      (element) =>
        element.get('id') === id &&
        (element instanceof VectorLayer || element instanceof VectorImageLayer)
    );

  if (!layer) {
    return ErrorHandler(aliasView, ERROR_LAYER_IS_NOT_SUPPORTED, props.onBack);
  }

  const layerProps = {
    map,
    selectedNode,
  };

  return (
    <div>
      <div className={styles.nodeContainer}>
        <HiArrowLeft
          onClick={props.onBack}
          style={{ marginRight: 5, cursor: 'pointer' }}
        />
        <div>
          {props.selectedNode.item?.alias || props.selectedNode.item?.id}
        </div>
      </div>

      <Tabs
        style={{ marginTop: 0 }}
        items={[
          {
            label: 'Основное',
            key: '1',
            children: <LayerSettingsCommon {...layerProps} />,
          },
          {
            label: 'Подписи',
            key: '2',
            children: <LayerSettingsLabeling {...layerProps} />,
          },
          {
            label: 'Стиль',
            key: '3',
            children: <LayerSettingsStyle {...layerProps} />,
          },
          {
            label: 'Фильтры',
            key: '4',
            children: <LayerSettingsFilter {...layerProps} />,
          },
        ]}
      ></Tabs>
    </div>
  );
};

export default observer(LayerSettings);
