import { type Pixel } from 'ol/pixel';
import { toLonLat } from 'ol/proj';
import { useCallback, useEffect, useState } from 'react';

import { TMap } from '../../../../../../../stores/mapStore/mapStore.model';
import rootStore from '../../../../../../../stores/rootStore/rootStore';
import {
  addVectorLayer,
  setFeatureCoord,
} from '../../../../../../ui-kit/UiMap/helpers/helpers';

const useMapInfo = () => {
  const { adminDeviceCoord, setAdminsData } = rootStore.adminPanelStore;

  const [map, setMap] = useState<TMap>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    map && addVectorLayer(map);
  }, [map]);

  useEffect(() => {
    if (!adminDeviceCoord || !map) return;

    setFeatureCoord(map, adminDeviceCoord);
  }, [map, adminDeviceCoord]);

  useEffect(() => {
    return () => {
      setAdminsData('adminDeviceCoord', null);
    };
  }, [setAdminsData]);

  const handleMapClick = useCallback(
    (event: { pixel: Pixel }) => {
      if (!map) return;

      const clickedCoord = map.getCoordinateFromPixel(event.pixel);

      const transformedCoord = toLonLat(clickedCoord);

      setAdminsData('adminDeviceCoord', transformedCoord);
    },
    [map, setAdminsData]
  );

  return {
    map,
    isLoading,
    handleMapClick,
    setMap,
    handleLoadView: setIsLoading,
  };
};

export default useMapInfo;
