import { IBaseMapItem } from '../../../stores/gisDataStore/gisDataStore.model';

import { MAX_SUBTITLE_LENGTH } from './constants';

export const getSubtitle = (item: IBaseMapItem) => {
  const { text } = item;

  return text && text.length > MAX_SUBTITLE_LENGTH
    ? text.slice(0, MAX_SUBTITLE_LENGTH - 2) + '...'
    : text;
};
