import type { CanvasFilterOptions } from 'ol-ext/filter/CanvasFilter';
import { useEffect, useState } from 'react';

import { getLayer } from '../../../../../apiGIS/layers/utils/getLayer';
import { applyLayerFilter } from '../../../../../apiGIS/utils/filters';
import { TMap } from '../../../../../stores/mapStore/mapStore.model';
import LayerFilter from '../../../../ui-kit/LayerFilter/LayerFilter';
import { ILayerDataNode } from '../../layers.model';

const LayerSettingsFilter = (props: {
  map: TMap;
  selectedNode: ILayerDataNode;
}) => {
  const [layerFilterSettings, setLayerFilterSettings] =
    useState<CanvasFilterOptions>();

  const { map, selectedNode } = props;
  const id = selectedNode.item?.id || '';

  useEffect(() => {
    applyLayerFilter(map, id, layerFilterSettings);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [layerFilterSettings]);

  if (!map || !id) {
    return null;
  }

  const layer = getLayer(map, id);

  if (!layer) {
    return null;
  }

  const updateBasemapSettings = (data: CanvasFilterOptions) => {
    setLayerFilterSettings(data);
  };

  return (
    <LayerFilter
      settings={layerFilterSettings}
      onSettingsChange={updateBasemapSettings}
    ></LayerFilter>
  );
};

export default LayerSettingsFilter;
