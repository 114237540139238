import { SelectProps } from 'antd/lib';

import sortByStr from '../../../../../../helpers/sortByStr';
import rootStore from '../../../../../../stores/rootStore/rootStore';
import { SEPARATE } from '../../../../../Panel/ScriptsControl/SelectModule/DispatchGroupControl/SelectItem/helpers/helpers';
import { CardsContent, isEmptyCard } from '../../../models/DeviceAdmin.model';
import DescriptionItem from '../../SourceEditForm/SourceInfo/DescriptionItem/DescriptionItem';

type SelectionProps = NonNullable<SelectProps['options']>;

const getFormattedKey = (id: number, caption: string) => {
  return `${id}${SEPARATE}${caption}`;
};

const getSourceOptions = (sources: Nullish<CardsContent>) => {
  const { selectedSource } = rootStore.adminPanelStore;

  if (!sources) return null;

  const res = sources.reduce((acc: SelectionProps, item) => {
    if (isEmptyCard(item)) return acc;

    const { id, sname } = item;

    sname &&
      acc.push({
        value: getFormattedKey(id, sname),
        label: (
          <DescriptionItem
            id={id}
            name={sname}
            isActive={selectedSource?.id === id}
          />
        ),
        id: `${id}${SEPARATE}${sname}`,
      });

    return acc;
  }, []);

  return sortByStr(res, 'id');
};

export default getSourceOptions;
