export const COLOR_GIS_WHITE = 'rgb(255, 255, 255)';

export const COLOR_GIS_DIRECTION_RED = 'rgb(255,12,74)';

export const COLOR_DRAPING_LAYER = 'rgb(213,223,201)';

const index = {
  COLOR_GIS_DIRECTION_RED,
  COLOR_GIS_WHITE,
  COLOR_DRAPING_LAYER,
};

export { index };
