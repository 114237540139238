import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import NOTICE from '../../../../../../constants/notificationConstants';
import rootStore from '../../../../../../stores/rootStore/rootStore';
import DefaultChecked from '../../../../../ui-kit/DefaultChecked/DefaultChecked';
import { DeviceData } from '../../../models/DeviceAdmin.model';
import SelectComponent from '../../SelectDevice/SelectComponent/SelectComponent';
import DeviceAddCard from '../DeviceAddCard/DeviceAddCard';

import DeleteButton from './DeleteButton/DeleteButton';
import useDeviceInfo from './hooks/useDeviceInfo';

import styles from './DeviceEditCard.module.scss';

const { DEVICE_INFO_ERROR } = NOTICE;

const DeviceEditCard: FC = () => {
  const { selectedDevice } = rootStore.adminPanelStore;

  const { device, isError, isLoading } = useDeviceInfo();

  const textError = isError ? DEVICE_INFO_ERROR : undefined;

  const { point_uds_profile_uid, ...initialProps } =
    device ?? ({} as DeviceData);

  return (
    <div className={styles.wrapper}>
      <div className={styles.select}>
        <SelectComponent />
      </div>
      <DefaultChecked
        isEmptyData={isError || !device}
        isLoading={isLoading}
        noDataProps={{
          textError: selectedDevice ? textError : 'Устройство не выбрано',
        }}
      >
        <DeviceAddCard
          initialProps={device ? initialProps : undefined}
          pointUdsUid={point_uds_profile_uid}
        />
        <DeleteButton />
      </DefaultChecked>
    </div>
  );
};

export default observer(DeviceEditCard);
