import MVT from 'ol/format/MVT.js';
import VectorTileSource from 'ol/source/VectorTile.js';

export interface SourceProps {
  url: string;
}

function source(props: SourceProps): VectorTileSource {
  return new VectorTileSource({
    url: props.url,
    format: new MVT(),
  });
}

export { source };
