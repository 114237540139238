export const Z_INDEX_LAYER = 10;
export const Z_INDEX_VECTOR_LAYER = 15;
export const Z_INDEX_CLUSTER_LAYER = Z_INDEX_LAYER + 1;
export const Z_INDEX_WEATHER_LAYER = Z_INDEX_LAYER - 1;
export const Z_INDEX_TEXT_LAYER = Z_INDEX_LAYER + 1;
export const Z_INDEX_TEMPORARY_LAYER = 1000;
export const Z_INDEX_DRAPING_LAYER = 1;

export const DRAPING_LAYER_ID = 'invisibles';
export const DRAPING_LAYER_OPACITY = 0.9;
