import { SizeType } from 'antd/lib/config-provider/SizeContext';
import {
  FilterDropdownProps,
  FilterValue,
  Key,
  TablePaginationConfig,
} from 'antd/lib/table/interface';
import dayjs, { Dayjs } from 'dayjs';
import { isNull, isString, isUndefined } from 'lodash';
import { ReactNode } from 'react';

import { LibraryKeysZod } from '../../api/libraries/libraries.zod';

export const ARRAY_TYPE = 'array';

export const VALUE_TYPES = {
  string: 'string',
  number: 'number',
  numberArray: `number.${ARRAY_TYPE}`,
  stringArray: `string.${ARRAY_TYPE}`,
  dateTime: 'dateTime',
  json: 'json',
  datePickerJson: 'datePickerJson',
  rangeDatePickerJson: 'rangeDatePickerJson',
} as const;

interface ElementOptions {
  label: string;
  value: SN | boolean;
}

export interface QueryParamItem {
  jkey: string;
  webElement: WebElementsTypes;
  webElementHint: string;
  associativeKey?: string;
  webElementOptions: N<ElementOptions[]>;
  webElementAssociativeOptions?: Record<string, ElementOptions[]>;
  webElementCapture: string;
  jvalueType: ValueOf<typeof VALUE_TYPES>;
  defaultValue: unknown;
  currentValue: unknown;
  minValue: N<number>;
  maxValue: N<number>;
}

export interface IScroll {
  x?: number | string;
  y?: number | string;
}

export type PaginationOnChange = (key: TableKeys, pageSize: number) => void;

export interface IAdminTableConfig {
  pagination: TablePaginationConfig | false;
  scroll?: IScroll;
  bordered?: boolean;
  size?: SizeType;
}

type ConfigKeysRequired = 'large' | 'middle';

type ConfigKeysOptional = 'scrollX' | 'scrollY' | 'defaultPageSize';

export type TableConfigSettingsValues = Partial<
  Record<ConfigKeysOptional, number>
> &
  Record<ConfigKeysRequired, number>;

export enum TableKeys {
  Users = 'users',
  AdminGroup = 'adminGroup',
  AdminGroupSettings = 'adminGroupSettings',
  AdminDevicesTable = 'adminDevicesTable',
  Equipment = 'equipment',
  Manufacturers = 'manufacturers',
  Extended = 'extended',
  Organizations = 'organizations',
  DivisionsTable = 'divisionsTable',
  UserAccessTable = 'userAccessTable',
  Versions = 'versions',
  Reports = 'reports',
  TLJournal = 'tlJournal',
  PlannedCmd = 'plannedCmd',
}

export interface IFilterValue {
  [key: string]: FilterValue;
}

export type IFilteredInfo = N<Record<string, FilterValue | null>>;

export interface IFilter {
  text: string;
  value: boolean | string | number;
}

export interface IFiltersSettings {
  sorted: boolean;
  searched: boolean;
  filters: N<IFilter[]>;
}

export interface DataType extends OWKS<any> {
  key: string;
}

export interface ICollaboration {
  deviceName: string;
  eputsDeviceId: N<number>;
  latitude: number;
  longitude: number;
  scSystemId: number;
  scSystemName: string;
  statusId: number;
  tlId: number;
  srcId: string;
  name: string;
  streamUrl: string;
  id: string | number;
  isChange: boolean;
  isDelete: boolean;
  play_ant_url: string;
  region_id: number;
  device_type_id: number;
  key?: string;
  collaboration?: ICollaboration;
}

export interface BasicTableColumn<T> {
  title: string;
  type?: string;
  dataIndex?: string;
  referens: T;
}

export interface SorterFunc {
  compare: (a: Record<string, unknown>, b: Record<string, unknown>) => number;
  multiple: number;
}

export interface ITableColumn extends BasicTableColumn<string> {
  fixed?: any;
  access?: boolean;
  id?: number;
  type: ColumnTypes;
  visible: boolean;
  visibleNumber?: number;
  readonly: boolean;
  dataIndex: string;
  render?:
    | ((
        data: string | number | boolean | string[] | number[]
      ) => React.ReactNode)
    | ((collaboration: ICollaboration) => JSX.Element)
    | ((collaboration: number) => JSX.Element);
  ellipsis?: {
    showTitle: boolean;
  };
  key?: string | number;
  filters?: IFilter[];
  onFilter?: (value: string | number | boolean, record: any) => boolean;
  width?: number;
  filterMode?: 'menu' | 'tree' | undefined;
  filterSearch?: boolean;
  sorter?: SorterFunc;
  filterDropdown?: (props: FilterDropdownProps) => ReactNode;
  filterIcon?: (param: boolean) => ReactNode;
  filteredValue?: N<(boolean | Key)[]>;
  onFilterDropdownOpenChange?: (param: boolean) => void;
  accessId?: number;
  filterSettings?: IFiltersSettings;
  link?: string;
  multiple?: number;
  viewValueLibraryKey?: string;
}

export interface IDateSearch {
  onChange: (value: any, dateString: string[]) => void;
  showTime: any;
  format: string;
  disabledDate: (current: any) => any;
  disabledTime?: (date: any) => any;
  placeholder: [string, string];
}

export interface IServerSearch {
  onSearch: (value: string) => void;
  placeholder: string;
  loading: boolean;
}

export interface IIteractionButton {
  text: string;
  handler: () => void;
}

export interface IOption {
  label: string;
  value: string | number | boolean;
}

export type ValueTypes = ValueOf<typeof VALUE_TYPES>;

export enum WebElementsTypes {
  Select = 'select',
  Input = 'input',
  DatePicker = 'datePicker',
  RangeDatePicker = 'rangeDatePicker',
  MultiSelect = 'select.multiple',
  InputJson = 'inputJson',
  SelectAssociativeKey = 'select.associativeKey',
  TextArea = 'textArea',
}

export enum ColumnTypes {
  date = 'date',
  string = 'string',
  boolean = 'boolean',
  number = 'number',
  jArrayNumber = 'jArrayNumber',
  jArrayString = 'jArrayString',
  jObjectAny = 'jObjectAny',
  jArrayAny = 'jArrayAny',
  jsonAny = 'jsonAny',
  collaboration = 'collaboration',
}

interface IPvalidItem {
  type: string;
  regExp: N<string>;
  message?: N<string>;
  readOnly: boolean;
  required: N<boolean>;
  referens?: N<string>;
  translate: string;
  input_type: N<string>;
  item?: N<number>;
  defaultLnk: N<string>;
  listValue?: N<
    {
      label: string;
      value: any;
    }[]
  >;
  deviceTypeId?: N<number[]>;
}

export interface IPvalid {
  [key: string]: IPvalidItem;
}

export type IQueryParamRequest =
  | {
      [key: string]: unknown;
    }
  | undefined;

export interface TableData {
  dataRows: DataType[];
  headerItem: ITableColumn[];
  queryParams?: QueryParamItem[];
  libraries?: Record<string, LibraryKeysZod[]>;
  pvalid?: IPvalid;
}

interface DateValues {
  value: N<string>;
  dateFormat: string;
}

interface RangeDateValues {
  from: N<string>;
  to: N<string>;
  dateFormat: string;
}

const isValidValue = (value: unknown) => isString(value) || isNull(value);

export const isDateValues = (
  value: unknown,
  jvalue?: string
): value is DateValues => {
  const dateObj = value as N<DateValues>;

  if (!dateObj) return false;

  const isValidJSON =
    isValidValue(dateObj.value) && isString(dateObj.dateFormat);

  return !isUndefined(jvalue)
    ? isValidJSON && jvalue === VALUE_TYPES.datePickerJson
    : isValidJSON;
};

export const isRangeDateValues = (
  value: unknown,
  jvalue?: string
): value is RangeDateValues => {
  const dateObj = value as N<RangeDateValues>;

  if (!dateObj) return false;

  const isValidJSON =
    isValidValue(dateObj.from) &&
    isValidValue(dateObj.to) &&
    isString(dateObj.dateFormat);

  return !isUndefined(jvalue)
    ? isValidJSON && jvalue === VALUE_TYPES.rangeDatePickerJson
    : isValidJSON;
};

interface DateDJSValue {
  value: N<Dayjs>;
  dateFormat: string;
}

const isValidDayJSItem = (value: unknown) =>
  isNull(value) || dayjs.isDayjs(value);

export const isDateDJSValues = (
  value: unknown,
  jvalue?: string
): value is DateDJSValue => {
  const dateObj = value as N<DateDJSValue>;

  if (!dateObj) return false;

  const isValidJSON =
    isValidDayJSItem(dateObj.value) && isString(dateObj.dateFormat);

  return !isUndefined(jvalue)
    ? isValidJSON && jvalue === VALUE_TYPES.datePickerJson
    : isValidJSON;
};

interface RangeDJSValue {
  from: N<Dayjs>;
  to: N<Dayjs>;
  dateFormat: string;
}

export const isRangeDateDJSValues = (
  value: unknown,
  jvalue?: string
): value is RangeDJSValue => {
  const dateObj = value as N<RangeDJSValue>;

  if (!dateObj) return false;

  const isValidJSON =
    isValidDayJSItem(dateObj.from) &&
    isValidDayJSItem(dateObj.to) &&
    isString(dateObj.dateFormat);

  return !isUndefined(jvalue)
    ? isValidJSON && jvalue === VALUE_TYPES.rangeDatePickerJson
    : isValidJSON;
};
