import { MINUTE, SECOND } from './constants';

export const DEFAULT_UPDATE_TIME = 5 * MINUTE;
export const AUTO_UPDATE_STATIC = 5 * SECOND;
export const UPDATE_PREDICT_CHART_INTERVAL = 15 * MINUTE;
export const UPDATE_WEATHER_TIME = 15 * MINUTE;
export const DEFAULT_UPDATE_DEVICE_ADMIN = 1 * MINUTE;
export const DEFAULT_UPDATE_GROUPS = 3 * MINUTE;
export const DEFAULT_UPDATE_GROUPS_SETTINGS = 1 * MINUTE;

export const DEFAULT_STALE_TIME = 3 * MINUTE;

export const QUERY_GROUP_KEY = {
  POINT_UDS: ['pointUds'],
};

export const QUERY_KEY = {
  VEHICLES_AMOUNT: [...QUERY_GROUP_KEY.POINT_UDS, 'vehicles', 'amount'],
  STATISTIC_MENU: 'statisticMenu',
  DETECTORS_DATA: 'detectorsData',
  DETECTORS_PREDICT_DATA: 'detectorsPredictData',
  STATISTIC_ADAPTERS: 'statistic',
  DEVICE_INFO: 'deviceInfo',
  USER_INFO: 'userInfo',
  DETECTORS_STATE: 'detectorsState',
  WEATHER_INFO: 'weatherInfo',
  DEVICE_FORM_INFO: 'deviceFormInfo',
  DEVICES_INFO: 'devicesInfo',
  EXTERNAL_LINKS_INFO: 'externalLinksInfo',
  SOURCE_TYPES: 'sourceTypes',
  SOURCE_SYSTEMS: 'sourceSystems',
  SOURCE_EXT_DEVICES: 'sourceExtDevices',
  EXTERNAL_LINK_INFO: 'externalLinkInfo',
  GROUPS_WEB_NJS: 'groupsWebNjs',
  SETTINGS_WEB_NJS: 'settingsWebNjs',
  COORD_GROUPS_LIST: 'coordGroupsList',
  COORD_PROFILES_LIST: 'coordProfilesList',
  COORD_GROUP_CHECK: 'checkCoordGroup',
} as const;
