import { PaginationOnChange } from '../../ts/models/table.model';
import eventBus from '../eventBusCore';

import { EventBusEvents } from './enums/eventEnums';

const eventName = EventBusEvents.TablePaginationChange;

const subscribe = (callback: PaginationOnChange) => {
  return eventBus.subscribe(eventName, callback);
};

const broadcast: PaginationOnChange = (key, pageSize) => {
  eventBus.broadcast(eventName, key, pageSize);
};

export default {
  subscribe,
  broadcast,
};
