/* eslint-disable jsx-a11y/no-static-element-interactions */
import { Tag } from 'antd';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import { findById } from '../../../../../helpers/findBy';
import { ActiveSystems } from '../../../../../stores/monitoringPanelStore/monitoringPanelStore.model';
import rootStore from '../../../../../stores/rootStore/rootStore';
import { System } from '../../../../../ts/enums/enums';
import IcoDevice from '../../../../IcoDevice/IcoDevice';
import { getIsCoordinatesOnExtent } from '../../../../Map/helpers/getIsCoordinatesOnExtent';
import {
  DISABLED_POPOVER_TEXT,
  DisabledTypes,
} from '../../../../Panel/MainPanelTabs/AllSystemsTab/constants/constants';
import Popover from '../../../../ui-kit/Popover/Popover';

import { getDeviceTitle, getLinkedsInfo } from './helpers';
import PopoverContent from './PopoverContent/PopoverContent';

import styles from './LinkedDeviceItem.module.scss';

export interface DevicesProps {
  id?: number;
  system: ActiveSystems;
  bgColor?: string;
  iconStyle?: string;
}

interface LinkedDeviceItemProps {
  id: number;
  system: System;
}

const TITLE_LENGTH = 50;
const DISABLED_TITLE = DISABLED_POPOVER_TEXT[DisabledTypes.UnknownCoordinates];

const LinkedDeviceItem: FC<LinkedDeviceItemProps> = ({ id, system }) => {
  const { systemsInfo, setCenteredDevice } = rootStore.mapDataStore;
  const { tipsValues } = rootStore.uiStore;

  const systemsData = systemsInfo[system]?.data;

  const currentObject = findById(systemsData, id);

  const isDisabled =
    currentObject?.longitude && currentObject?.latitude
      ? !getIsCoordinatesOnExtent([
          currentObject.longitude,
          currentObject.latitude,
        ])
      : false;

  const handleOpenLinkedDevice = () => {
    if (isDisabled || !currentObject) return;

    setCenteredDevice(currentObject.id, system);
  };

  if (!currentObject) return null;

  const { deviceStatus, errorText, isError, mode, statusColor } =
    getLinkedsInfo(currentObject, system);

  const content = !isDisabled ? (
    <PopoverContent
      mode={mode}
      system={system}
      currentObject={currentObject}
      id={id}
      statusDevice={deviceStatus}
    />
  ) : (
    DISABLED_TITLE
  );

  const popoverProps = {
    tipsDelay: tipsValues.interfaceTipsDelay,
    content,
    placement: 'left',
  } as const;

  const containerStyle = classNames({
    [styles.device]: true,
    [styles.disabled]: isDisabled,
  });

  return (
    <Popover {...popoverProps}>
      <div className={containerStyle} onClick={handleOpenLinkedDevice}>
        <span className={styles.ico}>
          <IcoDevice system={system} id={id} />
        </span>
        <p>
          {getDeviceTitle({
            currentObject,
            id,
            maxLength: TITLE_LENGTH,
          })}
        </p>
        {isError ? (
          <Tag color={statusColor} className={styles.error}>
            {errorText}
          </Tag>
        ) : null}
      </div>
    </Popover>
  );
};

export default observer(LinkedDeviceItem);
