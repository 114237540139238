import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import rootStore from '../../../../../../stores/rootStore/rootStore';
import VideoContainer from '../../SourceEditForm/SourceInfo/VideoContainer/VideoContainer';

import styles from './SourcePreview.module.scss';

interface SourcePreviewProps {
  isShow?: boolean;
}

const SourcePreview: FC<SourcePreviewProps> = ({ isShow = true }) => {
  const { previewSource } = rootStore.adminPanelStore;

  if (!isShow) return null;

  return (
    <div className={styles.container}>
      <p className={styles.title}>Предварительный просмотр источника</p>
      <div className={styles.content}>
        {previewSource ? (
          <VideoContainer
            systemId={previewSource.systemId}
            cameraExtId={previewSource.sourceId}
            name={previewSource.sourceName}
          />
        ) : (
          <p className={styles.noData}>
            Для просмотра необходимо выбрать внешнее устройство
          </p>
        )}
      </div>
    </div>
  );
};

export default observer(SourcePreview);
