import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import { COLORS } from '../../../../../../../constants/colorsConstants';
import { findById } from '../../../../../../../helpers/findBy';
import { sliceTitleBy } from '../../../../../../../helpers/sliceTitle';
import rootStore from '../../../../../../../stores/rootStore/rootStore';
import AccordionSimple from '../../../../../../ui-kit/AccordionSimple/AccordionSimple';
import { PopoverProps } from '../../../../../../ui-kit/Popover/Popover';
import ControlCard from '../ControlCard/ControlCard';
import { getTLWarnings } from '../ControlCard/helpers/helpers';
import StatusBlock from '../StatusBlock/StatusBlock';

import ActionButtons from './ActionButtons/ActionButtons';

import styles from './TLItem.module.scss';

const MAX_LEN = 50;

interface TLItemProps {
  id: number;
}

const TLItem: FC<TLItemProps> = ({ id }) => {
  const { interfaceTipsDelay } = rootStore.uiStore;
  const { tls } = rootStore.mapDataStore;

  const tl = findById(tls, id);

  if (!tl) return null;

  const {
    tlStatus: { mode },
    deviceInfo: { caption, controlAccess },
  } = tl;

  const { warningText } = getTLWarnings(controlAccess);

  const mainTitle = `СО ${id}.`;

  const title = `${mainTitle} ${sliceTitleBy(caption, MAX_LEN)}`;
  const isLongText = caption.length > MAX_LEN;

  const popoverProps: PopoverProps = {
    content: `${mainTitle} ${caption}`,
    tipsDelay: isLongText ? interfaceTipsDelay : null,
    placement: 'top',
  };

  return (
    <>
      <div className={styles.container}>
        <AccordionSimple
          key={id}
          title={title}
          statusBlock={<StatusBlock mode={mode} warningText={warningText} />}
          popoverProps={popoverProps}
          borderColor={warningText ? COLORS.WARNING : undefined}
        >
          <ControlCard tlId={tl.id} />
        </AccordionSimple>
        <ActionButtons id={id} />
      </div>
    </>
  );
};

export default observer(TLItem);
