import { FC } from 'react';
import { RiErrorWarningLine } from 'react-icons/ri';

import Detailed from '../../../ui-kit/Detailed/Detailed';
import { DETAILED_HEADER } from '../constants/constants';
import { EmptyDetailedProps } from '../TrafficDetailed.model';

import styles from './EmptyDetailed.module.scss';

const EmptyDetailed: FC<EmptyDetailedProps> = ({ onClose }) => (
  <Detailed header={DETAILED_HEADER} onClose={onClose}>
    <div className={styles.container}>
      <RiErrorWarningLine />
      <span>Невозможно построить группу координации</span>
    </div>
  </Detailed>
);

export default EmptyDetailed;
