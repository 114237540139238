const getSliderProps = (
  min: number,
  max: number,
  defaultValue = 0,
  step = 1,
  unit = ''
) => {
  const marks = {} as Record<string, string>;

  const minKey: string = min.toString();
  const maxKey: string = max.toString();
  const defaultValueKey: string = defaultValue.toString();

  marks[minKey] = minKey + unit;
  marks[defaultValueKey] = defaultValueKey + unit;
  marks[maxKey] = maxKey + unit;

  return { min, max, defaultValue, marks, step };
};

export default getSliderProps;
