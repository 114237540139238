type Id = N<SN>;

interface ElWithIdx<T> {
  el: N<T>;
  idx: N<number>;
}

interface ItemWithId {
  id?: Id;
  device_id?: Id;
  from_device_id?: Id;
}

const findElementWithIdx = <T extends ItemWithId>(array: T[], id: Id) =>
  array.reduce(
    (res: ElWithIdx<T>, el, idx, arr) => {
      if (
        !id ||
        ((!el.id || el.id !== id) &&
          (!el.device_id || el.device_id !== id) &&
          (!el.from_device_id || el.from_device_id !== id))
      )
        return res;
      arr.slice(0);

      return { el, idx };
    },
    { el: null, idx: null }
  );

export default findElementWithIdx;
