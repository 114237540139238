import { VideoSourceItem } from '../../../components/VideoCamera/models/VideoCamera.model';
import { VERSION } from '../../../constants/versions';
import { MapObject } from '../../../ts/enums/enums';
import { getAppPathHeaders } from '../../helpers/getAppPathHeaders';
import http from '../../http';
import mapObjectsApi from '../mapObjectsApi';

interface VideoSourcesByExtDeviceProps {
  extId: string;
  systemId: number;
  appPath: string;
}

const ENDPOINT = `${VERSION}/objects/cm`;

const camerasApi = {
  fetchAll: async (regionId: number) =>
    await mapObjectsApi.fetchFullObject(MapObject.cm, regionId),

  fetchItem: async (id: number) =>
    await mapObjectsApi.fetchObjectItem(MapObject.cm, id),

  getVideoSourcesByDeviceId: async (cameraDeviceId: number) => {
    const res = await http.get<VideoSourceItem[]>(
      `${ENDPOINT}/${cameraDeviceId}/video-sources`
    );

    return res.data;
  },

  getVideoSourcesByExtDeviceId: async ({
    extId,
    systemId,
    appPath,
  }: VideoSourcesByExtDeviceProps) => {
    const res = await http.get<VideoSourceItem[]>(
      `${ENDPOINT}/external-device/video-sources`,
      {
        headers: getAppPathHeaders(appPath),
        params: {
          extId,
          systemId,
        },
      }
    );

    return res.data;
  },
};

export default camerasApi;
