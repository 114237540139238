import { observer } from 'mobx-react-lite';

import { DeviceFormType } from '../../../stores/adminPanelStore/constants/constants';
import rootStore from '../../../stores/rootStore/rootStore';

import AdditionalCard from './AdditionalCard/AdditionalCard';
import DeviceAdminCard from './DeviceAdminCard/DeviceAdminCard';

import styles from './DeviceAdmin.module.scss';

const { EditForm } = DeviceFormType;

const DeviceAdmin = () => {
  const { deviceFormType, selectedDevice } = rootStore.adminPanelStore;

  const isSingleCard =
    !deviceFormType || (deviceFormType === EditForm && !selectedDevice);

  return (
    <div className={isSingleCard ? styles.singleCard : styles.container}>
      <DeviceAdminCard />
      <AdditionalCard />
    </div>
  );
};

export default observer(DeviceAdmin);
