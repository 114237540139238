import { FC } from 'react';
import { Polygon } from 'recharts';

import { COLORS } from '../../../constants/colorsConstants';

import { Y_OFFSET } from './constants/constants';
import { TrafficAreaProps } from './TrafficDetailed.model';

const TrafficArea: FC<TrafficAreaProps> = ({
  points,
  fill = COLORS.PRIMARY,
  fillOpacity,
  ...props
}) => {
  const xAxis = props.xAxisMap[0];
  const yAxis = props.yAxisMap[0];

  const { width, domain: xDomain, x } = xAxis;
  const { height, domain: yDomain, y } = yAxis;

  const xRate = width / xDomain[1];
  const yRate = height / (yDomain[1] + Y_OFFSET);

  const [d1, d2, d3, d4] = points.map((p) => ({
    x: x + p.x * xRate,
    y: y + (Y_OFFSET + p.y) * yRate,
  }));

  return <Polygon points={[d1, d2, d3, d4]} fill={fill} fillOpacity={0.3} />;
};

export default TrafficArea;
