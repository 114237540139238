export const PLACEMENT_ITEM = 'bottom';

export const LOCAL_STYLES = {
  panelBodyStyle: {
    margin: 0,
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 5,
    paddingBottom: 5,
  },
  borderRadius: 6,
  emptyCard: { width: 96, height: 96 },
};

export const BASEMAPS_CONTENT = {
  content: 'Картографические подложки',
  contentDisabled:
    'Для выбранного региона картографические подложки временно недоступны',
  title: 'Подложки',
  loading: 'загрузка ...',
  loadingPopover: 'Загрузка подложек по региону',
};

export const MAX_SUBTITLE_LENGTH = 10;
