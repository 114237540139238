import { Layer } from 'ol/layer';
import BaseLayer from 'ol/layer/Base';
import TileLayer from 'ol/layer/Tile';
import LayerRenderer from 'ol/renderer/Layer';
import { Source } from 'ol/source';
import TileSource from 'ol/source/Tile';
import XYZ from 'ol/source/XYZ';
import CanvasFilter, {
  type CanvasFilterOptions,
} from 'ol-ext/filter/CanvasFilter';

import { IBaseMapItem } from '../../../stores/gisDataStore/gisDataStore.model';
import { TMap } from '../../../stores/mapStore/mapStore.model';
import {
  BASEMAPS_PROPS,
  BASEMAPS_TYPES,
  BW_BASEMAP_FILTER,
} from '../../constants/basemaps/utils';
import * as VectorTileLayer from '../../layers/VectorTileLayer';
import { getVectorTileStyle } from '../../requests';
import { VectorStyleItem } from '../../requests/getVectorTileStyle';
import * as VectorTileSource from '../../sources/VectorTileSource';
import {
  styleFunction,
  styleTrafficFunction,
} from '../../styles/utils/styleFunction';

import { isTrafficLayer } from './layerTypeTest';

export const tileUrl = (item: IBaseMapItem): string => {
  if (!item.url) {
    return '';
  }

  const { url } = item;
  let { subDomains } = item;

  subDomains = subDomains || [];

  let targetUrl = url.split('$').join('');

  const [subDomain] = subDomains;

  if (subDomain) {
    targetUrl = targetUrl.replace('{subDomain}', `${subDomain}`);
  }

  targetUrl = targetUrl.replace('level', 'z');
  targetUrl = targetUrl.replace('col', 'x');
  targetUrl = targetUrl.replace('row', 'y');

  return targetUrl;
};

const createBasemapLayer = (basemap: IBaseMapItem): BaseLayer | None => {
  const { type, url, id, persistent, zIndex } = basemap;

  if (!url) {
    return null;
  }

  if (type === BASEMAPS_TYPES.XYZ && url) {
    const targetUrl = tileUrl(basemap);
    const source = new XYZ({ url: targetUrl });
    const layer = new TileLayer({
      source,
    });

    layer.setUseInterimTilesOnError(true);

    layer.set(
      BASEMAPS_PROPS.CATEGORY_PROPERTY,
      BASEMAPS_PROPS.CATEGORY_PROPERTY_VALUE
    );

    layer.set(BASEMAPS_PROPS.LAYER_ID_PROPERTY, id);

    return layer;
  }

  if (type === BASEMAPS_TYPES.VECTOR) {
    const source = VectorTileSource.source({
      url,
    });

    const layer = VectorTileLayer.layer({
      source,
    });

    const isTraffic = isTrafficLayer(id);

    getVectorTileStyle(url).then((definitions: VectorStyleItem[]) => {
      const style = isTraffic
        ? styleTrafficFunction(definitions)
        : styleFunction(definitions);

      layer.setStyle(style);
    });

    if (!persistent) {
      layer.set(
        BASEMAPS_PROPS.CATEGORY_PROPERTY,
        BASEMAPS_PROPS.CATEGORY_PROPERTY_VALUE
      );
    }

    layer.set(BASEMAPS_PROPS.LAYER_ID_PROPERTY, id);

    zIndex && layer.setZIndex(zIndex);

    return layer;
  }
};

const addBWFilter = (layer?: TileLayer<TileSource>) => {
  if (!(layer instanceof TileLayer)) {
    return;
  }

  const filter = new CanvasFilter(BW_BASEMAP_FILTER);

  // @ts-ignore
  layer.addFilter(filter);
};

const restoreDefaultFilter = (
  layer?: TileLayer<TileSource> | Layer<Source, LayerRenderer<any>>
) => {
  if (!layer) {
    return;
  }

  const filters = layer.getFilters();

  for (const filter of filters) {
    layer.removeFilter(filter);
  }
};

const addFilter = (
  layer: TileLayer<TileSource> | Layer<Source, LayerRenderer<any>>,
  settings: CanvasFilterOptions
) => {
  if (!layer) {
    return;
  }

  const filter = new CanvasFilter(settings);

  // @ts-ignore
  layer.addFilter(filter);
};

const getBasemap = (map: TMap) => {
  if (!map) {
    return;
  }

  const basemap = map
    .getLayers()
    .getArray()
    .find(
      (element) =>
        element.get(BASEMAPS_PROPS.CATEGORY_PROPERTY) ===
        BASEMAPS_PROPS.CATEGORY_PROPERTY_VALUE
    );

  if (!(basemap instanceof TileLayer)) {
    return;
  }

  return basemap;
};

export {
  addFilter,
  addBWFilter,
  createBasemapLayer,
  restoreDefaultFilter,
  getBasemap,
};
